import { IPollable } from "@/services/PollingService/IPollable";

interface IPoller<T> {
  timerId: number;
  isFetching: boolean;
  dataFetchers: Set<() => Promise<T>>;
}

export class PollingService implements IPollable {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private pollers: Map<number, IPoller<any>>;

  constructor() {
    this.pollers = new Map();
  }

  public dispose() {
    this.pollers.forEach((poller) => {
      window.clearInterval(poller.timerId);
    });

    this.pollers.clear();
  }

  public poll<T>(callback: () => Promise<T>, interval: number): () => void {
    let poller: IPoller<T>;

    if (this.pollers.has(interval)) {
      poller = this.pollers.get(interval)!;
    } else {
      poller = {
        timerId: this.createPollerTimer(interval),
        isFetching: false,
        dataFetchers: new Set(),
      };

      this.pollers.set(interval, poller);
    }

    poller.dataFetchers.add(callback);

    return () => {
      poller.dataFetchers.delete(callback);

      if (poller.dataFetchers.size === 0) {
        window.clearInterval(poller.timerId);
        this.pollers.delete(interval);
      }
    };
  }

  private createPollerTimer(period: number): number {
    return window.setInterval(() => {
      const poller = this.pollers.get(period);

      if (poller && !poller.isFetching) {
        poller.isFetching = true;
        // eslint-disable-next-line no-console
        console.debug("Fetching pollers data...");
        const promises = Array.from(poller.dataFetchers.values()).map((fetcher) => fetcher());

        Promise.all(promises)
          .then(() => {
            // eslint-disable-next-line no-console
            console.debug("End polling cycle");
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.debug("Error, retrying", error);
          })
          .finally(() => {
            poller.isFetching = false;
          });
      }
    }, period);
  }
}

export const poller = new PollingService();
