var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['home', { 'swiper-container': _vm.isMobile && !_vm.isDisabledSwiperMobile }]},[_vm._t("before"),(_vm.isMobile && !_vm.isDisabledSwiperMobile)?_c('div',{staticClass:"swiper-pagination"}):_vm._e(),_vm._t("header",null,{"isMobile":_vm.isMobile}),(_vm.isMobile)?_vm._t("present"):_vm._e(),_c('div',{class:[
      'home__wheel-fortune-wrapper home__wheel-fortune-wrapper--is-fortune-page',
      { 'home__wheel-fortune-wrapper--no-jackpots': !_vm.hasJackpots },
    ]},[(_vm.isMobile)?_vm._t("fortune-button"):_vm._e()],2),_c('div',{class:['home__wrapper', { 'swiper-wrapper': _vm.isMobile }]},[_c('div',{class:[
        'home__section home__section--jackpots wrapper',
        {
          'home__wheel-fortune-wrapper--jackpots-hidden': _vm.isHiddenJackpotsMobile,
        },
        { 'swiper-slide': _vm.isMobile },
      ],attrs:{"data-hash":"jackpots"}},[_vm._t("jackpots",null,{"isMobile":_vm.isMobile}),(!_vm.isMobile)?_vm._t("bonuses-description"):_vm._e(),(!_vm.isMobile)?_c('div',{class:[
          'home__wheel-fortune-wrapper',
          {
            'home__wheel-fortune-wrapper--no-jackpots': !_vm.hasJackpots || !_vm.isCasinoOrSlots,
          },
        ]},[_vm._t("fortune-button",null,{"isMobile":_vm.isMobile})],2):_vm._e(),_vm._t("swipe_icon",function(){return [(_vm.isMobile)?_c('swipe-up'):_vm._e()]},{"isMobile":_vm.isMobile})],2),_c('div',{class:['home__section home__section--games', { 'swiper-slide': _vm.isMobile }],attrs:{"data-hash":"games"}},[(_vm.isMobile)?_vm._t("games"):_vm._e()],2),(!_vm.isMobile)?_vm._t("games"):_vm._e(),_vm._t("afterGames",null,{"isMobile":_vm.isMobile}),(_vm.isBrandsShown && !_vm.isHiddenBrandsMobile)?_c('div',{class:['home__section home__section--brands', { 'swiper-slide': _vm.isMobile }],attrs:{"data-hash":"brands"}},[_vm._t("brands")],2):_vm._e(),_vm._t("footer",null,{"isMobile":_vm.isMobile})],2),_vm._t("after")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }