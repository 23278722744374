import { call } from "./client";

// const restructureTags = (tags) =>
//   tags.map((tag) => ({
//     id: tag.id,
//     name: tag.name,
//     priority: tag.priority,
//   }));

/**
 * @typedef {Object} Tag
 * @property {string} id
 * @property {string} name
 */
/**
 * @returns {Promise<Array<Tag>>}
 */
export async function fetchTags() {
  const response = await call("Game.tagList", { bankGroupStringId: "" });
  return response.list !== null ? response.list : [];
}
