import { call } from "./client";

export async function fetchBonus() {
  return await call("Bonus.getActive");
}

export async function useBonus() {
  await call("Bonus.use");
}

export async function fetchBonuses() {
  try {
    return await call("Bonus.getAccepted");
  } catch (error) {
    throw new Error("Bonus.getAccepted error");
  }
}
