<template>
  <div
    v-if="!isHide"
    :class="[
      'logo logo-default',
      {
        'logo--wide': isWide,
        'logo--full': !isCasinoOrSlots && (isFull || hasJackpots),
      },
    ]"
    role="presentation"
  >
    <span v-if="isDemoModeActive && isFull" class="logo__description uppercase">{{
      _("free.spins")
    }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Logo",

  props: {
    isHide: {
      type: Boolean,
      default: false,
    },

    isWide: {
      type: Boolean,
      default: false,
    },

    isFull: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
      isDemoModeActive: "app/isDemoModeActive",
      hasJackpots: "jackpots/hasSlots",
      isCasinoOrSlots: "providers/isCasinoOrSlots",
    }),
  },
};
</script>

<style lang="scss" scoped>
@mixin logo-translateY($padding-scale) {
  $_footer: $m_footer-btn-height + $m_footer-padding * $padding-scale;
  $_offset: ($_footer - $m_header-size) / 2;

  height: calc(100% - #{$m_header-size + $_footer + $_offset});
  padding-top: $_offset;
  margin-top: -$_offset;
}

.logo {
  top: 50%;
  left: 50%;
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url($path + "logo.png");
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;

  &--wide {
    width: 100%;
    max-height: 400px;
    max-width: 340px;
  }

  &--full {
    $swiper-pagination: $m_swiper-p-indent + $m_swiper-p-size + $m_swiper-p-active;

    width: calc(100% - #{$swiper-pagination * 2});
    @include logo-translateY(2);
    max-width: 400px;

    @media (orientation: landscape) {
      @include logo-translateY(1.6);
    }

    @media (orientation: landscape) and (max-width: 1023px) {
      max-height: 400px;
    }

    // tablet
    @media (orientation: portrait) and (min-width: 512px) {
      max-width: 500px;
    }

    @media (orientation: landscape) and (min-width: 1024px) {
      max-width: 500px;
    }
  }

  &__description {
    color: $white-color;
    font-size: $font-xxl-size;
    font-family: $base-font-family;
    pointer-events: none;
    user-select: none;
    transform: translateY(55px); // logo height + indent
  }
}

/** Add theme style */
@import "~@theme/style/components/logo.scss";
</style>
