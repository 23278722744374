/* eslint-disable no-console */
/**
 * Handling the fullscreen functionality via the fullscreen API
 *
 * @see http://fullscreen.spec.whatwg.org/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
 */

// Helpers
const doc = document;
const shr = document.documentElement.shadowRoot;

/**
 * @param {Promise} promise
 * @return {boolean}
 * @description Check Full-screen promise.
 */
export const getFullscreenPromiseResult = async (promise) =>
  await promise.then((value) => value === undefined);

/**
 * @param {boolean} isFullscreen
 * @param {(string|null)} errorMessage
 * @description Enable or disable Full-screen mode.
 */
export const displayFullscreenMode = (isFullscreen = true, errorMessage = null) => {
  let promise = null;
  try {
    promise = isFullscreen ? enableFullscreen() : disableFullscreen();
  } catch (e) {
    console.error(errorMessage || e.message);
    return false;
  }
  return promise ? getFullscreenPromiseResult(promise) && isFullscreen : promise;
};

/**
 * @description Toggle Full-screen mode.
 */
export const toggleFullscreen = () => {
  return displayFullscreenMode(!isFullscreen());
};

/**
 * @param {HTMLElement} element documentElement by default
 * @description Enable Full-screen mode.
 */
export const enableFullscreen = (element = doc.documentElement) => {
  return element.requestFullscreen
    ? element.requestFullscreen()
    : element.msRequestFullscreen
    ? element.msRequestFullscreen()
    : element.mozRequestFullScreen
    ? element.mozRequestFullScreen()
    : element.webkitRequestFullscreen
    ? element.webkitRequestFullscreen()
    : element.webkitRequestFullScreen && element.webkitRequestFullScreen();

  // To break out of frame in IE.
  // elem = window.top.document.body.msRequestFullscreen();
};

/**
 * @description Disable Full-screen mode.
 */
export const disableFullscreen = () => {
  return doc.exitFullscreen
    ? doc.exitFullscreen()
    : doc.msExitFullscreen
    ? doc.msExitFullscreen()
    : doc.mozCancelFullScreen
    ? doc.mozCancelFullScreen()
    : doc.webkitExitFullscreen
    ? doc.webkitExitFullscreen()
    : doc.webkitCancelFullScreen && doc.webkitCancelFullScreen();

  // window.top.document.msExitFullscreen()
};

/**
 * @param {HTMLElement} element documentElement by default
 * @description Has the Full-screen mode?!
 */
export const hasFullscreenMode = (element = doc.documentElement) => {
  return (
    (doc.exitFullscreen ||
      doc.msExitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen) &&
    (element.requestFullscreen ||
      element.msRequestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullscreen)
  );
};

/**
 * @return {(Element, null, undefined)}
 * @description Get Full-screen element.
 */
export const getFullscreenElement = () => {
  return (
    doc.fullscreenElement ||
    doc.msFullscreenElement ||
    doc.mozFullscreenElement ||
    doc.webkitFullscreenElement ||
    (shr
      ? shr.fullscreenElement ||
        shr.msFullscreenElement ||
        shr.mozFullscreenElement ||
        shr.webkitFullscreenElement
      : null)
  );
};

/**
 * @return {(boolean, undefined)}
 * @description Full-screen mode is available?!
 */
export const isFullscreenAvailable = () =>
  doc.fullscreenEnabled ||
  doc.msFullscreenEnabled ||
  doc.mozFullscreenEnabled ||
  doc.webkitFullscreenEnabled;

/**
 * @return {boolean}
 * @description Full-screen mode Is enable?!
 */
export const isFullscreen = () => {
  return !!getFullscreenElement();
};

// Event listeners:

// document.addEventListener("fullscreenchange ", this.fullscreenChange); // Standard syntax
// document.addEventListener("msfullscreenchange ", this.fullscreenChange); // IE | Edge
// document.addEventListener("mozfullscreenchange ", this.fullscreenChange); // Firefox
// document.addEventListener("webkitfullscreenchange ", this.fullscreenChange); // Chrome, Safari and Opera

// document.removeEventListener("fullscreenchange ", this.fullscreenChange);
// document.removeEventListener("msfullscreenchange ", this.fullscreenChange);
// document.removeEventListener("mozfullscreenchange ", this.fullscreenChange);
// document.removeEventListener("webkitfullscreenchange ", this.fullscreenChange);
