<template>
  <div class="page">
    <MobileHeader
      v-if="isMobile && !isGameUnavailable && !gameFix.isGameFrameError"
      variant="game"
      :is-balance-shown="false"
    >
      <template v-if="isMobile" #jackpots="{ isJackpotsShowed }">
        <div class="page__jackpotsWrapper">
          <Jackpots
            :jackpots="jackpots"
            compact
            float
            :is-visible="isJackpotsShowed && !isGameUnavailable && isMobile"
          />
        </div>
      </template>
    </MobileHeader>

    <Jackpots
      v-if="!isMobile && !gameFix.isGameFrameError && !isGameUnavailable"
      :jackpots="jackpots"
      compact
    />
    <BuyTimeModal :is-show-modal="isModalBuyTimeShown" />
    <SuccessOrErrorModal :is-visible="isSuccessOrErrorModalShown" />
    <GameFrame
      ref="wrapper"
      :is-game-frame-error="gameFix.isGameFrameError"
      :src="gameFix.sessionUrl"
      :settings="gameFix.frameSettings()"
      :message-error-frame="
        gameFix.isGameFrameError ? ['iframe-incognito-title1'] : ['null-session']
      "
    />
    <DesktopMiniFooter v-if="!isMobile && !gameFix.isGameFrameError && !isGameUnavailable" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { get } from "lodash-es";

// Utils
import { GameFix } from "@/utils/game-fix";

// Mixins
import routeMixin from "@/mixins/route";
import loadAllData from "@/mixins/load-all-data";

// Components
import DesktopMiniFooter from "@/components/Footer/DesktopMiniFooter.vue";
import MobileHeader from "@/components/Header/MobileHeader.vue";
import Jackpots from "@/components/Jackpots/Jackpots.vue";
import GameFrame from "@/components/Game/GameFrame";
import providers from "@/store/modules/providers";
import updateData from "@/mixins/update-data";
import { balanceUpdaterService } from "@/services/BalanceUpdateService/BalanceUpdaterService";
import BuyTimeModal from "@/components/Modal/BuyTimeModal.vue";
import SuccessOrErrorModal from "@/components/Modal/SuccessOrErrorModal.vue";

const GAME_RUN_TYPES = {
  favourites: "favourites",
  recommendation: "recommendation",
  game_list: "game_list",
  banner: "banner",
};
export default {
  components: {
    SuccessOrErrorModal,
    BuyTimeModal,
    Jackpots,
    GameFrame,
    MobileHeader,
    DesktopMiniFooter,
  },
  mixins: [routeMixin, loadAllData, providers, updateData],

  data: () => ({
    gameFix: new GameFix(),
    isGameUnavailable: false,
  }),

  computed: {
    ...mapGetters({
      jackpots: "jackpots/slots",
      sessionUrl: "session/url",
      sessionId: "session/id",
      hasJackpots: "jackpots/hasSlots",
      panicState: "user/panicState",
    }),
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
      isSuccessOrErrorModalShown: (state) => state.ui.isSuccessOrErrorModalShown,
      isModalBuyTimeShown: (state) => state.ui.isBuyTimeModalShown,
      isMobile: (state) => state.app.isMobile,
      isLandscapeMode: (state) => state.app.isLandscapeMode,
      isLoading: (state) => state.app.isLoading,
      isFavoriteFilter: (state) => state.tags.isFavoriteFilter,
      isRecommendationFilter: (state) => state.tags.isRecommendationFilter,
    }),
    getGameRunType() {
      if (this.isFavoriteFilter) {
        return GAME_RUN_TYPES.favourites;
      }

      if (this.isRecommendationFilter) {
        return GAME_RUN_TYPES.recommendation;
      }

      return GAME_RUN_TYPES.game_list;
    },
  },

  watch: {
    isMobile(value) {
      this.gameFix.analyzeGame(value);
    },

    panicState() {
      if (this.panicState?.isPanic) {
        this.goHome();
      }
    },
  },

  beforeCreate() {
    this.$store.commit("session/setItem", null);
  },

  async created() {
    const gameId = this.$route.params.id;
    const isDemoSession = get(this.$route, "query.demo", false);

    /** Load data of all requests ↓ ↓ ↓ */
    await this.loadRequestsData(true, {
      game: gameId,
      demo: isDemoSession,
      place: this.getGameRunType,
    });

    // Get current data
    const game = this.games.find((game) => game.id === gameId);

    if (!game?.brandId && this.isLoading) {
      this.$store.commit("app/setIsLoading", false);
      this.isGameUnavailable = true;
      throw new Error("Game is not available");
    }

    const brand = this.brands.find((brand) => brand.id === game.brandId);
    const provider = this.providers.find((provider) => provider.id === game.providerId);

    // Set current provider id
    if (provider) {
      this.setCurrentProviderId(provider.id);
    }

    /** Game fix ↓ ↓ ↓ */
    if (game) {
      await this.gameFix.loadTheClubGame(game, brand, provider);
      // this.gameFix.wrapper = this.$refs.wrapper;
      this.gameFix.analyzeGame(this.isMobile);
      this.gameFix.setGameSessionUrl(brand, this.sessionUrl);
    }

    // Add event listeners
    // this.gameFix.addResizeListener();

    // ↓ Await updates...
    await this.$nextTick();
    this.stopPageLoader(); // Change page loader state
    this.startUpdateDataInterval();
    balanceUpdaterService.init();
  },

  beforeDestroy() {
    this.stopUpdateDataInterval();
    balanceUpdaterService.dispose();
  },

  methods: {
    ...mapActions({
      setCurrentProviderId: "providers/setCurrentItemId",
    }),
    handleMessage(event) {
      if (event?.data?.type === "home") {
        this.goHome();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__jackpotsWrapper {
    position: fixed;
    bottom: 100%;
    width: 100%;
  }
}
</style>
