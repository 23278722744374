var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['menu', { 'menu--showed': _vm.isOpenMenu }, { 'menu--left': _vm.isTheLeft }],on:{"click":_vm.handleOverlayClick,"touchstart":function($event){$event.stopPropagation();},"touchmove":function($event){$event.stopPropagation();},"touchend":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"menu__content"},[_c('div',{staticClass:"menu__list"},[_vm._l((_vm.providers),function(provider){return [_c('div',{key:provider.id,staticClass:"menu__wrapper"},[(provider.gamesCount > 0)?_c('div',{class:[
              'menu__item',
              {
                'menu__item--active':
                  provider.id === _vm.currentProviderId &&
                  !_vm.isFavoriteFilter &&
                  !_vm.isRecommendationFilter,
              },
            ],on:{"click":function($event){return _vm.handleProviderClick(provider)}}},[_vm._v(" "+_vm._s(_vm._(provider.name))+" ")]):_vm._e(),(
              provider.id === _vm.currentProviderId && _vm.tags.filter((i) => i.gamesCount > 0).length
            )?_c('div',{staticClass:"menu__list"},[_vm._l((_vm.tags),function(tag){return [(tag.name === _vm.TAG_AVIATOR)?[(tag.gamesCount > 0)?_c('div',{key:tag.name,class:[
                    'menu__item',
                    {
                      'menu__item--active tag--img-tag-active':
                        tag.lowerCaseName === _vm.currentTagId,
                    },
                  ],on:{"click":function($event){return _vm.handleTagClick(tag)}}},[_c('div',{staticClass:"tag--aviator"},[_c('VIcon',{attrs:{"name":"aviator"}})],1)]):_vm._e()]:_vm._e(),(tag.name === _vm.TAG_CHRISTMAS)?[(tag.gamesCount > 0)?_c('div',{key:tag.name,class:[
                    'menu__item',
                    {
                      'menu__item--active tag--img-tag-active':
                        tag.lowerCaseName === _vm.currentTagId,
                    },
                  ],on:{"click":function($event){return _vm.handleTagClick(tag)}}},[_c('div',{staticClass:"tag--christmas"},[_c('VIcon',{attrs:{"name":"christmas"}})],1)]):_vm._e()]:_vm._e(),(tag.name === _vm.TAG_CHINESE)?[(tag.gamesCount > 0)?_c('div',{key:tag.name,class:[
                    'menu__item',
                    {
                      'menu__item--active tag--img-tag-active':
                        tag.lowerCaseName === _vm.currentTagId,
                    },
                  ],on:{"click":function($event){return _vm.handleTagClick(tag)}}},[_c('div',{staticClass:"tag--chinese"},[_c('VIcon',{attrs:{"name":"chinese-tag"}})],1)]):_vm._e()]:_vm._e(),(tag.name === _vm.TAG_HALLOWEEN)?[(tag.gamesCount > 0)?_c('div',{key:tag.name,class:[
                    'menu__item',
                    {
                      'menu__item--active tag--img-tag-active':
                        tag.lowerCaseName === _vm.currentTagId,
                    },
                  ],on:{"click":function($event){return _vm.handleTagClick(tag)}}},[_c('div',{staticClass:"tag--halloween"},[_c('VIcon',{attrs:{"name":"halloween"}})],1)]):_vm._e()]:_vm._e(),(
                  tag.name !== _vm.TAG_AVIATOR &&
                  tag.name !== _vm.TAG_HALLOWEEN &&
                  tag.name !== _vm.TAG_CHRISTMAS &&
                  tag.name !== _vm.TAG_CHINESE
                )?[(tag.gamesCount > 0)?_c('div',{key:tag.name,class:[
                    'menu__item',
                    {
                      'menu__item--active': tag.lowerCaseName === _vm.currentTagId,
                    },
                  ],on:{"click":function($event){return _vm.handleTagClick(tag)}}},[_vm._v(" "+_vm._s(tag.name)+" ")]):_vm._e()]:_vm._e()]})],2):_vm._e()])]})],2),(_vm.hasUser)?[_c('div',{class:[
          'menu__item',
          {
            'menu__item--active': _vm.isFavoriteFilter,
          },
        ],on:{"click":_vm.handleFavoriteFilter}},[_vm._v(" "+_vm._s(_vm._("favorites"))+" ")])]:_vm._e(),(_vm.hasUser && _vm.countOfRecommendations > 0)?[_c('div',{class:[
          'menu__item',
          {
            'menu__item--active': _vm.isRecommendationFilter,
          },
        ],on:{"click":_vm.handleRecommendationFilter}},[_vm._v(" "+_vm._s(_vm._("recommendations"))+" ")])]:_vm._e(),(_vm.hasUser)?[_c('hr',{staticClass:"menu__item menu__item--line"}),_c('BuyTimeButton',{attrs:{"on-click":_vm.closeMenu,"is-mobile-button":true}})]:_vm._e(),(_vm.hasUser)?[_c('div',{staticClass:"menu__item",on:{"click":_vm.handleContactsClick}},[_vm._v(" "+_vm._s(_vm._("modal.contacts.title"))+" ")])]:_vm._e(),(_vm.hasUser)?[_c('div',{staticClass:"menu__item",on:{"click":_vm.handleInfoClick}},[_vm._v(" "+_vm._s(_vm._("info"))+" ")])]:_vm._e(),(_vm.hasUser)?[_c('div',{staticClass:"menu__item",on:{"click":_vm.handleBetSportClick}},[_vm._v(" "+_vm._s(_vm._("bet_history_title_short"))+" ")])]:_vm._e(),(_vm.hasUser)?[_c('div',{staticClass:"menu__item",on:{"click":_vm.handleMemberCardClick}},[_vm._v(_vm._s(_vm._("member_card")))])]:_vm._e(),(_vm.hasUser)?[_c('hr',{staticClass:"menu__item menu__item--line"}),_c('div',{staticClass:"menu__item",on:{"click":_vm.handleSignOutClick}},[_vm._v(" "+_vm._s(_vm._("exit"))+" "),_c('v-button',{staticClass:"menu__item--btn-sign-out",attrs:{"variant":"icon","label":"Sign-Out","icon":"sign-out"}})],1)]:_vm._e(),_c('v-button',{staticClass:"menu__btn--close",attrs:{"variant":"icon","label":"Menu"},on:{"click":_vm.handleCloseClick}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }