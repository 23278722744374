<template>
  <div class="list-wrapper">
    <template>
      <div
        v-if="!betHistory(status)?.length && !isFetchingNextPage(status) && !isLoading(status)"
        class="flex-center"
      >
        <div class="text-[1em] text-white font-bold text-center mt-50">
          <div>
            {{ status === "created" ? _("empty_active_bets") : _("empty_completed_bets") }}
          </div>
          <div class="my-3 h-20">
            <svg
              class="block"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 325 200"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M38.343 0h248.215c21.143 0 38.343 17.203 38.343 38.346v47.79l-12.501.59c-7.112.337-12.683 6.169-12.683 13.276s5.571 12.94 12.678 13.272l12.506.585v47.8c0 21.138-17.2 38.341-38.343 38.341H38.343C17.199 200 0 182.797 0 161.659v-46.893l11.259-1.61c6.502-.933 11.406-6.59 11.406-13.154 0-6.57-4.906-12.227-11.412-13.16L0 85.228V38.345C0 17.203 17.2 0 38.343 0m248.256 100.002c0-14.179 11.168-25.718 25.183-26.38V38.345c0-13.932-11.294-25.227-25.224-25.227H38.343c-13.93 0-25.225 11.295-25.225 25.227v35.512c12.809 1.84 22.665 12.827 22.665 26.145s-9.856 24.305-22.665 26.14v35.516c0 13.929 11.294 25.223 25.225 25.223h248.215c13.93 0 25.224-11.294 25.224-25.223v-35.28c-14.015-.657-25.183-12.196-25.183-26.376m-211.277 48.33h8.679v22.635h-8.679zm8.679-39.766h-8.679v22.635h8.679zm-8.679-39.773h8.679v22.639h-8.679zm8.679-39.758h-8.679V51.67h8.679zm96.305 79.938c-9.913 9.913-25.984 9.913-35.897 0s-9.913-25.984 0-35.897 25.984-9.912 35.897 0 9.912 25.985 0 35.897m4.113 13.091c-14.869 10.595-35.645 9.223-48.984-4.116-14.869-14.869-14.869-38.977 0-53.846s38.976-14.869 53.845 0c13.184 13.184 14.678 33.63 4.483 48.46l13.913 13.913 2.244-2.245 21.987 21.987-13.91 13.91-21.987-21.987 2.243-2.242z"
              />
            </svg>
          </div>
          <div v-if="status === 'created'">
            {{ _("boilerplate.components.SportBetsHistoryModal.placeBid") }}
          </div>
        </div>
      </div>
      <div v-if="isLoading(status)">
        <div class="rounded-rounded bg-silver mb-5 h-56 animate-pulse dark:bg-bright-gray"></div>
        <div class="rounded-rounded bg-silver mb-5 h-56 animate-pulse dark:bg-bright-gray"></div>
      </div>
      <template v-for="item in betHistory(status)">
        <Coupon :key="item.uuid" v-bind="item" />
      </template>
    </template>
    <div v-if="isFetchingNextPage(status)" class="flex items-center justify-center my-2 h-10">
      <div class="size-1 text-keppel animate-spin">
        <svg
          class="block overflow-visible"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          viewBox="0 0 31 31"
        >
          <path
            fill="currentColor"
            d="M3.44 15.5a12.06 12.06 0 0 1 24.12 0H23.3l5.97 10.33 5.96-10.33H31A15.5 15.5 0 1 0 15.5 31v-3.44c-6.66 0-12.06-5.4-12.06-12.06"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Coupon from "./Coupon.vue";

export default {
  name: "SportBetsHistoryList",
  components: {
    Coupon,
  },
  props: {
    status: {
      type: String,
      required: true,
      validator: (value) => ["created", "non-created"].includes(value),
    },
  },
  data() {
    return {
      limit: 100,
      offset: 0,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/hasUser",
      _: "l10n/translate",
      betHistory: "history/getSportBetsHistory",
      isLoading: "history/isLoading",
      isFetchingNextPage: "history/isFetchingNextPage",
      hasNextPage: "history/hasNextPage",
    }),
  },
  watch: {
    status: {
      handler(newStatus) {
        this.fetchHistory(newStatus);
      },
      immediate: true,
    },
  },

  created() {
    this.fetchHistory(this.status);
    this.offset = this.betHistory(this.status).length;
  },
  beforeDestroy() {
    this.offset = 0;
  },

  methods: {
    ...mapActions({
      fetchSportBetsHistory: "history/fetchSportBetsHistory",
    }),
    fetchHistory(status) {
      if (this.hasNextPage(status)) {
        this.fetchSportBetsHistory({
          status,
          limit: this.limit,
          offset: this.offset,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-50 {
  margin-top: 50px;
}

//.list-wrapper {
//  padding-right: 12px;
//}
</style>
