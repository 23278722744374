var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'nav',
    {
      'nav--hide': _vm.isHide,
    },
    {
      'nav--open': !_vm.isHide,
    },
    {
      'nav--jackpotsVisible': _vm.isJackpotsShowed,
    },
    {
      'nav--sweepstakes-exchange': _vm.isSweepstakesExchangeEnabled,
    },
  ]},[_c('div',{staticClass:"nav__wrapper",on:{"click":_vm.handleHideClick}},[_c('div',{staticClass:"nav__sweepstakes"},[_c('SweepstakesBalance',{attrs:{"is-small":"","is-column":""}}),_c('BuyTimeButton')],1),_c('v-button',{staticClass:"nav__btn",attrs:{"variant":"icon","icon":"left","label":"Hide"},on:{"click":_vm.handleHideClick}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }