type TData =
  | { event: "play_start" }
  | { event: "play_end" }
  | { event: "balance_updated"; coins: number };

const enum GameEvents {
  SPIN_START = "play_start",
  SPIN_END = "play_end",
  BALANCE_UPDATED = "balance_updated",
}
type TExternalCallback = () => void;

class BalanceUpdaterService {
  private _balance = 0;
  private _prevEvent: keyof typeof GameEvents | null = null;
  private _updateExternalCallback: TExternalCallback = (): null => null;

  public init() {
    window.addEventListener("message", this.messageHandler);
  }

  public dispose() {
    window.removeEventListener("message", this.messageHandler);
  }

  private messageHandler = (message: { data: TData }) => {
    if (!message || !message?.data) {
      return;
    }

    try {
      const jsonMessage = JSON.parse(message.data.toString()) ?? {};
      const { event, coins } = jsonMessage;

      if (
        (this._prevEvent as GameEvents) === GameEvents.SPIN_END &&
        event === GameEvents.BALANCE_UPDATED
      ) {
        this.balance = coins;

        if (typeof this.updateExternalCallback === "function") {
          this.updateExternalCallback();
        }
      }

      this._prevEvent = event;
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error((e as Error).message);
    }
  };

  public get balance(): number {
    return this._balance;
  }

  private set balance(balance) {
    this._balance = balance;
  }

  public get updateExternalCallback(): TExternalCallback {
    return this._updateExternalCallback;
  }

  public set updateExternalCallback(updateExternalCallback: TExternalCallback) {
    this._updateExternalCallback = updateExternalCallback;
  }
}

export const balanceUpdaterService = new BalanceUpdaterService();
