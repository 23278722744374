import * as api from "../../api";
import { errorMessageMutations, errorMessageState } from "../mixins/error-message";

/** State */
const state = {
  ...errorMessageState,

  item: null,
};

/** Actions */
const actions = {
  async create({ commit, rootState }, params) {
    try {
      // Add back-end parameters
      if (params.demo) {
        params["demo_balance"] = 50000;
      }

      params["deviceType"] = rootState.app.isMobile ? "mobile" : "desktop";

      const session = await api.sessions.createSession(params);
      commit("setItem", session);
      if (state.error !== null) {
        commit("clearError");
      }
    } catch (error) {
      if (state.item !== null) {
        commit("setItem", null);
      }
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  url: (state) => (state.item ? state.item.url : null),
  id: (state) => (state.item ? state.item.id : null),
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,

  setItem(state, item) {
    state.item = item;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
