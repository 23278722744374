import { poller } from "@/services/PollingService/PollingService";
import { throttle } from "lodash-es";
import { IPollable } from "@/services/PollingService/IPollable";

const DEFAULT_IDLE_TIMEOUT = 44000;
const DEFAULT_POLLING_INTERVAL = 5000;

class UserWatchActivityService {
  private readonly timeout: number;
  private poller: IPollable;
  private _isUserActive = true;

  constructor() {
    this.timeout = DEFAULT_IDLE_TIMEOUT;
    this.poller = poller;
  }

  public init() {
    this.handleActivityTracker();
    this.setIdleTimer();
    this.poller.poll(this.updateExpiredTime, DEFAULT_POLLING_INTERVAL);
  }

  public dispose() {
    this.handleCleanUpTracker();
    this.poller.dispose();
  }

  public get isUserActive(): boolean {
    return this._isUserActive;
  }

  private set isUserActive(value: boolean) {
    this._isUserActive = value;
  }

  private updateExpiredTime = async (): Promise<void> => {
    const expiredTime = parseInt(localStorage.getItem("_expiredTime") || "0", 10);

    if (expiredTime && expiredTime < Date.now()) {
      this.isUserActive = false;
    }
  };

  private handleActivityTracker() {
    window.addEventListener("mousemove", this.setIdleTimer);
    window.addEventListener("scroll", this.setIdleTimer);
    window.addEventListener("keydown", this.setIdleTimer);
    window.addEventListener("touchmove", this.setIdleTimer);
  }

  private handleCleanUpTracker() {
    localStorage.removeItem("_expiredTime");
    window.removeEventListener("mousemove", this.setIdleTimer);
    window.removeEventListener("scroll", this.setIdleTimer);
    window.removeEventListener("keydown", this.setIdleTimer);
    window.removeEventListener("touchmove", this.setIdleTimer);
  }

  private setIdleTimer = throttle(() => {
    this.isUserActive = true;
    localStorage.setItem("_expiredTime", String(Date.now() + this.timeout));
  }, 300);
}

export const userWatchActivityService = new UserWatchActivityService();
