// Chrome only
/* eslint-disable  @typescript-eslint/no-explicit-any */
const DEFAULT_CHROME_QUOTA = 1073741824;

interface IResult {
  browserName: string;
  isPrivate: boolean;
}

export class IncognitoDetectorService {
  static detectChrome: () => Promise<IResult | Error> = () => {
    return new Promise((resolve, reject) => {
      const browserName = "Chrome";

      const __callback = (isPrivate: boolean) => {
        resolve({
          isPrivate: isPrivate,
          browserName: browserName,
        });
      };

      /**
       * version {50 to 75}
       */
      const oldChromePrivateTest = () => {
        const fs = (window as any).webkitRequestFileSystem;
        const success = () => {
          __callback(false);
        };
        const error = () => {
          __callback(true);
        };
        fs(0, 1, success, error);
      };

      const getQuotaLimit = (): number => {
        if (
          window.performance !== undefined &&
          window.performance.memory !== undefined &&
          window.performance.memory.jsHeapSizeLimit !== undefined
        ) {
          return performance.memory?.jsHeapSizeLimit as number;
        }
        return DEFAULT_CHROME_QUOTA;
      };

      /**
       * version {>=76}
       */
      const storageQuotaChromePrivateTest = () => {
        (navigator as any)?.webkitTemporaryStorage?.queryUsageAndQuota(
          (usage: number, quota: number) => {
            __callback(quota < getQuotaLimit());
          },
          function (e: Error) {
            reject(
              new Error("detectIncognito somehow failed to query storage quota: " + e.message)
            );
          }
        );
      };

      const chromePrivateTest = () => {
        if (Promise !== undefined && Promise.allSettled !== undefined) {
          storageQuotaChromePrivateTest();
        } else {
          oldChromePrivateTest();
        }
      };

      chromePrivateTest();
    });
  };
}
