<template>
  <div class="desktop-mini-footer">
    <div class="desktop-mini-footer__row">
      <div class="desktop-mini-footer__side">
        <div class="desktop-mini-footer__username">{{ username }}</div>
        <div v-if="isSweepstakesEnabled"><BuyTimeButton /></div>
      </div>
      <div class="desktop-mini-footer__center">
        <div v-if="isDemoModeActive" class="desktop-mini-footer__text flashing">
          {{ _("free.spins") }}
        </div>
        <div v-if="isSweepstakesEnabled" class="desktop-mini-footer__sweepstakes">
          <SweepstakesBalance />
        </div>
        <template v-else>
          <Providers />
        </template>
      </div>

      <div class="desktop-mini-footer__side">
        <div
          class="desktop-mini-footer__icon desktop-mini-footer__icon--fullscreen"
          @click="handleFullscreenClick"
        />
        <div
          class="desktop-mini-footer__icon desktop-mini-footer__icon--close"
          @click="handleCloseClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { toggleFullscreen } from "@/utils/fullscreen-api";

import routeMixin from "@/mixins/route";

import Providers from "@/components/Navigation/Providers.vue";
import { get } from "lodash-es";
import SweepstakesBalance from "@/components/SweestakesBalance/SweepstakesBalance.vue";
import { balanceUpdaterService } from "@/services/BalanceUpdateService/BalanceUpdaterService";
import BuyTimeButton from "@/components/Helpers/Buttons/BuyTimeButton.vue";

export default {
  name: "DesktopMiniFooter",

  components: {
    BuyTimeButton,
    SweepstakesBalance,
    Providers,
  },

  mixins: [routeMixin],

  computed: {
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
    }),
    ...mapGetters({
      _: "l10n/translate",
      balance: "user/balance",
      currency: "user/currency",
      hasUser: "user/hasUser",
      sweepstakesBalance: "user/sweepstakesBalance",
      username: "user/username",
      isDemoModeActive: "app/isDemoModeActive",
    }),
  },

  created() {
    balanceUpdaterService.updateExternalCallback = async () =>
      await this.$store.dispatch("user/fetch");
  },

  methods: {
    handleFullscreenClick() {
      toggleFullscreen();
    },

    handleCloseClick() {
      this.goHome();
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-mini-footer {
  height: 36px;
  display: flex;
  border-top: 1px solid $border-gray-color;
  background-color: $bg-black-color;
  position: relative;

  &__sweepstakes {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  &__row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
  }

  &__side {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &__center {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  &__username {
    color: $text-darkGray-color;
    font-size: $font-xs-size;
    font-weight: bold;
    font-family: $second-font-family;
    text-transform: uppercase;
    margin-right: 90px;
  }

  .desktop-mini-footer__text {
    top: 0;
    bottom: 0;
    left: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
  }

  &__icon {
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;

    &--fullscreen {
      background-image: url($path + "fullscreen-icon.png");
    }

    &--close {
      background-image: url($path + "close-icon.png");
    }

    & + & {
      margin-left: 8px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
</style>
