<template>
  <div class="providers" role="menu">
    <template v-for="provider of providers">
      <v-button
        v-if="provider.gamesCount > 0"
        :key="provider.id"
        variant="provider"
        class="provider"
        role="menuitem"
        :data-id="provider.id"
        :is-active="provider.id === currentProviderId"
        @click="handleClick(provider)"
      >
        {{ _(provider.name) }}
      </v-button>
    </template>
  </div>
</template>

<script>
// Mixins
import gameFilter from "@/mixins/game-filter";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import { mapGetters } from "vuex";

export default {
  name: "Providers",

  components: { VButton },

  mixins: [gameFilter],
  computed: {
    ...mapGetters({
      _: "l10n/translate",
    }),
  },

  methods: {
    handleClick(provider) {
      this.selectProvider(provider);
    },
  },
};
</script>

<style lang="scss" scoped>
.providers {
  display: flex;
  flex-direction: row;

  @media (min-width: 1025px) and (max-width: 1320px) {
    margin-left: calc((1320px - 100vw) * 0.75);
  }
}

.provider {
  height: 30px;
  white-space: nowrap;
  padding: 0 1em;

  & + & {
    margin-left: 4px;
  }

  //&::v-deep.btn--active {
  //  background-color: $null-color;
  //}
}
</style>
