<template>
  <div class="custom-select">
    <v-select
      v-model="lang"
      max-height="80px"
      :calculate-position="withPopper"
      :append-to-body="true"
      :clearable="false"
      :filterable="false"
      :searchable="false"
      :options="localesArr"
      label="name"
      @input="selectedLocale"
    >
      <template #option="option">
        <span :class="`ico-flag ico-flag_${option.code} flag-mt`"> </span>
        {{ option.name }}
      </template>
      <template #selected-option="option">
        <span :class="`ico-flag ico-flag_${option.code} custom-select-flag--${option.code}`">
        </span>
        {{ option.name }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { createPopper } from "@popperjs/core";
import { localesArr } from "@/utils/languages";
import { getLangFromStorage } from "@/api/language";

export default {
  name: "LanguageSelect",
  components: { vSelect },

  data() {
    return {
      lang: null,
      placement: "top",
    };
  },
  computed: {
    ...mapState({
      locales: (state) => state.l10n.locales,
      currentLocale: (state) => state.l10n.current,
    }),
    localesArr() {
      return localesArr.filter((langObject) => this.locales?.includes(langObject.code));
    },
  },
  watch: {
    localesArr(locales) {
      this.lang = locales.find((langObject) => langObject.code === this.currentLocale);
    },
  },
  async mounted() {
    const currentLangFromLS = getLangFromStorage();

    if (this.currentLocale === null) {
      const apiLang = this.apiLanguages?.defaultLang;

      if (!currentLangFromLS && apiLang) {
        this.$store.commit("l10n/setCurrent", apiLang);
      } else {
        this.$store.commit("l10n/setCurrent", currentLangFromLS);
      }
    }

    this.lang = this.localesArr.filter((langObject) => langObject.code === this.currentLocale);
  },
  methods: {
    ...mapActions({
      setCurrentLang: "l10n/setLanguage",
    }),
    async selectedLocale(input) {
      this.lang = input;
      await this.$store.dispatch("l10n/setLanguage", {
        lang: input.code,
      });
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle("drop-up", state.placement === "top");
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  position: relative;
  min-width: 150px;
  margin-right: 2px;

  .vs__selected {
    color: white;
    z-index: 15;
    margin: 0 0 4px 0;
    .ico-flag {
      margin-bottom: 0;
    }
  }

  //.vs__clear {
  //  display: none;
  //}

  .v-select {
    &:after {
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      pointer-events: none;
      background-position-y: 0;
      content: "";
      background-image: url("../../assets/images/btn_effects.png");
      background-repeat: no-repeat;
      background-size: 100% 200%;
      z-index: 5;
    }
  }

  .vs__dropdown-toggle {
    border-style: solid;
    border-image-slice: 1;
    border-radius: 1px;
    border-color: $null-color;
    border-width: 1px;
    max-height: 30px;
    background-color: $btn-primary-color;
    border-image-source: linear-gradient(
      to bottom,
      fade-out($border-primary-color, 0.5),
      $border-primary2-color,
      fade-out($border-primary-color, 0.5)
    );
    max-height: 30px;
  }

  .vs__open-indicator {
    fill: white;
    z-index: 15;
  }

  * {
    font-family: $base-font-family;
    font-size: $font-md-size;
  }

  .custom-select-flag {
    .ico-flag {
      margin-bottom: -2px;
    }
    &--he {
      margin-left: 4px;
      margin-top: 4px;
    }
  }
}
</style>
