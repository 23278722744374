<template>
  <div>
    <transition name="fade">
      <div v-show="isVisible && isGamesHash" class="search">
        <input
          ref="search"
          autofocus
          :placeholder="_('search')"
          class="search__input"
          :value="searchQuery"
          @input="handleSearchQueryChange"
        />
        <div
          class="search__reset-button"
          role="button"
          aria-label="Search reset"
          @click="handleResetButtonClick"
        >
          <v-icon name="close" :size="16" />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <v-button
        v-if="isGamesHash"
        class="search-button"
        tag-name="button"
        variant="primary"
        has-texture
        has-rounded
        @click="toggleVisible"
      >
        <v-icon
          :is-active="isVisible && isGamesHash"
          active-class="search-button__icon"
          name="search"
        />
      </v-button>
    </transition>
  </div>
</template>

<script>
import gameFilter from "@/mixins/game-filter";
import { debounce } from "lodash-es";
import { DEBOUNCE_SEARCH_DELAY } from "@/constants/app";
import VIcon from "@/components/Helpers/Icons/VIcon";
import routeMixin from "@/mixins/route";
import { VButton } from "@/components/Helpers/Buttons";
import { mapGetters } from "vuex";

export default {
  name: "MobileSearch",
  components: { VIcon, VButton },
  mixins: [gameFilter, routeMixin],

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
    }),
    isGamesHash() {
      return this.$route.hash === "#games";
    },
  },

  methods: {
    handleSearchQueryChange: debounce(function (event) {
      this.runSearchQuery(event.target.value);
    }, DEBOUNCE_SEARCH_DELAY),

    handleResetButtonClick() {
      this.toggleVisible();
    },

    handleSearchFocus() {
      this.isSearchFocused = true;
    },

    handleSearchBlur() {
      if (this.isEmptyQuery) {
        this.isSearchFocused = false;
      }
    },

    toggleVisible() {
      this.isVisible = !this.isVisible;

      if (this.isVisible) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }

      this.clearSearchQuery();
      this.setHash("games");
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  top: $m_header-size;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 800;
  width: 100%;

  &__input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 0 0 10px;
    margin: 0;
    border-radius: 0;
    color: white;
    font-size: $font-xxs-size;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    border-left: none;
    border-right: none;
    text-transform: uppercase;
    text-align: left;
    outline: none;
    background-color: $bg-black-color;
    background-image: radial-gradient(
        ellipse 50% 150% at left,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      ),
      radial-gradient(ellipse 50% 150% at right, fade-out($bg-dark-color, 0.6) 0%, $null-color 100%);
  }

  &__reset-button {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 1000;
    fill: white;
  }
}

.search-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 800;
  fill: white;
  outline: none;
  width: 50px;
  height: 50px;
  padding: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  border: none;
}

.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
