import { errorActions, errorGetters, errorMutations, errorState } from "../mixins/error";
import * as api from "../../api";

// const testItem = {
//   available: true,
//   bonus: { stringId: "cashback" },
//   bonusAmount: 1000,
// };

/** State */
const state = {
  ...errorState,
  item: null,
};

/** Actions */
const actions = {
  ...errorActions,

  async fetch({ commit }) {
    try {
      const bonus = await api.bonus.fetchBonus();
      commit("setItem", bonus);
      if (state.error.message !== null) {
        commit("clearError");
      }
    } catch (error) {
      if (state.item !== null) {
        commit("setItem", null);
      }
      commit("setError", error.message);
    }
  },

  async use({ commit, dispatch }) {
    try {
      await api.bonus.useBonus();
      await dispatch("fetch");
      await dispatch("user/fetch", null, { root: true });
      commit("ui/hideBonusModal", null, { root: true });
      if (state.error.message !== null) {
        commit("clearError");
      }
    } catch (error) {
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  ...errorGetters,
};

/** Mutations */
const mutations = {
  ...errorMutations,

  setItem(state, item) {
    state.item = item;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
