<template>
  <div class="visually-hidden" role="presentation">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
      <title>{{ title }}</title>

      <defs>
        <pattern :id="patternId" x="0" y="0" width="6" height="6" patternUnits="userSpaceOnUse">
          <g fill="rgba(255,255,255, 0.1)">
            <path d="M3 6h1V4h2V3H3v3z" />
            <path d="M1 4h1V2h2V1H1v3z" />
            <path d="M0 5h2v1H0V5z" />
            <path d="M5 0h1v2H5V0z" />
            <path d="M5 5h1v1H5V5z" />
          </g>
        </pattern>

        <rect
          :id="buttonRectId"
          x="0"
          y="0"
          width="100%"
          height="100%"
          :fill="`url(#${patternId})`"
        />
      </defs>
    </svg>
  </div>
</template>

<script>
export const ID_OF_PATTERN = "svg_pattern";
export const ID_OF_PATTERN_RECT = "pattern_rect";

export default {
  name: "SvgRoot",

  data() {
    return {
      title: "Graphics container",

      patternId: ID_OF_PATTERN,
      buttonRectId: ID_OF_PATTERN_RECT,
    };
  },
};
</script>
