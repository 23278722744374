// store/modules/history.js
import { fetchHistoryGetSport } from "@/modules/SportBetHistory"; // Assuming you use axios for API calls

const initialState = {
  sportBetsHistory: {
    "created": {
      isPending: false,
      isFetching: false,
      isFetchingNextPage: false,
      isInitialFetch: true,
      hasNextPage: true,
      items: [],
    },
    "non-created": {
      isPending: false,
      isFetching: false,
      isFetchingNextPage: false,
      isInitialFetch: true,
      hasNextPage: true,
      items: [],
    },
  },
};

const state = () => ({ ...initialState });

const mutations = {
  setHistoryPending(state, payload) {
    const status = payload.status;
    state.sportBetsHistory[status].isPending = state.sportBetsHistory[status].isInitialFetch;
    state.sportBetsHistory[status].isFetchingNextPage = payload.offset > 0;
    state.sportBetsHistory[status].isFetching = true;
  },
  setHistorySuccess(state, payload) {
    const status = payload.status;
    const list = payload.response.list;
    state.sportBetsHistory[status].isPending = false;
    state.sportBetsHistory[status].isFetching = false;
    state.sportBetsHistory[status].isFetchingNextPage = false;
    state.sportBetsHistory[status].isInitialFetch = false;
    state.sportBetsHistory[status].hasNextPage = list.length > 1;
    if (state.sportBetsHistory[status].isFetchingNextPage) {
      state.sportBetsHistory[status].items = [...state.sportBetsHistory[status].items, ...list];
    } else {
      state.sportBetsHistory[status].items = list;
    }
  },
};

const actions = {
  async fetchSportBetsHistory({ commit }, { status, limit, offset }) {
    commit("setHistoryPending", { status, offset });
    try {
      const response = await fetchHistoryGetSport({ status, limit, offset });
      commit("setHistorySuccess", {
        status,
        response,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching sports bets history:", error);
    }
  },
};

const getters = {
  getSportBetsHistory: (state) => (status) => state.sportBetsHistory[status].items,
  isLoading: (state) => (status) => state.sportBetsHistory[status].isPending,
  hasNextPage: (state) => (status) => state.sportBetsHistory[status].hasNextPage,
  isFetchingNextPage: (state) => (status) => state.sportBetsHistory[status].isFetchingNextPage,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
