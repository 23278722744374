import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import Home from "./views/Home";
import Game from "./views/Game";
import SignIn from "./views/SignIn";
import Jackpots from "./views/Jackpots";
import MemberCard from "@/views/MemberCard.vue";
import WheelFortune from "@/views/WheelFortune.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        isSignInRequired: true,
      },
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: SignIn,
    },
    {
      path: "/member-card",
      name: "member-card",
      component: MemberCard,
      meta: {
        isSignInRequired: true,
      },
    },
    {
      path: "/games/:id",
      name: "game",
      component: Game,
      meta: {
        isSignInRequired: true,
      },
    },
    {
      path: "/jackpots/:streamId/:versionId?",
      name: "jackpots",
      component: Jackpots,
    },
    {
      path: "/wheel-fortune",
      name: "wheel-fortune",
      component: WheelFortune,
      meta: {
        isSignInRequired: true,
      },
    },
    {
      path: "/vk",
      redirect: { path: "/sign-in", query: { vk: null } },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isDemoModeActive = store.getters["app/isDemoModeActive"];

  // Change app loader state
  if (from.path !== to.path) {
    store.commit("app/setIsLoading", true);
  }

  // Redirect if user is not logged in
  if (to.meta.isSignInRequired && !isDemoModeActive && store.state.user.item === null) {
    return next({ path: "/sign-in", query: { redirect: to.path } });
  }
  return next();
});

export default router;
