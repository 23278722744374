var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagName,_vm._g(_vm._b({tag:"component",class:{
    [_vm.variantMap[_vm.variant]]: _vm.variant,
    [_vm.activeClass]: _vm.isActive,
    'btn--is-loading': _vm.isLoading,
    'btn--shadow': _vm.settings.hasShadow,
    'btn--shine': _vm.settings.hasShine,
    'btn--mobile': _vm.settings.hasShine,
    'btn--error': _vm.hasErrors,
    'btn--rounded': _vm.hasRounded,
    [_vm.disabledClass]: _vm.disabled || _vm.isLoading,
  },attrs:{"aria-label":_vm.ariaLabel || _vm.label,"disabled":_vm.isButton && (_vm.disabled || _vm.isLoading),"role":_vm.currentRole}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.hasContent && !_vm.isLoading)?_c('span',{staticClass:"btn__content"},[_vm._t("default")],2):_vm._e(),(_vm.hasTexture)?_c('svg-link',{class:{
      'btn__pattern': true,
      'btn--rounded': _vm.hasRounded,
    },attrs:{"href":"pattern","width":"100%","height":"100%"}}):_vm._e(),(_vm.icon)?_c('v-icon',_vm._b({staticClass:"btn__icon",attrs:{"name":_vm.icon}},'v-icon',_vm.iconProps,false)):_vm._e(),(_vm.isLoading)?_c('v-icon',{staticClass:"btn__loading",attrs:{"name":"loader"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }