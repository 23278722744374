<template>
  <div
    :class="[
      'nav',
      {
        'nav--translucent': translucent,
        'nav--compact': compact,
        'nav--right': isTheRight,
        'nav--hide': !isDisplay && isShowHide,
      },
    ]"
  >
    <v-button
      v-if="isShowSingOut"
      variant="icon"
      label="Sign-Out"
      icon="sign-out"
      class="nav__btn nav__btn--sign-out"
      :is-loading="isFetchLogOut"
      @click="handleSignOutClick"
    />

    <v-button
      v-if="isShowBack"
      variant="icon"
      label="Go back"
      icon="arrow"
      class="nav__btn nav__btn--back"
      @click="handleBackClick"
    />

    <div
      :class="[
        'nav__btn--star',
        {
          'nav__btn--star-active': isFavorite,
        },
      ]"
    >
      <StarButton
        v-if="isShowHide"
        variant="heart"
        :is-game-favorite="isFavorite"
        :icon-props="{ size: 32 }"
        :on-click="handleGameFavorite"
      />
    </div>

    <v-button
      variant="icon"
      label="Open menu"
      icon="menu"
      class="nav__btn nav__btn--menu"
      disabled-class="btn--hide"
      :disabled="isOpenMenu"
      @click="handleMenuClick"
    />

    <v-button
      v-if="isShowJackpots"
      variant="icon"
      label="Toggle jackpots"
      icon="jackpots"
      class="nav__btn nav__btn--jackpots"
      @click="handleJackpotsClick"
    />

    <v-button
      v-if="isShowFullscreen"
      variant="icon"
      icon="screen"
      label="Toggle fullscreen mode"
      class="nav__btn nav__btn--fullscreen"
      :icon-props="{ 'is-active': isEnableFullscreen }"
      @click="handleFullscreenClick"
    />

    <v-button
      v-if="isShowHide"
      variant="icon"
      icon="left"
      label="Hide"
      class="nav__btn nav__btn--hide"
      @click="handleHideClick"
    />
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapActions, mapGetters, mapState } from "vuex";
import { get } from "lodash-es";

// Utils
import { hasFullscreenMode, isFullscreen, toggleFullscreen } from "@/utils/fullscreen-api";

// Mixins
import routeMixin from "@/mixins/route";

// Components
import { VButton, StarButton } from "@/components/Helpers/Buttons";
import { gamesService } from "@/services/GamesService/GamesService";
import loadAllData from "@/mixins/load-all-data";

// Helpers
const OPEN_MENU_EVENT = "open-menu";
const TOGGLE_JACKPOTS_EVENT = "toggle-jackpots";

export default {
  name: "MobileNav",

  components: { VButton, StarButton },

  mixins: [routeMixin, loadAllData],

  model: {
    prop: "isOpenMenu",
    event: OPEN_MENU_EVENT,
  },

  props: {
    buttons: {
      type: Array,
      default: () => [],
    },

    isOpenMenu: {
      type: Boolean,
      default: false,
    },

    compact: {
      type: Boolean,
      default: false,
    },

    translucent: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFetchLogOut: false,
      isEnableFullscreen: false,
      isFavorite: false,
      currentGameId: null,
    };
  },

  computed: {
    ...mapState({
      isEnabledSingOut: (state) => get(state, "lobby.params.lobbyButtons.exit.enabled", false),
      isTheRight: (state) => state.ui.isNavigationTheRight,
      isDisplay: (state) => state.ui.isDisplayNavigation,
    }),

    ...mapGetters({
      gameItems: "games/items",
      hasJackpots: "jackpots/hasSlots",
      isCasinoOrSlots: "providers/isCasinoOrSlots",
    }),

    isShowSingOut() {
      return this.buttons.includes("sign-out") && this.isEnabledSingOut;
    },

    isShowBack() {
      return this.buttons.includes("back");
    },

    isShowJackpots() {
      return this.buttons.includes("jackpots") && this.isCasinoOrSlots && this.hasJackpots;
    },

    isShowFullscreen() {
      return this.buttons.includes("fullscreen") && hasFullscreenMode();
    },

    isShowHide() {
      return this.buttons.includes("hide");
    },
  },

  watch: {
    gameItems() {
      this.setCurrentGame();
    },
  },

  mounted() {
    this.setMobileNavigation(false);
    this.setCurrentGame();
  },

  methods: {
    ...mapActions({
      signOut: "user/signOut",
      toggleMobileNavigation: "ui/toggleMobileNavigation",
      setMobileNavigation: "ui/setMobileNavigation",
    }),

    setCurrentGame() {
      if (!this.gameItems) {
        return;
      }

      this.gameItems.find((game) => {
        if (game?.id === this.$route.params.id) {
          this.isFavorite = game?.isFavorite;
          this.currentGameId = game?.originalId;
        }
      });
    },

    async handleGameFavorite() {
      this.isFavorite = !this.isFavorite;

      const updatedGameId = await gamesService.updateGameFavoriteStatus(
        this.currentGameId,
        this.isFavorite
      );

      this.$store.commit("games/updateFavoriteItem", {
        originalId: updatedGameId,
        isFavorite: this.isFavorite,
      });
    },

    handleMenuClick() {
      this.$emit(OPEN_MENU_EVENT, true);
    },

    handleJackpotsClick() {
      this.$emit(TOGGLE_JACKPOTS_EVENT);
    },

    async handleSignOutClick() {
      this.isFetchLogOut = true;
      try {
        await this.signOut();

        // Redirect to home page
        this.goToLoginPage();
      } catch (e) {
        console.error(e);
      } finally {
        this.isFetchLogOut = false;
      }
    },

    handleBackClick() {
      this.resetRoute();
    },

    async handleFullscreenClick() {
      this.isEnableFullscreen = toggleFullscreen();
    },

    handleHideClick() {
      this.toggleMobileNavigation();
    },

    fullscreenChange() {
      this.isEnableFullscreen = isFullscreen();
    },
  },
};
</script>

<style lang="scss" scoped>
$size: 64px;
$dragSize: 32px;

$size-sm: 58px;
$dragSize-sm: 28px;

.nav {
  top: 0;
  height: $size;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: absolute;
  transition: background 0.25s ease, transform 0.5s ease;
  overflow: hidden;
  z-index: 110;

  &--translucent {
    background-color: fade-out($bg-black-color, 0.5);
    border-radius: 0 0 5px 5px;

    .nav__btn {
      &--hide {
        border-style: solid;
        border-width: 0 0 0 1px;
        border-color: fade-out($dark-color, 0.5);
      }
    }
  }

  &__btn {
    width: $size;
    color: $white-color;
    fill: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s ease;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 90;

    &--star {
      display: flex;
      justify-content: center;
      align-items: center;
      color: transparent;
      stroke: $white-color;

      &::v-deep svg {
        color: transparent;
      }
    }

    &--star-active {
      &::v-deep svg {
        color: white;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &::v-deep svg {
      width: 50%;
      height: 50%;
    }

    &--hide {
      $offset: $dragSize * 0.2;

      width: $dragSize;
      padding: 0 $offset;
      margin-right: $offset;

      &::v-deep svg {
        opacity: 0.5;
        width: 100%;
      }
    }
  }

  &--compact {
    height: $size-sm;

    .nav__btn {
      width: $size-sm;

      &--hide {
        $offset: $dragSize-sm * 0.2;

        width: $dragSize-sm;
        padding: 0 $offset;
        margin-right: $offset;
      }
    }
  }

  &--hide {
    transform: translateX(calc(-100% + #{$dragSize}));

    .nav__btn--hide {
      transform: scale(1.4);

      &::v-deep svg {
        opacity: 0.8;
      }
    }
  }

  &:not(&--right) {
    left: 0;
    padding-left: 4px;

    .nav__btn {
      &--menu {
        order: -1;
      }

      &--fullscreen {
        order: 0;
      }

      &--back,
      &--sign-out {
        order: 2;
      }

      &--hide {
        order: 99;
      }
    }
  }

  &--right {
    right: 0;
    padding-right: 4px;

    &.nav--hide {
      transform: translateX(calc(100% - #{$dragSize}));
    }

    .nav__btn {
      &--menu {
        order: 99;
      }

      &--back,
      &--sign-out {
        order: 0;
      }

      &--hide {
        border-width: 0 1px 0 0;
        order: -1;
      }
    }
  }

  &--hide:not(.nav--right),
  &--right:not(.nav--hide) {
    .nav__btn--hide::v-deep svg {
      transform: scale(-1, 1);
    }
  }
}
</style>
