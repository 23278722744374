import { errorMessageMutations, errorMessageState } from "../mixins/error-message";
import { get, defaultTo, first, isEmpty, has } from "lodash-es";
import { Client as WebSocket } from "rpc-websockets";
import { JACKPOT_ID } from "../../constants";

/** Utils */
import { getJackpotsApiUrl } from "../../utils";

/** Helpers */
let ws;

/** State */
const state = {
  ...errorMessageState,

  rawWins: [],
  rawSlots: [],
  cooldowns: {},
  currency: "",
  isConnected: false,
};

/** Actions */
const actions = {
  async connect({ commit }, streamId) {
    if (!streamId) {
      return;
    }

    const url = getJackpotsApiUrl(streamId);

    return new Promise((resolve) => {
      ws = new WebSocket(url);

      ws.on("open", async () => {
        ws.on("Jackpot.UpdateSlots", (event) => {
          const slots = get(event, "updates", []);
          commit("updateRawSlots", slots);
        });

        ws.on("Jackpot.WinStatus", (event) => {
          const win = get(event, "win", null);
          commit("updateRawWins", win);
        });

        ws.on("Jackpot.CommunityCooldown", (event) => {
          const cooldowns = get(event, "cooldowns", null);
          commit("updateCooldowns", cooldowns);
        });

        {
          const response = await ws.call("Jackpot.GetState");

          const slots = get(response, "slots", []);
          commit("setRawSlots", slots);

          let wins = get(response, "wins", []);
          wins = wins === null ? [] : wins;
          commit("setRawWins", wins);
        }

        {
          const response = await ws.call("BankGroup.Get");
          const currency = get(response, "Currency", "");
          commit("setCurrency", currency);
        }

        commit("setIsConnected", true);
        resolve();
      });

      ws.on("error", (error) => {
        commit("setError", error.message);

        commit("setIsConnected", false);
        resolve();
      });
    });
  },

  async acceptWin({ commit }, winId) {
    try {
      await ws.call("Jackpot.AcceptWin", { win_id: winId });
    } catch (error) {
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  slots: (state) =>
    defaultTo(state.rawSlots, [])
      .filter((j) => j.jackpot_id === JACKPOT_ID)
      .sort((a, b) => a.slot - b.slot)
      .map((j) => ({
        isCommunity: j.is_community,
        value: j.value,
      })),

  win: (state) =>
    defaultTo(
      first(
        state.rawWins
          .filter((w) => w.jackpot_id === JACKPOT_ID)
          .map((w) => ({
            id: w.win_id,
            state: w.state,
            isViewed: w.isViewed,
            value: w.value,
            isCommunity: get(
              state.rawSlots.filter((j) => j.jackpot_id === w.jackpot_id && j.slot === w.slot),
              "[0].is_community",
              false
            ),
            slot: w.slot,
          }))
          .filter((w) => {
            if (w.state === "pending") {
              return true;
            } else if (w.isCommunity && w.state === "paid" && !w.isViewed) {
              return true;
            } else if (w.state === "canceled" && !w.isViewed) {
              return true;
            }
            return false;
          })
      ),
      null
    ),

  hasSlots: (state, getters) => {
    return !isEmpty(getters.slots) && getters.slots.every((j) => has(j, "value"));
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,

  setRawSlots(state, rawSlots) {
    state.rawSlots = rawSlots;
  },

  setRawWins(state, rawWins) {
    state.rawWins = rawWins.map((w) => ({ ...w, isViewed: true }));
  },

  setCurrency(state, currency) {
    state.currency = currency;
  },

  updateRawSlots(state, rawSlots) {
    state.rawSlots = state.rawSlots.map((rawSlot1) => {
      const rawSlot2 = rawSlots.find(
        (rawSlot2) => rawSlot1.jackpot_id === rawSlot2.jackpot_id && rawSlot1.slot === rawSlot2.slot
      );
      if (rawSlot2) {
        rawSlot1.value = rawSlot2.value;
      }
      return rawSlot1;
    });
  },

  updateRawWins(state, rawWin) {
    const index = state.rawWins.findIndex((w) => w.win_id === rawWin.win_id);
    if (index === -1) {
      state.rawWins = [...state.rawWins, { ...rawWin, isViewed: false }];
    } else {
      state.rawWins = state.rawWins.map((w, i) => {
        if (i === index) {
          let isViewed = w.state === "pending" && rawWin.state === "paid";
          if (w.state === "pending" && rawWin.state === "canceled") {
            isViewed = false;
          }
          return {
            ...w,
            state: rawWin.state,
            isViewed,
          };
        }
        return w;
      });
    }
  },

  updateCooldowns(state, rawCooldowns) {
    const cooldowns = { ...state.cooldowns };
    rawCooldowns
      .filter((w) => w.jackpot_id === JACKPOT_ID)
      .forEach((c) => {
        cooldowns[c.slot] = Date.now() + c.cooldown_ms;
      });
    state.cooldowns = cooldowns;
  },

  markWinAsViewed(state, winId) {
    state.rawWins = state.rawWins.map((win) => {
      if (win.win_id === winId) {
        win.isViewed = true;
      }
      return win;
    });
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
