var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'nav',
    {
      'nav--translucent': _vm.translucent,
      'nav--compact': _vm.compact,
      'nav--right': _vm.isTheRight,
      'nav--hide': !_vm.isDisplay && _vm.isShowHide,
    },
  ]},[(_vm.isShowSingOut)?_c('v-button',{staticClass:"nav__btn nav__btn--sign-out",attrs:{"variant":"icon","label":"Sign-Out","icon":"sign-out","is-loading":_vm.isFetchLogOut},on:{"click":_vm.handleSignOutClick}}):_vm._e(),(_vm.isShowBack)?_c('v-button',{staticClass:"nav__btn nav__btn--back",attrs:{"variant":"icon","label":"Go back","icon":"arrow"},on:{"click":_vm.handleBackClick}}):_vm._e(),_c('div',{class:[
      'nav__btn--star',
      {
        'nav__btn--star-active': _vm.isFavorite,
      },
    ]},[(_vm.isShowHide)?_c('StarButton',{attrs:{"variant":"heart","is-game-favorite":_vm.isFavorite,"icon-props":{ size: 32 },"on-click":_vm.handleGameFavorite}}):_vm._e()],1),_c('v-button',{staticClass:"nav__btn nav__btn--menu",attrs:{"variant":"icon","label":"Open menu","icon":"menu","disabled-class":"btn--hide","disabled":_vm.isOpenMenu},on:{"click":_vm.handleMenuClick}}),(_vm.isShowJackpots)?_c('v-button',{staticClass:"nav__btn nav__btn--jackpots",attrs:{"variant":"icon","label":"Toggle jackpots","icon":"jackpots"},on:{"click":_vm.handleJackpotsClick}}):_vm._e(),(_vm.isShowFullscreen)?_c('v-button',{staticClass:"nav__btn nav__btn--fullscreen",attrs:{"variant":"icon","icon":"screen","label":"Toggle fullscreen mode","icon-props":{ 'is-active': _vm.isEnableFullscreen }},on:{"click":_vm.handleFullscreenClick}}):_vm._e(),(_vm.isShowHide)?_c('v-button',{staticClass:"nav__btn nav__btn--hide",attrs:{"variant":"icon","icon":"left","label":"Hide"},on:{"click":_vm.handleHideClick}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }