<template>
  <div :class="[jackpotClass, { [`${jackpotClass}--community`]: isCommunity }]">
    <slot name="before" />

    <slot :jackpotClass="jackpotClass" :name="name" :money="money" :currency="currency">
      <div :class="`${jackpotClass}__name`">{{ name }}</div>
      <div :class="`${jackpotClass}__value`">{{ money }} {{ currency }}</div>
    </slot>

    <slot name="after" />
  </div>
</template>

<script>
export default {
  name: "Jackpot",

  props: {
    value: {
      type: [Number, String, null],
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    currency: {
      type: [String, null],
      required: true,
    },

    jackpotClass: {
      type: String,
      default: "jackpot",
    },

    isCommunity: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    money() {
      return this.$f.formatMoney(this.$f.toMoney(this.value));
    },
  },
};
</script>
