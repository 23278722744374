<template>
  <div class="mobile-footer">
    <div class="mobile-footer__side">
      <language-select />
      <div v-if="hasDemoMode" class="mobile-footer__content">
        <div class="mobile-footer__content-row uppercase">
          {{ _("free.spins") }}
        </div>
      </div>
    </div>

    <div v-if="!hasUser" class="mobile-footer__side">
      <v-button
        variant="primary"
        class="mobile-footer__button mobile-footer__button--sign-in uppercase"
        @click="goToLogin"
      >
        {{ _("sign-in.log-in") }}
      </v-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { get, upperFirst } from "lodash-es";

// Mixins
import routeMixin from "@/mixins/route";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import LanguageSelect from "@/components/Helpers/language-select.vue";

export default {
  name: "MobileFooter",

  components: {
    LanguageSelect,
    VButton,
  },

  mixins: [routeMixin],

  computed: {
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
      hasDemoMode: (state) => state.app.hasDemoMode,
      bonus: (state) => {
        const { item } = state.bonus;
        if (!item || !item.bonus || !item.available) {
          return null;
        }
        return {
          name: upperFirst(item.bonus.stringId),
          amount: item.bonus.amount,
        };
      },
    }),

    ...mapGetters({
      filteredItemsCode: "l10n/allowLocalesFromApi",
      _: "l10n/translate",
      hasUser: "user/hasUser",
      userCurrency: "user/currency",
    }),

    currency() {
      return this.isSweepstakesEnabled ? "" : this.userCurrency;
    },
  },

  methods: {
    ...mapMutations({
      showBonusModal: "ui/showBonusModal",
    }),
  },
};
</script>

<style lang="scss" scoped>
.mobile-footer {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: absolute;

  @media (orientation: portrait) {
    padding: $m_footer-padding;
  }

  @media (orientation: landscape) {
    padding: $m_footer-padding * 0.8;
  }

  @media (min-width: 1025px) {
    display: none;
  }
}

.mobile-footer__side {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child {
    .mobile-footer__content {
      text-align: left;
    }
  }

  @media (orientation: landscape) {
    &:first-child {
      .mobile-footer__content {
        margin-left: 25px;
      }
    }
  }

  @media (orientation: portrait) {
    &:first-child {
      .mobile-footer__content {
        left: 20px;
        right: auto;
        font-size: 22px;
      }
    }
  }
}

.mobile-footer__button {
  min-width: $m_footer-btn-width;
  height: $m_footer-btn-height;
  font-size: $font-lg-size;
  background-size: contain;
}

.mobile-footer__content {
  display: flex;
  text-align: right;
  color: $text-color;
  font-family: $base-font-family;

  @media (orientation: portrait) {
    top: -20px;
    right: 20px;
    height: 34px;
    align-items: flex-end;
    flex-direction: column;
    font-size: $font-xs-size;
    position: absolute;
  }

  @media (orientation: landscape) {
    flex-direction: column;
    font-size: $base-font-size;
    margin-right: 25px;
  }
}
</style>
