<template>
  <component
    :is="icon"
    :class="['icon', `icon--${name}`, { [activeClass]: isActive }]"
    :width="width"
    :height="size"
    :style="iconStyle"
    role="presentation"
  />
</template>

<script>
import { validateProp } from "@/utils/validator";

// Icons
import IconMenu from "@/assets/icons/menu.svg";
import IconClose from "@/assets/icons/close.svg";
import IconSearch from "@/assets/icons/search.svg";
import IconSignOut from "@/assets/icons/sign-out.svg";
import IconSwipeUp from "@/assets/icons/swipe-up.svg";
import IconArrow from "@/assets/icons/left-arrow.svg";
import IconScreen from "@/assets/icons/fullscreen.svg";
import IconLeft from "@/assets/icons/left-direction.svg";
import IconRotation from "@/assets/icons/rotation.svg";
import IconJackpots from "@/assets/icons/jackpots.svg";
import IconLoader from "@/assets/icons/loader.svg";
import IconHeart from "@/assets/icons/heart.svg";
import IconAviator from "@/assets/icons/aviator.svg";
import IconChineseTag from "@/assets/icons/chinese-tag.svg";
import IconClock from "@/assets/icons/clock.svg";
import IconRefresh from "@/assets/icons/refresh.svg";
import IconHalloween from "@/assets/icons/halloween.svg";
import IconChristmas from "@/assets/icons/christmas.svg";
import IconPresent from "@/assets/icons/present.svg";

export const ICONS = [
  "menu",
  "close",
  "search",
  "sign-out",
  "swipe-up",
  "arrow",
  "screen",
  "left",
  "jackpots",
  "rotation",
  "loader",
  "heart",
  "aviator",
  "clock",
  "refresh",
  "halloween",
  "christmas",
  "present",
  "chinese-tag",
];

export default {
  name: "VIcon",

  props: {
    name: {
      type: String,
      required: true,
      validator: (value) => validateProp(value, ICONS),
    },

    /** Height by pixels */
    size: {
      type: Number,
      default: 32,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    activeClass: {
      type: String,
      default: "icon--active",
    },

    rotate: {
      type: Number,
      default: 0,
    },

    scaleX: {
      type: Number,
      default: 1,
    },

    scaleY: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      /** Set default icon props */
      defaultIconProps: {
        aspectRatio: 1,
      },

      /** Set icon props by iconName */
      dataMap: {},

      iconMap: {
        "menu": IconMenu,
        "left": IconLeft,
        "arrow": IconArrow,
        "close": IconClose,
        "screen": IconScreen,
        "search": IconSearch,
        "sign-out": IconSignOut,
        "swipe-up": IconSwipeUp,
        "rotation": IconRotation,
        "jackpots": IconJackpots,
        "loader": IconLoader,
        "heart": IconHeart,
        "aviator": IconAviator,
        "clock": IconClock,
        "refresh": IconRefresh,
        "halloween": IconHalloween,
        "christmas": IconChristmas,
        "present": IconPresent,
        "chinese-tag": IconChineseTag,
      },
    };
  },

  computed: {
    icon() {
      const icon = this.iconMap[this.name];
      return icon ? icon : null;
    },

    /** Dynamic load icon by name */
    iconProps() {
      return {
        ...this.defaultIconProps,
        ...this.dataMap[this.name],
      };
    },

    width() {
      return this.size * this.iconProps.aspectRatio;
    },

    iconStyle() {
      const transform = [];
      if (this.rotate !== 0) {
        transform.push(`rotate(${this.rotate}deg)`);
      }
      if (this.scaleX && this.scaleX !== 1) {
        transform.push(`scaleX(${this.scaleX})`);
      }
      if (this.scaleY && this.scaleY !== 1) {
        transform.push(`scaleY(${this.scaleY})`);
      }
      return transform.length ? { transform: transform.join(" ") } : null;
    },
  },
};
</script>

<style lang="scss">
.icon {
  transition: transform 0.3s;

  > path {
    transition: transform 0.3s, opacity 0.3s;
  }

  &--screen {
    &.icon--active {
      path:last-child {
        opacity: 0;
      }
    }
  }
}
</style>
