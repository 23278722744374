import { mapState, mapGetters, mapActions } from "vuex";

// Mixins
import routeMixin from "@/mixins/route";

export default {
  mixins: [routeMixin],
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
      currentTagId: (state) => state.tags.currentItemId,
      currentBrandId: (state) => state.brands.currentItemId,
      currentProviderId: (state) => state.providers.currentItemId,
      isFavoriteFilter: (state) => state.tags.isFavoriteFilter,
      isRecommendationFilter: (state) => state.tags.isRecommendationFilter,
      searchQuery: (state) => state.search.query,
    }),

    ...mapGetters({
      tags: "tags/computedItems",
      brands: "brands/computedItems",
      providers: "providers/items",
      isEmptyQuery: "search/isEmptyQuery",
    }),
  },

  methods: {
    ...mapActions({
      setCurrentTagId: "tags/setCurrentItemId",
      setCurrentBrandId: "brands/setCurrentItemId",
      setCurrentProviderId: "providers/setCurrentItemId",
      setFavorites: "tags/setFavoriteItems",
      setRecommendations: "tags/setRecommendationItems",
      setDefaultTagId: "tags/setDefaultItemId",
      setDefaultBrandId: "brands/setDefaultItemId",
      setSearchQuery: "search/setSearchQuery",
      clearSearchQuery: "search/clearSearchQuery",
    }),

    /**
     * @param {Object} provider
     * @description Set current provider id.
     */
    selectProvider(provider) {
      if (provider) {
        if (provider.games.length === 1 && !this.searchQuery.length) {
          this.openGameById(provider.games[0].id);
        } else {
          this.setCurrentProviderId(provider?.id);
          this.resetGamesFilter();
          this.goToGamesList();
        }
      }
    },

    /**
     * @param {number, undefined} brandId
     * @param {boolean} resetTagId
     * @description Set current brand id.
     */
    selectBrand(brandId, resetTagId = false) {
      this.setCurrentBrandId(brandId);
      if (resetTagId) {
        this.setDefaultTagId();
      }
    },

    /**
     * @param {string, undefined} tagId
     * @description Set current tag id.
     */
    selectTag(tagId) {
      this.setCurrentTagId(tagId);
      this.goToGamesList();
    },
    /**
     * @param {boolean} isFavorite
     */
    selectFavorite(isFavorite) {
      this.setFavorites(isFavorite);
      this.goToGamesList();
    },

    selectRecommendation(isRecommendation) {
      this.setRecommendations(isRecommendation);
      this.goToGamesList();
    },

    /**
     * @param {string, undefined} tagId
     * @description Set current tag id.
     */
    selectProviderTag(tagId) {
      this.setDefaultBrandId();
      this.setCurrentTagId(tagId);
      this.goToGamesList();
    },

    /**
     * @param {string} searchQuery
     * @description Set search query.
     */
    runSearchQuery(searchQuery) {
      this.setDefaultTagId();
      this.setSearchQuery(searchQuery);
    },

    /**
     * @description Set to default current brand & tag ids.
     */
    resetGamesFilter() {
      this.setDefaultBrandId();
      this.setDefaultTagId();
    },

    /**
     * @description go to Home page & slide to games.
     */
    goToGamesList() {
      if (this.isHomePage) {
        if (this.isMobile) {
          this.scrollToGames();
        }
      } else {
        this.goToGames(this.isMobile);
      }
    },
  },
};
