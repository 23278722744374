// Utils
import { getBoolean } from "../../utils";

/** State */
const state = {
  isLoading: false,
  isMobile: false,
  isLandscapeMode: false,
  hasDemoMode: getBoolean(process.env.VUE_APP_HAS_DEMO),
};

/** Getters */
const getters = {
  isDemoModeActive(state, getters, rootState, rootGetters) {
    return !rootGetters["user/hasUser"] && state.hasDemoMode;
  },
};

/** Mutations */
const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setIsMobile(state, isMobile) {
    state.isMobile = isMobile;
  },

  setIsLandscapeMode(state, isLandscapeMode) {
    state.isLandscapeMode = isLandscapeMode;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  state,
};
