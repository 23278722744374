import { notEmptyArray } from "@/utils";
import { filterService } from "@/services/QueryFilterService/query-filter-service";

/** State */
export const itemsState = {
  rawItems: null,
  isFavoriteFilter: filterService.getFilterParams().isFavorite ?? false,
  isRecommendationFilter: filterService.getFilterParams().isRecommendation ?? false,
  currentItemId: null,
  defaultItemId: null,
};

/** Actions */
export const itemsActions = {
  setCurrentItemId({ state, commit }, itemId) {
    commit("setCurrentItemId", itemId ? itemId : state.defaultItemId);
  },

  setDefaultItemId(state) {
    state.commit("setCurrentItemIdToDefault");
  },

  setFavoriteItems({ commit }, isFavorite) {
    filterService.setParam("isFavorite", isFavorite);
    commit("setFavoriteItems", isFavorite);
  },

  setRecommendationItems({ commit }, isRecommendation) {
    filterService.setParam("isRecommendation", isRecommendation);
    commit("setRecommendationItems", isRecommendation);
  },
};

/** Getters */
export const itemsGetters = {
  /**
   * @return {(Object, undefined)}
   * @description Get currentItem by currentItemId.
   */
  currentItem(state) {
    return notEmptyArray(state.rawItems)
      ? state.rawItems.find((item) => item.id === state.currentItemId)
      : undefined;
  },
};

/** Mutations */
export const itemsMutations = {
  setRawItems(state, rawItems) {
    state.rawItems = rawItems;
  },

  clearRawItems(state) {
    state.rawItems = null;
    state.currentItemId = state.defaultItemId;
  },

  setCurrentItemId(state, itemId) {
    state.currentItemId = itemId;
  },

  setUpdateGamesCbsList(state, items) {
    state.updatedGamesCbsList = items;
  },

  setFavoriteItems(state, isFavorite) {
    state.isFavoriteFilter = isFavorite;
  },

  setRecommendationItems(state, isRecommendation) {
    state.isRecommendationFilter = isRecommendation;
  },

  setCurrentItemIdToDefault(state) {
    state.currentItemId = state.defaultItemId;
  },
};
