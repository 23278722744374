import { call } from "@/api/client";

export async function fetchHistoryGetSport({ status, limit, offset }) {
  try {
    return await call("History.getSport", {
      status,
      limit,
      offset,
    });
  } catch (error) {
    return null;
  }
}
