import { has } from "lodash-es";
import { call } from "./client";
import { userWatchActivityService } from "@/services/UserWatchActivityService/UserWatchActivityService";

/**
 * @typedef {Object} User
 * @property {number} casinoId
 * @property {number} balance
 * @property {string} currency
 * @property {string} displayableName
 */

/**
 * @typedef {Object} LoginWithPasswordOptions
 * @property {string} login
 * @property {string} password
 * @property {boolean} [remember]
 *
 * @typedef {LoginWithPasswordOptions} LoginOptions
 */
/**
 * @param {LoginOptions} options
 * @returns {Promise<?User>}
 */
export async function signIn(options) {
  const response = await call("Auth.login", options);
  return has(response, "user") ? response.user : null;
}

/**
 * @returns {Promise}
 */
export async function signOut() {
  return await call("Auth.logout");
}

/**
 * @returns {Promise<?User>}
 */
export async function fetchUser() {
  return await call("Auth.user", {
    isUserActive: userWatchActivityService.isUserActive,
  });
}

export async function fetchPin() {
  const pinResponse = await call("Auth.generatePin");

  if (!pinResponse) {
    // eslint-disable-next-line no-console
    console.error("Pin not response");
  }

  return pinResponse?.pin ?? "";
}
