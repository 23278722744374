import { lowerCase } from "lodash-es";

const DEFAULT_QUERY = "";

/** State */
const state = {
  query: DEFAULT_QUERY,
};

/** Actions */
const actions = {
  setSearchQuery({ commit }, searchQuery) {
    commit("setQuery", searchQuery);
  },

  clearSearchQuery({ commit }) {
    commit("setQuery", DEFAULT_QUERY);
  },
};

/** Getters */
const getters = {
  isEmptyQuery: (state) => !state.query,

  currentQuery: (state) => lowerCase(state.query),
};

/** Mutations */
const mutations = {
  setQuery(state, searchQuery = DEFAULT_QUERY) {
    if (searchQuery.trim() !== state.query) {
      state.query = searchQuery;
    }
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
