var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasUser)?_c('div',{class:[
    'sweepstakes',
    {
      'sweepstakes--column': _vm.isColumn,
      'sweepstakes--small': _vm.isSmall,
    },
  ]},[_c('div',{staticClass:"sweepstakes__item"},[_c('div',{staticClass:"sweepstakes__title"},[_vm._v(" "+_vm._s(_vm._("entries"))+" ")]),_c('div',{staticClass:"sweepstakes__value"},[_vm._v(" "+_vm._s(_vm.$f.formatMoney(_vm.$f.toMoney(_vm.sweepstakesBalance?.entries)))+" ")])]),_c('div',{staticClass:"sweepstakes__item"},[_c('div',{staticClass:"sweepstakes__title"},[_vm._v(_vm._s(_vm._("total-win"))+" ("+_vm._s(_vm.currency)+")")]),_c('div',{staticClass:"sweepstakes__value"},[_vm._v(" "+_vm._s(_vm.$f.formatMoney(_vm.$f.toMoney(_vm.sweepstakesBalance?.totalWin)))+" ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }