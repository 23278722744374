<template>
  <base-modal
    v-if="isShowBonusModal"
    :title="_('take-bonus')"
    :content="{
      key: bonus.name,
      value: `${money} ${isSweepstakesEnabled ? '' : currency}`,
    }"
  >
    <template #footer>
      <v-button
        class="modal__button"
        variant="accept"
        has-texture
        :is-loading="isFetchBonus"
        @click="handleTakeClick"
      >
        {{ _("take-bonus-now") }}
      </v-button>

      <v-button class="modal__button" variant="cancel" has-texture @click="hideBonus">
        {{ _("take-bonus-later") }}
      </v-button>
    </template>
  </base-modal>
</template>

<script>
/* eslint-disable no-console */
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { get, upperFirst } from "lodash-es";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import BaseModal from "./BaseModal";

export default {
  name: "BonusModal",

  components: {
    VButton,
    BaseModal,
  },

  data() {
    return {
      isFetchBonus: false,
    };
  },

  computed: {
    ...mapState({
      bonus: (state) => {
        const { item } = state.bonus;
        if (!item || !item.bonus || !item.available) {
          return null;
        }
        return {
          name: upperFirst(item.bonus.stringId),
          amount: item.bonus.amount,
        };
      },

      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
      isBonusModalShown: (state) => state.ui.isBonusModalShown,
    }),

    ...mapGetters({
      _: "l10n/translate",
      currency: "user/currency",
    }),

    isShowBonusModal() {
      return this.bonus && this.isBonusModalShown;
    },

    money() {
      return this.$f.formatMoney(this.$f.toMoney(this.bonus.amount));
    },
  },

  methods: {
    ...mapActions({
      takeBonus: "bonus/use",
    }),

    ...mapMutations({
      hideBonus: "ui/hideBonusModal",
    }),

    handleTakeClick() {
      this.isFetchBonus = true;
      try {
        this.takeBonus();
      } catch (e) {
        console.error(e);
      }
      this.isFetchBonus = false;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .modal__header {
  font-size: 44px;
  line-height: 88px;
}
</style>
