import { itemsActions, itemsGetters, itemsMutations, itemsState } from "../mixins/items";
import { errorMessageMutations, errorMessageState } from "../mixins/error-message";

// Constants
import { providerIdMap } from "@/constants/game";

// API
import * as api from "../../api";

// Utils
import { getCurrentProviderId } from "@/utils/gamester";
import { filterService } from "@/services/QueryFilterService/query-filter-service";

/** State */
const state = {
  ...errorMessageState,
  ...itemsState,
};

/** Actions */
const actions = {
  ...itemsActions,

  async fetch({ commit, rootState }) {
    try {
      const providers = await api.providers.fetchProviders();
      const currentProviderId = getCurrentProviderId(rootState.games.countOfGamesBy.providers);
      const { provider } = filterService.getFilterParams();

      commit("setRawItems", providers);
      commit("setCurrentItemId", provider ?? currentProviderId);
      commit("clearError");
    } catch (error) {
      commit("clearRawItems", null);
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  ...itemsGetters,

  items(state, getters, rootState, rootGetters) {
    if (state.rawItems === null) {
      return [];
    }

    return state.rawItems.map((item) => {
      const games = rootGetters["games/filteredItems"](item.id, null, null, null, null).filter(
        (game) => !game.isHidden
      );

      return {
        ...item,
        games,
        gamesCount: games.length,
      };
    });
  },

  isCurrentItem: (state, getters) => (provider) => {
    return getters.currentItem ? getters.currentItem.stringId === providerIdMap[provider] : false;
  },

  isCasino(state, getters) {
    return getters.isCurrentItem("casino");
  },

  isSlots(state, getters) {
    return getters.isCurrentItem("slots");
  },

  isCasinoOrSlots(state, getters) {
    return getters.isCasino || getters.isSlots;
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,
  ...itemsMutations,
  setCurrentItemId(state, itemId) {
    const { provider } = filterService.getFilterParams();

    if (provider !== itemId) {
      filterService.setParam("brand", null);
      filterService.setParam("tag", null);
    }

    filterService.setParam("provider", itemId);
    state.currentItemId = itemId;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
