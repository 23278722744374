<template>
  <div class="member-card-page">
    <div class="member-card-wrapper">
      <div class="member-card member-card--front" />
      <div
        :class="['member-card member-card--back', { 'member-card--open': isCardOpen }]"
        @click="toggleCard"
      >
        <div class="member-card__content">
          <div class="member-card__pin">
            <label>{{ _("pin").toUpperCase() }}:</label>
            <span> {{ pinWithDelimiter }}</span>
            <div class="member-card__refresh" @click="handlePinRefresh">
              <VIcon name="refresh" :size="20" />
            </div>
          </div>
          <div class="member-card__barcode">
            <canvas ref="canvas" />
          </div>
          <div class="member-card__human-title">{{ barCodeHumanize }}</div>
        </div>
      </div>
      <v-button
        :class="['member-card-button', { 'member-card-button--hidden': isCardOpen }]"
        tag-name="button"
        variant="primary"
        has-texture
        type="submit"
        @click="toggleCard"
      >
        {{ _("login_to_kiosk") }}
      </v-button>
      <a class="member-card-button member-card-button--link" @click="handleBackClick">
        {{ _("back_to_lobby") }}
      </a>
      <p>
        {{ _("member_card_use_1") }}
        <svg
          viewBox="0 0 69 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="member-card-share-icon"
        >
          <path
            d="M12.4512 87.1093H56.25C60.4166 87.1093 63.5335 86.0839 65.6006 84.0331C67.6677 81.9823 68.7012 78.9062 68.7012 74.8046V37.2557C68.7012 33.1542 67.6677 30.078 65.6006 28.0272C63.5335 25.9764 60.4166 24.951 56.25 24.951H45.752V32.0311H56.1524C57.9102 32.0311 59.2692 32.495 60.2295 33.4227C61.1897 34.3505 61.6699 35.7421 61.6699 37.5975V74.4628C61.6699 76.3183 61.1897 77.7099 60.2295 78.6376C59.2692 79.5654 57.9102 80.0292 56.1524 80.0292H12.5489C10.791 80.0292 9.43195 79.5654 8.47168 78.6376C7.51141 77.7099 7.03128 76.3183 7.03128 74.4628V37.5975C7.03128 35.7421 7.51141 34.3505 8.47168 33.4227C9.43195 32.495 10.791 32.0311 12.5489 32.0311H22.9492V24.951H12.4512C8.31705 24.951 5.20831 25.9764 3.12498 28.0272C1.04166 30.078 0 33.1542 0 37.2557V74.8046C0 78.9062 1.04166 81.9823 3.12498 84.0331C5.20831 86.0839 8.31705 87.1093 12.4512 87.1093Z"
          ></path>
          <path
            d="M34.3262 56.8359C35.2702 56.8359 36.0921 56.5022 36.792 55.8349C37.4918 55.1676 37.8418 54.362 37.8418 53.4179V15.332L37.5489 9.4726L39.5996 11.7187L44.9707 17.5293C45.5566 18.2129 46.3379 18.5547 47.3145 18.5547C48.2259 18.5547 48.9909 18.2699 49.6094 17.7002C50.2278 17.1305 50.5371 16.4062 50.5371 15.5273C50.5371 14.681 50.179 13.916 49.4629 13.2324L36.8653 1.1718C36.4421 0.716132 36.027 0.406902 35.6201 0.244102C35.2132 0.0813691 34.7819 0 34.3262 0C33.903 0 33.4879 0.0813691 33.0811 0.244102C32.6741 0.406902 32.2428 0.716132 31.7871 1.1718L19.1895 13.2324C18.5059 13.916 18.1641 14.681 18.1641 15.5273C18.1641 16.4062 18.457 17.1305 19.043 17.7002C19.6289 18.2699 20.3939 18.5547 21.3379 18.5547C22.3145 18.5547 23.112 18.2129 23.7305 17.5293L29.1016 11.7187L31.1524 9.4726L30.8594 15.332V53.4179C30.8594 54.362 31.2093 55.1676 31.9092 55.8349C32.609 56.5022 33.4147 56.8359 34.3262 56.8359Z"
          ></path>
        </svg>
        {{ _("member_card_use_2") }}
      </p>
    </div>
  </div>
</template>

<script>
import loadAllData from "@/mixins/load-all-data";
import routeMixin from "@/mixins/route";
import { chunk } from "lodash-es";
import { mapGetters, mapState } from "vuex";
import { drawBarCode } from "@/utils/bar-code";
import { VButton } from "@/components/Helpers/Buttons";
import { fetchPin } from "@/api/auth";
import VIcon from "@/components/Helpers/Icons/VIcon.vue";

const BAR_CODE_DEFAULT = "0000000000000000";

export default {
  name: "MemberCard",
  components: { VIcon, VButton },
  mixins: [loadAllData, routeMixin],
  data: () => ({
    isCardOpen: false,
    pin: "",
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),

    ...mapGetters({
      userMemberCard: "user/userMemberCard",
      _: "l10n/translate",
    }),
    barCodeHumanize() {
      if (this.userMemberCard) {
        return this.formattedBarCodeData(this.userMemberCard);
      } else {
        return this.formattedBarCodeData(BAR_CODE_DEFAULT);
      }
    },
    pinWithDelimiter() {
      return `${this.pin.toString().slice(0, 3)}-${this.pin.toString().slice(3, 6)}`;
    },
  },
  mounted() {
    if (this.userMemberCard) {
      drawBarCode(this.$refs.canvas, this.userMemberCard);
    } else {
      drawBarCode(this.$refs.canvas, BAR_CODE_DEFAULT);
    }

    const root = document.querySelector("#app");
    if (!root) {
      return;
    }
    root.id = "tmp-app";
  },

  async beforeMount() {
    await Promise.all([this.loadRequestsData(true), this.handlePinRefresh()]);

    if (!this.isMobile) {
      await this.resetRoute();
    }

    this.stopPageLoader();
  },

  beforeDestroy() {
    const root = document.querySelector("#tmp-app");
    root.id = "app";
  },
  methods: {
    async handlePinRefresh(event) {
      event?.preventDefault();
      event?.stopPropagation();

      this.startPageLoader();
      this.pin = await fetchPin();
      this.stopPageLoader();
    },
    handleBackClick() {
      this.resetRoute();
    },
    toggleCard() {
      this.isCardOpen = !this.isCardOpen;
    },
    formattedBarCodeData(data) {
      const chars = data.split("");
      const chunks = chunk(chars, chars.length <= 10 ? 3 : 4);
      return chunks.map((chunk) => chunk.join("")).join(" ");
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-family: $base-font-family;
}

p {
  color: $white-color;
}

.member-card-page {
  padding: 32px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-card-wrapper {
  max-width: 375px;
}

.member-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 63%;

  border-radius: 2.34% / 3.72%;
  // @link https://material-components.github.io/material-components-web-catalog/#/component/elevation
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.member-card--front {
  border-width: 1px;
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  background-image: url(~@theme/images/logo.png);
  background-color: $btn-primary-color;
  z-index: 100;
}

.member-card--back {
  // 63.00% — 100% height of the card
  // 50.40% —  80% height of the card
  margin-top: -50.4%;
  background-color: #fff;
  color: black;
  z-index: 80;
  transition: margin-top 0.3s ease-in-out;
}

.member-card--open {
  // 06.30% —  10% height of the card
  margin-top: -2.3%;
}

.member-card__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  z-index: 90;
}

.member-card__barcode {
  padding: 0 16px 0 16px;
  height: 65%;
  width: 100%;
  box-sizing: border-box;
}

.member-card__pin {
  display: flex;
  justify-content: center;
  padding: 15px 4px 4px 4px;
  width: 100%;
  font-size: $font-xm-size;

  span {
    margin-left: 8px;
    font-weight: 700;
  }
}

.member-card__refresh {
  margin-left: 12px;
  cursor: pointer;
}

.member-card__barcode canvas {
  max-width: 100%;
  max-height: 100%;
}

.member-card__human-title {
  bottom: 0;
  height: 15%;

  font-size: 18px;
}

.member-card__barcode,
.member-card__human-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.member-card-button {
  color: $text-color;
  font-family: $second-font-family;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 32px auto 0 auto;
  padding: 8px 32px;

  transition: opacity 0.25s ease-in-out;
}

.member-card-button--link {
  font-weight: 400;
  text-transform: none;
  margin-top: 8px;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  box-shadow: none;
  text-decoration: underline;
}

.member-card-button--hidden {
  opacity: 0;
}

.member-card-share-icon {
  display: inline-block;
  height: 1rem;
  fill: white;
  vertical-align: text-bottom;
}
</style>
