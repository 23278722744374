<template>
  <v-button variant="icon" class="button" @click="toggleFavourite">
    <v-icon
      v-bind="iconProps"
      :name="variant"
      :class="[
        'button__star',
        iconProps.class,
        { 'button--active-static': isActiveButton },
        { 'button--inactive': !isActiveButton },
        { 'button--active': isActiveClass && isActiveButton },
      ]"
    />
  </v-button>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons/index";
import { VIcon } from "@/components/Helpers/Icons";

export default {
  name: "StarButton",

  components: {
    VButton,
    VIcon,
  },

  inheritAttrs: false,
  props: {
    /**
     * @type {String}
     * @default "star"
     */
    variant: {
      type: String,
      default: "star",
    },
    isGameFavorite: {
      type: Boolean,
      default: false,
    },
    /**
     * @type {Object}
     * @param {{ size: number, isActive: boolean, isActiveClass: string, scaleX: boolean, scaleY: boolean, rotate: number, class: string }} type
     */
    iconProps: {
      type: Object,
      default: () => ({ size: 30 }),
    },

    onClick: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      isActiveClass: false,
    };
  },

  computed: {
    isActiveButton() {
      return this.isGameFavorite;
    },
  },

  methods: {
    toggleFavourite() {
      this.onClick();
      this.isActiveClass = !this.isGameFavorite;
    },
  },
};
</script>

<style lang="scss">
.button {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    &:hover {
      .button--inactive {
        transition: ease-in 0.3s;
        transform: scale(1.1);
      }
    }
  }

  &__star {
    transition: cubic-bezier(0.15, 0.89, 0.7, 0.77) 0.6s;
    stroke-width: 2px;
    stroke: white;
    color: transparent;
  }

  &--inactive {
    fill: rgba(32, 26, 26, 0.76);
  }

  &--active {
    animation-name: star-anim;
    animation-duration: 0.4s;
  }

  &--active-static {
    color: $heart-active-color;
    stroke-width: 1px;
  }

  @keyframes star-anim {
    0% {
      transform: translateY(0) translateX(0) scale(0.6);
    }

    25% {
      transform: translateY(0) translateX(0) scale(0.8);
    }

    50% {
      transform: translateY(0) translateX(0) scale(0.95);
    }

    60% {
      transform: translateY(0) translateX(0) scale(1.2);
    }

    75% {
      transform: translateY(0) translateX(0) scale(1.1);
    }

    80% {
      transform: translateY(0) translateX(0) scale(1.05);
    }

    100% {
      transform: scale(1);
    }
  }
}
</style>
