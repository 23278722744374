import { itemsActions, itemsGetters, itemsMutations, itemsState } from "../mixins/items";
import { errorMessageMutations, errorMessageState } from "../mixins/error-message";
import * as api from "../../api";

// Utils
import { prepareTags, sortTags, addGamesCountToTags } from "../../utils/gamester";
import { filterService } from "@/services/QueryFilterService/query-filter-service";

/** State */
const state = {
  ...errorMessageState,
  ...itemsState,
  currentItemId: filterService.getFilterParams().tag ?? null,
};

/** Actions */
const actions = {
  ...itemsActions,

  resetCurrentItemId({ commit }) {
    commit("setCurrentItemIdToDefault");
  },

  async fetch({ commit, rootState }) {
    try {
      const tags = await api.tags.fetchTags();
      const readyTags = prepareTags(sortTags(tags), rootState.games.countOfGamesBy.tags);

      commit("setRawItems", readyTags);
      commit("clearError");
    } catch (error) {
      commit("clearRawItems");
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  ...itemsGetters,

  items: (state, getters, rootState, rootGetters) => {
    return addGamesCountToTags(state.rawItems, rootGetters["games/countOf"]);
  },

  computedItems: (state, getters, rootState, rootGetters) => {
    return addGamesCountToTags(state.rawItems, rootGetters["games/computedCountOf"]);
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,
  ...itemsMutations,
  setCurrentItemId(state, itemId) {
    filterService.setParam("tag", itemId);
    state.currentItemId = itemId;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
