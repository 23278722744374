<template>
  <div :class="['games', { 'games--compact': isMobile }]">
    <v-button
      class="games__arrow games__arrow--prev"
      label="Previous"
      variant="prev"
      :disabled="!isLeftArrowEnabled"
      @click="handleLeftArrowClick"
    />
    <div ref="container" class="games__container">
      <div ref="fakeGame" class="fake-game">
        <GameCard
          :game="{
            id: 'fake-game',
            name: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quasi voluptates ipsa perferendis.',
          }"
        />
      </div>
      <div
        v-if="isVipArcadeTheme && !isMobile"
        :class="['sign-logo-footer', { 'sign-logo-footer--games': !isWheelFortuneActive }]"
      />
      <div ref="wrapper" class="games__wrapper">
        <template v-if="hasUser && isFavoriteFilter && isFavoriteCountGames < 1">
          <div class="games--favorite-empty">
            <div>
              {{ _("favorite.empty.list") }}
            </div>
            <div>
              {{ _("favorite.empty.list-1") }}
            </div>
          </div>
          <div class="games__button-back-wrapper">
            <v-button
              class="games__button-back"
              tag-name="button"
              variant="primary"
              has-texture
              type="submit"
              @click="handleGameList"
            >
              {{ _("session.button-text") }}
            </v-button>
          </div>
        </template>
        <template v-if="hasUser && isRecommendationFilter">
          <div class="games--recommendations">
            <div class="games--first-row">
              {{ _("recommendations-text-1") }}
            </div>
            <div>
              {{ _("recommendations-text-2") }}
            </div>
          </div>
        </template>
        <template v-if="isRecommendationFilter && !isMobile">
          <div class="games--recommendations-grid">
            <template v-for="game in visibleGames">
              <GameCard
                :key="game.id"
                :game="game"
                :is-demo-mode="isDemoModeActive"
                role="listitem"
                @on-game-click="handleGameClick"
              />
            </template>
          </div>
        </template>
        <template>
          <RecycleScroller
            ref="scroller"
            v-slot="{ item }"
            class="games__scroller"
            :class="{
              'games__scroller--recommendations': isRecommendationFilter && !isMobile,
            }"
            :items="chunks"
            :item-size="columnWidth"
            direction="horizontal"
          >
            <div
              class="games__column"
              :class="{ 'games__column--visible': isColumnsVisible }"
              :style="{ paddingTop: columnPaddingTop + 'px' }"
            >
              <template v-for="game in item.games">
                <GameCard
                  :key="game.id"
                  :game="game"
                  :is-demo-mode="isDemoModeActive"
                  role="listitem"
                  @on-game-click="handleGameClick"
                />
              </template>
            </div>
          </RecycleScroller>
        </template>
      </div>
    </div>

    <v-button
      class="games__arrow games__arrow--next"
      variant="next"
      label="Next"
      :disabled="!isRightArrowEnabled"
      @click="handleRightArrowClick"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { throttle, chunk, debounce } from "lodash-es";

// Mixins
import routeMixin from "@/mixins/route";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import GameCard from "@/components/Game/GameCard";
import gameFilter from "@/mixins/game-filter";
import { getTheme } from "@/utils";

const TRANSITION_DURATION = 200;
const MONTECARLO_THEME_MIN_GAMES_CONTAINER_HEIGHT = 600;
const themesStaticRows = ["montecarlo", "airclub", "viparcade", "playclub"];

export default {
  name: "Games",

  components: {
    GameCard,
    VButton,
  },

  mixins: [routeMixin, gameFilter],

  data: () => ({
    isLeftArrowEnabled: false,
    isRightArrowEnabled: true,
    columnPaddingTop: 0,
    columnWidth: 0,
    rowsNumber: 0,

    isColumnsVisible: true,
    prevCountFavoriteGames: 0,
    chunks: [],
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
      session: (state) => state.session.item,
      isFavoriteFilter: (state) => state.tags.isFavoriteFilter,
      isRecommendationFilter: (state) => state.tags.isRecommendationFilter,
    }),

    ...mapGetters({
      isDemoModeActive: "app/isDemoModeActive",
      countOfRecommendations: "games/countOfRecommendations",
      isWheelFortuneActive: "user/wheelFortuneIsActive",
      rawItems: "games/rawItems",
      _: "l10n/translate",
      hasUser: "user/hasUser",
    }),

    isFavoriteCountGames() {
      return this.rawItems.filter((game) => game.isFavorite).length;
    },

    games() {
      return this.$store.getters["games/filteredItems"]();
    },

    isVipArcadeTheme() {
      return getTheme() === "viparcade" || getTheme() === "playclub";
    },

    visibleGames() {
      const maxRecGame = 5;
      if (this.isRecommendationFilter) {
        return this.games.filter((g) => !g.isHidden).slice(0, maxRecGame);
      }

      return this.games.filter((g) => !g.isHidden);
    },
  },
  watch: {
    async visibleGames() {
      this.isColumnsVisible = false;
      this.handleResize();

      if (this.prevCountFavoriteGames === this.isFavoriteCountGames) {
        await this.delay(TRANSITION_DURATION);
      }

      this.prepareChunks();
      this.resetScroll();
      this.isColumnsVisible = true;
      await this.$nextTick();
      this.highlightArrows();
    },

    isFavoriteCountGames() {
      if (this.prevCountFavoriteGames !== this.isFavoriteCountGames) {
        this.prevCountFavoriteGames = this.isFavoriteCountGames;
      }
    },
  },

  mounted() {
    this.handleResize();

    this.prepareChunks();
    this.isColumnsVisible = true;

    this.throttledScrollHandler = throttle(this.highlightArrows, 200);
    this.debouncedResizeHandler = debounce(this.handleResize);

    window.addEventListener("resize", this.debouncedResizeHandler);

    const scroller = this.$refs["scroller"];
    scroller.$el.addEventListener("scroll", this.throttledScrollHandler);
    scroller.$el.addEventListener("wheel", this.handleWheel);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedResizeHandler);

    const scroller = this.$refs["scroller"];
    scroller.$el.removeEventListener("scroll", this.throttledScrollHandler);
    scroller.$el.removeEventListener("wheel", this.handleWheel);
  },

  methods: {
    handleGameList() {
      this.selectTag(null);
      this.selectFavorite(false);
    },

    delay(duration) {
      return new Promise((resolve) => setTimeout(resolve, duration));
    },

    prepareChunks() {
      this.chunks = chunk(this.visibleGames, this.rowsNumber).map((chunk) => {
        const id = chunk.map((game) => game.id).join("_");
        return {
          id,
          games: chunk,
        };
      });
    },

    handleResize() {
      const { height: containerHeight } = this.$refs["container"].getBoundingClientRect();
      const { width: gameWidth, height: gameHeight } =
        this.$refs["fakeGame"].getBoundingClientRect();
      let gap = 8;
      let rowsNumber = Math.floor((containerHeight - gap) / (gameHeight + gap));

      //TODO костыль для montecarlo темы
      if (!this.isMobile && themesStaticRows.includes(getTheme())) {
        if (containerHeight < MONTECARLO_THEME_MIN_GAMES_CONTAINER_HEIGHT) {
          rowsNumber = 2;
        } else {
          rowsNumber = 3;
        }
      }

      //TODO костыль для списка рекомендованных
      if (!this.isMobile && this.isRecommendationFilter) {
        rowsNumber = 1;
      }

      this.columnWidth = gameWidth + gap * 2;
      this.columnPaddingTop =
        (containerHeight - gameHeight * rowsNumber - gap * (rowsNumber - 1)) / 2;
      if (rowsNumber !== this.rowsNumber) {
        this.rowsNumber = rowsNumber;
        this.prepareChunks();
      }
    },

    highlightArrows() {
      const scroller = this.$refs["scroller"];
      const element = scroller.$el;
      const { scrollLeft, scrollWidth, clientWidth } = element;
      this.isLeftArrowEnabled = scrollLeft > 0;
      this.isRightArrowEnabled = scrollLeft + clientWidth < scrollWidth;
    },

    /**
     * @param {WheelEvent} event
     */
    handleWheel(event) {
      const { deltaX, deltaY } = event;
      if (deltaX) {
        return;
      }
      const scroller = this.$refs["scroller"];
      const element = scroller.$el;
      element.scrollBy({
        left: deltaY,
        behavior: "instant",
      });
    },

    handleLeftArrowClick() {
      this.smoothScroll("left");
    },

    handleRightArrowClick() {
      this.smoothScroll("right");
    },

    resetScroll() {
      const scroller = this.$refs["scroller"];
      const element = scroller.$el;
      element.scroll({
        left: 0,
        behavior: "instant",
      });
    },

    /**
     * @param {"left" | "right"} direction
     */
    smoothScroll(direction) {
      const scroller = this.$refs["scroller"];
      const element = scroller.$el;
      let by = element.clientWidth / 2;
      if (direction === "left") {
        by = -by;
      }
      element.scrollBy({
        left: by,
        behavior: "smooth",
      });
    },

    async handleGameClick(event, { gameId, isDemoMode }) {
      this.openGameById(gameId, isDemoMode);
    },
  },
};
</script>

<style lang="scss" scoped>
.games {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  &__button-back {
    margin-top: auto;
    margin-bottom: 50px;
    color: $text-color;
    font-family: $second-font-family;
    font-size: $font-sm-size;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 190px;
    height: 40px;

    @media screen and (max-width: 720px) {
      width: 240px;
      padding: 20px;
    }
  }

  &__button-back-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &--favorite-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85%;
    margin: 0 10%;
    color: #f1f1f1;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    padding: 0 2em;
    text-align: center;
  }

  &--first-row {
    font-size: 22px;
    font-weight: bold;
  }

  &--recommendations {
    color: #f1f1f1;
    font-size: 16px;
    margin-top: 100px;
    margin-bottom: -125px;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    padding: 0 2em;
    text-align: center;

    @media (max-width: 1025px) {
      margin-top: 6px;
      padding: 0 8px;
      font-size: 12px;
      margin-bottom: -8px;
    }
  }

  &--recommendations-grid {
    @media (min-width: 1025px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .game {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 1024px) {
    height: calc(100% - 64px);
    margin: 64px 0 0 0;

    mask-image: linear-gradient(
      to right,
      fade-out($bg-black-color, 0.75) 0%,
      $bg-black-color 25%,
      $bg-black-color 75%,
      fade-out($bg-black-color, 0.75) 100%
    );
  }

  @media (min-width: 1025px) {
    height: 100%;
    padding: 0 80px;

    &:before {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      display: block;
      background-image: linear-gradient(
        to right,
        $null-color 0,
        $bg-white-color 50%,
        $null-color 100%
      );
      position: absolute;
    }
  }

  &__arrow {
    $width: 97px;
    $height: 95px;

    top: 50%;
    width: $width;
    height: $height;
    position: absolute;
    margin-top: (-$height / 2);

    &--prev {
      left: 40px;
    }

    &--next {
      right: 40px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;

    @media (min-width: 1025px) {
      background-image: radial-gradient(
          50% 50% ellipse at top,
          fade-out($bg-dark-color, 0.5),
          $null-color 100%
        ),
        radial-gradient(50% 50% ellipse at bottom, fade-out($bg-dark-color, 0.5), $null-color 100%);

      mask-image: linear-gradient(
        to right,
        $null-color 0,
        $null-color 40px,
        $bg-black-color 100px,
        $bg-black-color calc(100% - 100px),
        $null-color calc(100% - 40px),
        $null-color 100%
      );
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__scroller {
    padding: 0 60px;
    width: 100%;
    height: 100%;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    box-sizing: border-box;
    position: absolute;

    &--recommendations {
      display: none;
    }

    //  left: 50%;
    //
    //  @media (max-width: 1800px) {
    //    transform: translateX(-25%);
    //  }
    //
    //  @media (max-width: 1450px) {
    //    transform: translateX(-35%);
    //  }
    //
    //  @media (max-width: 1340px) {
    //    transform: translateX(-36%);
    //  }
    //
    //  @media (max-width: 1250px) {
    //    transform: translateX(-40%);
    //  }
    //
    //  @media (max-width: 1166px) {
    //    transform: translateX(-45%);
    //  }
    //
    //  @media (max-width: 1025px) {
    //    left: inherit;
    //    transform: inherit;
    //  }
    //}
  }

  &__scroller::-webkit-scrollbar {
    height: 4px;

    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, transparent),
      color-stop(50%, #fff),
      to(transparent)
    );
    background-image: linear-gradient(90deg, transparent 0, #fff 50%, transparent);
  }

  &__scroller::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &__scroller::-webkit-scrollbar-thumb {
    background-color: $bg-main-color;
  }

  .games__column {
    padding: 0 8px;
    display: flex;
    flex-direction: column;

    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    will-change: opacity;
  }

  .games__column--visible {
    opacity: 1;
  }

  .games__column .game + .game {
    margin-top: 8px;
  }

  .fake-game {
    position: absolute;
    visibility: hidden;
  }
}
</style>
