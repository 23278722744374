export async function fetchLocalizations(locale = "en") {
  const response = await fetch(
    `${window.TEMPLATE_PATH}/static/i18n/${locale}.json?v=${process.env.VUE_APP_VERSION}`
  );
  return await response.json();
}

/**
 *    `${window.TEMPLATE_PATH}/static/l10n.csv?v=${process.env.VUE_APP_VERSION}`
 */
