<template>
  <BaseModal
    v-if="isOpenModal && isAuth"
    :title="_('bet_history_title')"
    class="modal"
    :content="{}"
  >
    <template #body class="bets-history">
      <svg
        width="30"
        height="30"
        class="close"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        viewBox="0,0,256,256"
        y="0px"
        @click="onClose"
      >
        <g
          fill="#ffffff"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <g transform="scale(5.12,5.12)">
            <path
              d="M9.15625,6.3125l-2.84375,2.84375l15.84375,15.84375l-15.9375,15.96875l2.8125,2.8125l15.96875,-15.9375l15.9375,15.9375l2.84375,-2.84375l-15.9375,-15.9375l15.84375,-15.84375l-2.84375,-2.84375l-15.84375,15.84375z"
            ></path>
          </g>
        </g>
      </svg>
      <vue-tabs
        class="w-full mt-20 flex flex-col max-h-full h-full min-h-0 margin-16"
        text-position="center"
        centered
      >
        <v-tab :title="_('In_Game')" class="list">
          <SportBetsHistoryList :status="'created'" />
        </v-tab>
        <v-tab :title="_('Completed')" class="list">
          <SportBetsHistoryList :status="'non-created'" />
        </v-tab>
      </vue-tabs>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex";
import BaseModal from "@/components/Modal/BaseModal.vue";
import { VTab, VueTabs } from "vue-nav-tabs/dist/vue-tabs.js";
import "vue-nav-tabs/dist/vue-tabs.min.css";
import SportBetsHistoryList from "@/modules/SportBetHistory/components/SportBetHistoryList.vue";

export default {
  name: "SportBetsHistoryModal",
  components: {
    SportBetsHistoryList,
    BaseModal,
    VTab,
    VueTabs,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      isOpenModal: this.isOpen,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/hasUser",
      _: "l10n/translate",
    }),
    isAuth() {
      return this.user;
    },
  },
  watch: {
    isOpen(newVal) {
      this.isOpenModal = newVal;
    },
  },
  methods: {
    onCloseModal() {
      this.isOpenModal = false;
      if (this.onClose) {
        this.onClose();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}
:deep .modal__content {
  background: none;
  overflow-y: hidden;
  padding: 5px 0;
  border: none;
  resize: none;
  min-width: 420px;
  color: white;
  font-size: $font-sm-size;
  font-family: $base-font-family, $fallback-font-family;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 760px) {
    min-width: auto;
    text-align: left;
  }
}
:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}
:deep .modal__window {
  max-width: 700px;
  max-height: 800px;
  padding: 40px;
  overflow-y: hidden;
  position: relative;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}
:deep .modal__header {
  font-style: italic;
  font-size: 40px;
  margin-top: -22px;
  margin-left: -24px;

  @media screen and (max-width: 1024px) {
    margin: 4px 8px;
    display: flex;
    justify-content: center;
    font-size: 28px;
  }
}

:deep .modal__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.mt-20 {
  margin-top: 40px;

  @media screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 10px !important;
  }
}

.w-full {
  width: 100%;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 25px;

  @media screen and (max-width: 768px) {
    right: 2px;
  }
}

.list {
  position: relative;
  padding-top: 20px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-color: fade-out($bg-main-color, 0.2) white;
  scrollbar-width: thin;

  .margin-16 {
    padding: 0 16px;
  }
}
</style>
