<template>
  <div>
    <div v-if="noWagerGames" class="modal-info__title modal-info__title-mt">
      <div class="modal-info__bounceback-title">
        {{ _("no_wagers_game_title") }}
      </div>
      <div
        v-for="provider in Object.keys(noWagerGames)"
        :key="provider"
        class="modal-info__game-provider"
      >
        <div class="modal-info__game-provider-title">{{ provider }}:</div>
        <span
          v-for="(game, index) in noWagerGames[provider]"
          :key="game.string_id"
          class="modal-info__game-provider-text"
        >
          {{ game.name }}<span v-if="index < noWagerGames[provider].length - 1">,</span>
        </span>
      </div>
    </div>

    <div v-if="noJpGames" class="modal-info__title modal-info__title-mt">
      <div class="modal-info__bounceback-title">
        {{ _("no_jackpots_game_title") }}
      </div>
      <div
        v-for="provider in Object.keys(noJpGames)"
        :key="provider"
        class="modal-info__game-provider"
      >
        <div class="modal-info__game-provider-title">{{ provider }}:</div>
        <span
          v-for="(game, index) in noJpGames[provider]"
          :key="game.string_id"
          class="modal-info__game-provider-text"
        >
          {{ game.name }}<span v-if="index < noJpGames[provider].length - 1">,</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchNoJackpotGames, fetchNoWagerGames } from "@/api/games";

export default {
  name: "NoJpWagerSection",
  data() {
    return {
      noJpGames: null,
      noWagerGames: null,
    };
  },
  computed: {
    ...mapGetters({
      _: "l10n/translate",
    }),
  },
  mounted() {
    Promise.all([this.fetchUnJackpots(), this.fetchUnwagered()]);
  },
  methods: {
    async fetchUnJackpots() {
      const res = await fetchNoJackpotGames();
      const list = res.list || [];
      this.noJpGames = this.filteredByProviders(list);
    },

    async fetchUnwagered() {
      const res = await fetchNoWagerGames();
      const list = res.list || [];
      this.noWagerGames = this.filteredByProviders(list);
    },
    filteredByProviders(games) {
      return games.reduce((acc, game) => {
        if (!acc[game.sectionName]) {
          acc[game.sectionName] = [];
        }
        acc[game.sectionName].push(game);
        return acc;
      }, {});
    },
  },
};
</script>

<style scoped>
.modal-info__title-mt {
  margin-top: 40px;
}

.modal-info__title {
  font-weight: bold;
  font-size: 18px;
}

.modal-info__game-provider {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.modal-info__game-provider-title {
  font-weight: bold;
  margin-right: 4px;
  font-size: 14px;
}

.modal-info__game-provider-text {
  display: inline;
  font-weight: normal;
  margin-right: 4px;
  font-size: 14px;
}
</style>
