import { has } from "lodash-es";

// Helpers
const routeMap = {
  root: {
    name: "home",
    path: "/",
  },
  login: {
    name: "sign-in",
    path: "/sign-in",
  },
  game: {
    name: "game",
    path: (id) => `/games/${id}`,
  },
  jackpots: {
    name: "jackpots",
    path: (streamId) => `/jackpots/${streamId}`,
  },
  fortune: {
    name: "wheel-fortune",
    path: "/wheel-fortune",
  },
};
export const ROOT_PATH = routeMap.root.path;
const GAMES_HASH = "games";

export default {
  computed: {
    isHomePage() {
      return this.$route.path === ROOT_PATH;
    },
  },

  watch: {
    $route(to, from, next) {
      return { to, from, next };
    },
  },

  methods: {
    /**
     * @param {(string, string)} gameId
     * @param {boolean} demo
     * @description Open game by ID.
     */
    openGameById(gameId, demo = false) {
      if (gameId && this.$route.path !== routeMap.game.path(gameId)) {
        return this.$router.push({
          name: routeMap.game.name,
          params: { id: gameId },
          query: demo ? { demo } : {},
        });
      } else {
        return false;
      }
    },

    /**
     * @description Replace router to home page
     */
    resetRoute() {
      return this.$router.push({ path: ROOT_PATH });
    },

    /**
     * @param {(string, Object)} location
     * @description Replace router
     */
    redirectRoute(location) {
      return this.$router.replace(location);
    },

    /**
     * @description Check $route.query & redirect
     */
    checkQueryAndRedirect() {
      if (this.hasRouteQuery("redirect")) {
        this.redirectRoute(this.$route.query.redirect);
      } else {
        this.resetRoute();
      }
    },

    /**
     * @param {string} query
     * @description Check $route.query
     */
    hasRouteQuery(query) {
      return has(this.$route.query, query);
    },

    /**
     * @param {(string, undefined)} hash
     * @description Push router to home page
     */
    goHome(hash = "") {
      return this.$router.push({ path: ROOT_PATH, hash });
    },

    /**
     * @param {boolean} isScrollToGames
     * @description Push router to home page
     */
    goToGames(isScrollToGames) {
      this.goHome(isScrollToGames ? GAMES_HASH : undefined);
    },

    /**
     * @description Push router to sing-in page
     */
    goToLogin() {
      return this.$router.push({ path: routeMap.login.path });
    },

    goToFortune() {
      return this.$router.push({ path: routeMap.fortune.path });
    },

    /**
     * @description Push router to home page
     */
    scrollToGames() {
      this.setHash(GAMES_HASH);
    },

    /**
     * @param {string} hash
     * @description Set location.hash
     */
    setHash(hash) {
      return (window.location.hash = hash);
    },

    getHash() {
      return window.location.hash;
    },

    /**
     * @description Reload page
     */
    goToLoginPage() {
      window.location = ROOT_PATH;
    },
  },
};
