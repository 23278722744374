/** State */
export const errorMessageState = {
  error: null,
};

/** Mutations */
export const errorMessageMutations = {
  setError(state, error) {
    state.error = error;
  },

  clearError(state) {
    state.error = null;
  },
};
