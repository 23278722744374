import { mapState, mapGetters, mapActions } from "vuex";

// API
import { fetchJackpotsStreamId } from "@/api/lobby";
import { getLangFromStorage } from "@/api/language";

export default {
  computed: {
    ...mapState({
      tags: (state) => state.tags.rawItems,
      games: (state) => state.games.rawItems,
      brands: (state) => state.brands.rawItems,
      providers: (state) => state.providers.rawItems,

      l10n: (state) => state.l10n.items,
      language: (state) => state.l10n.current,
      apiLanguages: (state) => state.l10n.apiLanguages,
      lobbyParams: (state) => state.lobby.params,

      isConnectedToJackpotsStream: (state) => state.jackpots.isConnected,
    }),

    ...mapGetters({
      locales: "l10n/allowLocalesFromApi",
      hasUser: "user/hasUser",
      isDemoModeActive: "app/isDemoModeActive",
    }),
  },

  methods: {
    ...mapActions({
      createGameSession: "session/create",
      connectToJackpotsWebSockets: "jackpots/connect",
    }),

    /**
     * @description Connect to the jackpot stream (WebSocket).
     */
    async connectToWebSocket() {
      if (!this.isConnectedToJackpotsStream && this.hasUser) {
        const streamId = await fetchJackpotsStreamId();
        await this.connectToJackpotsWebSockets(streamId);
      }
    },

    /**
     * @param {boolean} isLoadUserData
     * @param {{game: string, demo: boolean } | null} session
     * @description Load data of all requests.
     */
    async loadRequestsData(isLoadUserData = false, session = null) {
      const promises = [];
      const currentLang = getLangFromStorage();

      if (isLoadUserData) {
        // User
        await this.$store.dispatch("user/fetch");

        // Bonus
        if (!this.isDemoModeActive) {
          await this.$store.dispatch("bonus/fetch");
        }
      }

      // Create session
      if (session && session.game) {
        promises.push(this.createGameSession(session));
      }

      if (this.l10n === null || this.apiLanguages === null) {
        await this.$store.dispatch("l10n/fetchLanguages");
        await this.$store.dispatch("l10n/fetch", {
          lang: currentLang,
        });
      }

      // Current language
      if (this.language === null) {
        const apiLang = this.apiLanguages?.defaultLang;

        if (!currentLang && apiLang) {
          this.$store.commit("l10n/setCurrent", apiLang);
        } else if (currentLang && apiLang && !this.locales.includes(currentLang)) {
          this.$store.commit("l10n/setCurrent", apiLang);
        } else if (apiLang && !this.locales.includes(currentLang)) {
          this.$store.commit("l10n/setCurrent", apiLang);
        } else {
          if (!this.locales.length) {
            this.$store.commit("l10n/setCurrent", null);
          } else {
            this.$store.commit("l10n/setCurrent", currentLang);
          }
        }
      }

      // Games
      if (this.games === null) {
        await this.$store.dispatch("games/fetch");
      }

      // Providers
      if (this.providers === null) {
        promises.push(this.$store.dispatch("providers/fetch"));
      }

      // Brands
      if (this.brands === null) {
        promises.push(this.$store.dispatch("brands/fetch"));
      }

      // Tags
      if (this.tags === null) {
        promises.push(this.$store.dispatch("tags/fetch"));
      }

      // Params
      if (this.lobbyParams === null) {
        promises.push(this.$store.dispatch("lobby/fetchParams"));
      }

      // Wait...
      await Promise.allSettled(promises);

      // Connect to WebSocket
      if (!this.isDemoModeActive) {
        this.connectToWebSocket();
      }
    },

    /**
     * @description Stop page loader.
     */
    stopPageLoader() {
      this.$store.commit("app/setIsLoading", false);
    },
    startPageLoader() {
      this.$store.commit("app/setIsLoading", true);
    },
  },
};
