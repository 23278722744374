var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'keyboard',
    {
      'keyboard--alphabet': _vm.isAlphabet,
    },
  ]},[_c('div',{staticClass:"keyboard__wrapper"},[_vm._l((_vm.keyboardChars),function(key){return [(
          key !== _vm.CAPS_CHAR &&
          key !== _vm.ENTER_CHAR &&
          key !== _vm.CHANGE_TO_ALPHABETIC_CHAR &&
          key !== _vm.CHANGE_TO_NUMERIC_CHAR
        )?_c('v-button',{key:'key#' + key,staticClass:"keyboard__key",attrs:{"variant":"icon"},on:{"click":function($event){return _vm.$emit('on-press', key)}}},[_vm._v(" "+_vm._s(key)+" ")]):_vm._e(),(key === (_vm.CAPS_CHAR || _vm.BACKSPACE_CHAR))?_c('v-button',{key:'key#' + key,class:[
          'keyboard__key',
          {
            'keyboard__key--shift': _vm.isShift,
          },
        ],attrs:{"variant":"tag","has-texture":""},on:{"click":_vm.toggleCapsLock}},[_vm._v(" ⇪ ")]):_vm._e(),(key === _vm.CHANGE_TO_NUMERIC_CHAR || key === _vm.CHANGE_TO_ALPHABETIC_CHAR)?_c('v-button',{key:'key#' + key,staticClass:"keyboard__key",attrs:{"has-texture":"","variant":"tag"},on:{"click":_vm.toggleKeyboardType}},[_vm._v(" "+_vm._s(_vm.humanizeKeyboardType.toUpperCase())+" ")]):_vm._e(),(key === _vm.ENTER_CHAR)?_c('v-button',{key:'key#' + key,class:[
          'keyboard__key keyboard__key--enter',
          {
            'keyboard__key--alphabet': _vm.isAlphabet,
          },
        ],attrs:{"tag-name":"button","variant":"icon","type":"submit"},on:{"click":function($event){return _vm.$emit('on-submit')}}},[_vm._v(" ⏎ ")]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }