<template>
  <div
    :class="[
      'keyboard',
      {
        'keyboard--alphabet': isAlphabet,
      },
    ]"
  >
    <div class="keyboard__wrapper">
      <template v-for="key in keyboardChars">
        <v-button
          v-if="
            key !== CAPS_CHAR &&
            key !== ENTER_CHAR &&
            key !== CHANGE_TO_ALPHABETIC_CHAR &&
            key !== CHANGE_TO_NUMERIC_CHAR
          "
          :key="'key#' + key"
          class="keyboard__key"
          variant="icon"
          @click="$emit('on-press', key)"
        >
          {{ key }}
        </v-button>
        <v-button
          v-if="key === (CAPS_CHAR || BACKSPACE_CHAR)"
          :key="'key#' + key"
          variant="tag"
          has-texture
          :class="[
            'keyboard__key',
            {
              'keyboard__key--shift': isShift,
            },
          ]"
          @click="toggleCapsLock"
        >
          &#x21EA;
        </v-button>
        <v-button
          v-if="key === CHANGE_TO_NUMERIC_CHAR || key === CHANGE_TO_ALPHABETIC_CHAR"
          :key="'key#' + key"
          has-texture
          class="keyboard__key"
          variant="tag"
          @click="toggleKeyboardType"
        >
          {{ humanizeKeyboardType.toUpperCase() }}
        </v-button>
        <v-button
          v-if="key === ENTER_CHAR"
          :key="'key#' + key"
          :class="[
            'keyboard__key keyboard__key--enter',
            {
              'keyboard__key--alphabet': isAlphabet,
            },
          ]"
          tag-name="button"
          variant="icon"
          type="submit"
          @click="$emit('on-submit')"
        >
          ⏎
        </v-button>
      </template>
    </div>
  </div>
</template>

<script>
// Components
import { VButton } from "@/components/Helpers/Buttons";

export const BACKSPACE_CHAR = "<";
const ENTER_CHAR = "ENTER";
const CHANGE_TO_NUMERIC_CHAR = "123";
const CHANGE_TO_ALPHABETIC_CHAR = "ABC";
const CAPS_CHAR = "⇪";

export default {
  name: "VirtualKeyboard",

  components: {
    VButton,
  },

  data() {
    return {
      virtualNumericKeys: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "-",
        "0",
        BACKSPACE_CHAR,
        CHANGE_TO_ALPHABETIC_CHAR,
        ENTER_CHAR,
      ],
      virtualAlphabetKeys: [
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        BACKSPACE_CHAR,
        "A",
        "S",
        "D",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        ENTER_CHAR,
        CAPS_CHAR,
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        "_",
        "-",
        CHANGE_TO_NUMERIC_CHAR,
      ],
      isAlphabet: false,
      isShift: false,
      CAPS_CHAR,
      BACKSPACE_CHAR,
      ENTER_CHAR,
      CHANGE_TO_NUMERIC_CHAR,
      CHANGE_TO_ALPHABETIC_CHAR,
    };
  },

  computed: {
    keyboardChars() {
      if (this.isAlphabet) {
        if (!this.isShift) {
          return this.virtualAlphabetKeys.map((char) => {
            if (char !== ENTER_CHAR) {
              return char.toLowerCase();
            } else {
              return char;
            }
          });
        }

        return this.virtualAlphabetKeys;
      }

      return this.virtualNumericKeys;
    },

    humanizeKeyboardType() {
      if (this.isAlphabet) {
        return CHANGE_TO_NUMERIC_CHAR;
      }

      return CHANGE_TO_ALPHABETIC_CHAR;
    },
  },

  methods: {
    toggleKeyboardType() {
      this.isAlphabet = !this.isAlphabet;
    },

    toggleCapsLock() {
      this.isShift = !this.isShift;
    },
  },
};
</script>

<style lang="scss" scoped>
.keyboard {
  display: flex;
  perspective: 1000px;
  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1025px) {
    width: 298px;
    height: 510px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &__key {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 88px;
    height: 88px;
    background-image: url($path + "key-desktop.png");
    background-repeat: no-repeat;
    background-size: contain;

    font-family: $second-font-family;
    font-weight: bold;
    font-size: 44px;
    color: $text-color;

    user-select: none;
    cursor: pointer;

    & + & {
      margin-left: 16px;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-last-child(1) {
      margin-left: 0;
    }

    &--enter {
      width: 194px;
      background-image: url($path + "enter-key-desktop.png");
      background-color: transparent;

      &:nth-last-child(1) {
        margin-left: 16px;
      }
    }

    &--shift {
      color: $text-main-color;
    }

    &--upperCase {
      text-transform: uppercase;
    }
  }

  &--alphabet {
    width: 1144px;
    margin-left: -16px;

    .keyboard__wrapper {
      transform: rotateY(180deg) scaleX(-1);
      margin: auto 0;
    }

    .keyboard__key {
      &:nth-child(n) {
        margin-left: 16px;
      }

      &:nth-child(21) {
        margin-left: 14px;
      }

      &:nth-last-child(1) {
        margin-left: 14px;
      }
    }
  }
}
</style>
