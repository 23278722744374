import { itemsActions, itemsGetters, itemsMutations, itemsState } from "../mixins/items";
import { errorMessageMutations, errorMessageState } from "../mixins/error-message";

// API
import * as api from "../../api";
import { filterService } from "@/services/QueryFilterService/query-filter-service";

/** State */
const state = {
  ...errorMessageState,
  ...itemsState,
  currentItemId: filterService.getFilterParams().brand ?? null,
};

/** Actions */
const actions = {
  ...itemsActions,

  async fetch({ commit }) {
    try {
      const brands = await api.brands.fetchBrands();

      commit("setRawItems", brands);
      commit("clearError");
    } catch (error) {
      commit("clearRawItems");
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  ...itemsGetters,

  computedItems: (state, getters, rootState, rootGetters) => {
    const countOf = rootGetters["games/countOf"];

    return state.rawItems
      ? state.rawItems.map(({ id, name }) => ({
          id,
          name,
          gamesCount: countOf ? countOf.brands[id] || 0 : 0,
        }))
      : [];
  },

  isBrandsShown: (state, getters) => {
    return getters.computedItems.filter((b) => b.gamesCount > 0).length >= 1;
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,
  ...itemsMutations,
  setCurrentItemId(state, itemId) {
    filterService.setParam("brand", itemId);
    filterService.setParam("tag", null);
    state.currentItemId = itemId;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
