import { get } from "lodash-es";
import camelize from "camelize";
import { userWatchActivityService } from "@/services/UserWatchActivityService/UserWatchActivityService";
import { ROOT_PATH } from "@/mixins/route";

// Helpers
const URL = "/actionHandler/request";

export async function call(method, parameters) {
  try {
    const response = await fetch(URL, {
      method: "POST",
      body: JSON.stringify({ calls: [{ method, args: parameters || {} }] }),
    });
    const body = await response.json();
    const result = camelize(body.results[0]);

    if (!result.success) {
      const errorMessage = get(result, "exception.message");

      if (method === "Auth.user" && errorMessage && !userWatchActivityService.isUserActive) {
        window.location = ROOT_PATH;
      }
      // eslint-disable-next-line no-console
      console.error(errorMessage ?? "Unknown error");
    }

    return result.response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}
