<template>
  <div v-if="isSweepstakesExchangeEnabled && isSweepstakesEnabled">
    <v-button
      v-if="isDefaultDesktopButton"
      variant="primary"
      class="buy-time-button--default"
      @click="handleClick"
    >
      <div class="buy-time-button buy-time-button--home">
        <VIcon name="clock" />
        <span> Buy time</span>
      </div>
    </v-button>

    <div v-else-if="isMobileButton" class="buy-time-button--mobile" @click="handleClick">
      Buy Time
    </div>

    <div v-else class="buy-time-button" @click="handleClick">
      <VIcon name="clock" />
      <span> Buy time </span>
    </div>
  </div>
</template>

<script>
import VIcon from "@/components/Helpers/Icons/VIcon.vue";
import { mapMutations, mapState } from "vuex";
import { get } from "lodash-es";
import { VButton } from "@/components/Helpers/Buttons/index";

export default {
  name: "BuyTimeButton",
  components: { VButton, VIcon },
  props: {
    isDefaultDesktopButton: {
      type: Boolean,
      default: false,
    },
    isMobileButton: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    ...mapState({
      isSweepstakesEnabled: (state) => get(state, "lobby.params.sweepstakes", false),
      isSweepstakesExchangeEnabled: (state) => {
        return get(state, "lobby.params.lobbyButtons.ssUpdateBalance.enabled", false);
      },
    }),
  },

  methods: {
    ...mapMutations({
      handleBuyTimeModalOpen: "ui/showBuyTimeModal",
    }),
    handleClick(event) {
      event.preventDefault();
      event.stopPropagation();
      this.onClick();
      this.handleBuyTimeModalOpen();
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-time-button {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  cursor: pointer;

  &--mobile {
    width: 100%;
    display: flex;
    align-items: center;
    color: fade-out($text-color, 0.5);
    font-family: $base-font-family;
    text-transform: uppercase;
    box-sizing: border-box;
    position: relative;
    padding: 0 32px;
    cursor: pointer;

    @media (orientation: portrait) {
      font-size: $font-xm-size;
      height: 48px;
    }

    @media (orientation: landscape) {
      font-size: $font-md-size;
      height: 32px;
    }

    &--active {
      color: $text-color;
    }
  }

  &--home {
    span {
      font-weight: 500;
      margin-left: 0;
      padding-right: 2px;
    }
  }

  &--default {
    height: 30px;
    font-size: $font-sm-size;
    text-transform: uppercase;
    margin-left: 2px;
  }

  svg {
    width: 28px;
    margin-top: 8px;
  }

  span {
    margin-left: 2px;
    color: $white-color;
    font-family: $base-font-family;
  }
}
</style>
