import { errorMessageMutations, errorMessageState } from "../mixins/error-message";
import * as api from "../../api";

import { isGameHidden, analyzeGames, calcGames } from "../../utils/gamester";
import { itemsActions, itemsGetters, itemsState } from "@/store/mixins/items";

const state = {
  ...errorMessageState,
  ...itemsState,

  rawItems: null,
  countOfGamesBy: {},
  recommendedGamesIds: [],
  tags: [],
};

/** Actions */
const actions = {
  ...itemsActions,
  async fetch({ commit }) {
    try {
      const games = await api.games.fetchGames();
      const recommendedGames = await api.games.fetchGamesIds();
      commit("setRawItems", games);
      commit("setRecommendedItemsId", recommendedGames);
      commit("clearError");
    } catch (error) {
      commit("setRawItems", null);
      commit("setError", error.message);
    }
  },
};

/** Getters */
const getters = {
  ...itemsGetters,
  rawItems(state) {
    if (state.rawItems !== null) {
      return state.rawItems;
    }
    return [];
  },

  recommendedGamesIds(state) {
    return state.recommendedGamesIds;
  },

  items(state, getters) {
    if (state.rawItems === null) {
      return null;
    }

    return getters.filteredItems(undefined, null, null, null).filter((game) => !game.isHidden);
  },

  filteredItems:
    (state, getters, rootState, rootGetters) =>
    (
      currentProviderId = rootState.providers.currentItemId,
      currentBrandId = rootState.brands.currentItemId,
      currentTagId = rootState.tags.currentItemId,
      isFavoriteFilter = rootState.tags.isFavoriteFilter,
      isRecommendationFilter = rootState.tags.isRecommendationFilter,
      searchQuery = rootGetters["search/currentQuery"]
    ) => {
      return state.rawItems.map((game) => ({
        ...game,
        isHidden: isGameHidden(
          game,
          rootState.app.isMobile,
          currentProviderId,
          currentBrandId,
          currentTagId,
          searchQuery,
          isFavoriteFilter,
          isRecommendationFilter,
          state.recommendedGamesIds
        ),
      }));
    },
  countOf(state, getters) {
    return calcGames(getters.items);
  },

  countOfRecommendations(state) {
    return state.rawItems?.filter((game) => state.recommendedGamesIds.includes(game.originalId))
      .length;
  },

  computedCountOf(state, getters) {
    return calcGames(
      getters.filteredItems(undefined, undefined, null).filter((game) => !game.isHidden)
    );
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,

  setRawItems(state, rawItems) {
    const data = analyzeGames(rawItems);

    state.countOfGamesBy = data.countOfGamesBy;
    state.rawItems = data.games;
  },

  setRecommendedItemsId(state, recommendedGamesIds) {
    state.recommendedGamesIds = recommendedGamesIds;
  },

  updateFavoriteItem(state, { originalId, isFavorite }) {
    const game = state.rawItems.find((game) => game.originalId === originalId);

    if (game) {
      game.isFavorite = isFavorite;
    }
  },

  clearRawItems(state) {
    state.rawItems = null;
    state.countOfGames = 0;
    state.firstProvider = null;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
