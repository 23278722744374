<template>
  <v-button
    :disabled="filteredItemsCode?.length <= 1 ?? false"
    variant="primary"
    :class="['button', classNames]"
    @click="nextLanguage"
  >
    {{ language }}
  </v-button>
</template>
<script lang="js">
import { VButton } from "@/components/Helpers/Buttons/index";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SwitchLanguageButton",
  components: {
    VButton,
  },
  props: {
    classNames: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState({
      language: (state) => state.l10n?.current?.toUpperCase() ?? "en",
    }),

    ...mapGetters({
      filteredItemsCode: "l10n/allowLocalesFromApi",
      _: "l10n/translate",
    }),
  },

  methods: {
    ...mapActions({
      nextLanguage: "l10n/nextLanguage",
    }),
  },
};
</script>
<style lang="scss" scoped>
.button {
  text-transform: uppercase;
  padding: 8px 16px;
  background-size: contain;
}
</style>
