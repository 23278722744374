<template>
  <div
    :class="['menu', { 'menu--showed': isOpenMenu }, { 'menu--left': isTheLeft }]"
    @click="handleOverlayClick"
    @touchstart.stop
    @touchmove.stop
    @touchend.stop
  >
    <div class="menu__content">
      <div class="menu__list">
        <template v-for="provider in providers">
          <div :key="provider.id" class="menu__wrapper">
            <div
              v-if="provider.gamesCount > 0"
              :class="[
                'menu__item',
                {
                  'menu__item--active':
                    provider.id === currentProviderId &&
                    !isFavoriteFilter &&
                    !isRecommendationFilter,
                },
              ]"
              @click="handleProviderClick(provider)"
            >
              {{ _(provider.name) }}
            </div>

            <div
              v-if="
                provider.id === currentProviderId && tags.filter((i) => i.gamesCount > 0).length
              "
              class="menu__list"
            >
              <template v-for="tag in tags">
                <template v-if="tag.name === TAG_AVIATOR">
                  <div
                    v-if="tag.gamesCount > 0"
                    :key="tag.name"
                    :class="[
                      'menu__item',
                      {
                        'menu__item--active tag--img-tag-active':
                          tag.lowerCaseName === currentTagId,
                      },
                    ]"
                    @click="handleTagClick(tag)"
                  >
                    <div class="tag--aviator">
                      <VIcon name="aviator" />
                    </div>
                  </div>
                </template>
                <template v-if="tag.name === TAG_CHRISTMAS">
                  <div
                    v-if="tag.gamesCount > 0"
                    :key="tag.name"
                    :class="[
                      'menu__item',
                      {
                        'menu__item--active tag--img-tag-active':
                          tag.lowerCaseName === currentTagId,
                      },
                    ]"
                    @click="handleTagClick(tag)"
                  >
                    <div class="tag--christmas">
                      <VIcon name="christmas" />
                    </div>
                  </div>
                </template>
                <template v-if="tag.name === TAG_CHINESE">
                  <div
                    v-if="tag.gamesCount > 0"
                    :key="tag.name"
                    :class="[
                      'menu__item',
                      {
                        'menu__item--active tag--img-tag-active':
                          tag.lowerCaseName === currentTagId,
                      },
                    ]"
                    @click="handleTagClick(tag)"
                  >
                    <div class="tag--chinese">
                      <VIcon name="chinese-tag" />
                    </div>
                  </div>
                </template>

                <template v-if="tag.name === TAG_HALLOWEEN">
                  <div
                    v-if="tag.gamesCount > 0"
                    :key="tag.name"
                    :class="[
                      'menu__item',
                      {
                        'menu__item--active tag--img-tag-active':
                          tag.lowerCaseName === currentTagId,
                      },
                    ]"
                    @click="handleTagClick(tag)"
                  >
                    <div class="tag--halloween">
                      <VIcon name="halloween" />
                    </div>
                  </div>
                </template>
                <template
                  v-if="
                    tag.name !== TAG_AVIATOR &&
                    tag.name !== TAG_HALLOWEEN &&
                    tag.name !== TAG_CHRISTMAS &&
                    tag.name !== TAG_CHINESE
                  "
                >
                  <div
                    v-if="tag.gamesCount > 0"
                    :key="tag.name"
                    :class="[
                      'menu__item',
                      {
                        'menu__item--active': tag.lowerCaseName === currentTagId,
                      },
                    ]"
                    @click="handleTagClick(tag)"
                  >
                    {{ tag.name }}
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </div>
      <template v-if="hasUser">
        <div
          :class="[
            'menu__item',
            {
              'menu__item--active': isFavoriteFilter,
            },
          ]"
          @click="handleFavoriteFilter"
        >
          {{ _("favorites") }}
        </div>
      </template>
      <template v-if="hasUser && countOfRecommendations > 0">
        <div
          :class="[
            'menu__item',
            {
              'menu__item--active': isRecommendationFilter,
            },
          ]"
          @click="handleRecommendationFilter"
        >
          {{ _("recommendations") }}
        </div>
      </template>
      <template v-if="hasUser">
        <hr class="menu__item menu__item--line" />
        <BuyTimeButton :on-click="closeMenu" :is-mobile-button="true" />
      </template>
      <template v-if="hasUser">
        <div class="menu__item" @click="handleContactsClick">
          {{ _("modal.contacts.title") }}
        </div>
      </template>
      <template v-if="hasUser">
        <div class="menu__item" @click="handleInfoClick">
          {{ _("info") }}
        </div>
      </template>
      <template v-if="hasUser">
        <div class="menu__item" @click="handleBetSportClick">
          {{ _("bet_history_title_short") }}
        </div>
      </template>
      <template v-if="hasUser">
        <div class="menu__item" @click="handleMemberCardClick">{{ _("member_card") }}</div>
      </template>
      <template v-if="hasUser">
        <hr class="menu__item menu__item--line" />
        <div class="menu__item" @click="handleSignOutClick">
          {{ _("exit") }}
          <v-button
            variant="icon"
            label="Sign-Out"
            icon="sign-out"
            class="menu__item--btn-sign-out"
          />
        </div>
      </template>
      <v-button class="menu__btn--close" variant="icon" label="Menu" @click="handleCloseClick" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

// Mixins
import gameFilter from "@/mixins/game-filter";
import routeMixin from "@/mixins/route";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import { TAG_AVIATOR, TAG_CHRISTMAS, TAG_HALLOWEEN, TAG_CHINESE } from "@/constants/game";
import VIcon from "@/components/Helpers/Icons/VIcon.vue";
import BuyTimeButton from "@/components/Helpers/Buttons/BuyTimeButton.vue";

const CHANGE_STATE_EVENT = "change-state";

export default {
  name: "Menu",

  components: {
    BuyTimeButton,
    VIcon,
    VButton,
  },

  mixins: [gameFilter, routeMixin],

  model: {
    prop: "isOpenMenu",
    event: CHANGE_STATE_EVENT,
  },

  props: {
    isOpenMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      TAG_AVIATOR,
      TAG_HALLOWEEN,
      TAG_CHRISTMAS,
      TAG_CHINESE,
      isTheLeft: false,
    };
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
      balance: "user/balance",
      currency: "user/currency",
      hasUser: "user/hasUser",
      rawItems: "games/rawItems",
      recommendationsGames: "games/recommendedGamesIds",
      countOfRecommendations: "games/countOfRecommendations",
      // ↓ Rewrite 'gameFilter' tags
      tags: "tags/items",
    }),

    isFavoriteCountGames() {
      return this.rawItems.filter((game) => game.isFavorite).length;
    },

    gamesCount() {
      return this.items?.length;
    },
  },
  watch: {
    isOpenMenu: {
      handler(value) {
        document.body.style.overflow = value ? "hidden" : "";
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      signOut: "user/signOut",
    }),
    ...mapMutations({
      showContactsModal: "ui/showContactsModal",
      showInfoModal: "ui/showInfoModal",
      showBetsSportModal: "ui/showSportBetHistoryModal",
    }),

    async handleSignOutClick() {
      this.isFetchLogOut = true;
      try {
        await this.signOut();

        // Redirect to home page
        this.goToLoginPage();
      } catch (e) {
        throw new Error(e);
      } finally {
        this.isFetchLogOut = false;
      }
    },

    handleContactsClick() {
      this.showContactsModal();
      this.closeMenu();
    },

    handleInfoClick() {
      this.showInfoModal();
      this.closeMenu();
    },

    handleBetSportClick() {
      this.showBetsSportModal();
      this.closeMenu();
    },

    handleMemberCardClick() {
      this.closeMenu();
      this.redirectRoute("/member-card");
    },

    handleFavoriteFilter() {
      this.selectFavorite(true);
      this.selectRecommendation(false);
      this.selectProviderTag(null);
      this.closeMenu();
    },

    handleRecommendationFilter() {
      this.selectFavorite(false);
      this.selectRecommendation(true);
      this.selectProviderTag(null);
      this.closeMenu();
    },

    handleProviderClick(provider) {
      this.selectFavorite(false);
      this.selectRecommendation(false);
      this.selectProvider(provider);
      this.closeMenu();
    },

    handleTagClick(tag) {
      this.selectFavorite(false);
      this.selectRecommendation(false);
      this.selectProviderTag(tag.lowerCaseName);
      this.closeMenu();
    },

    handleCloseClick() {
      this.closeMenu();
    },

    handleOverlayClick(event) {
      if (event.target.classList.contains("menu")) {
        this.closeMenu();
      }
    },

    /**
     * @description Send emitter to close menu.
     */
    closeMenu() {
      this.$emit(CHANGE_STATE_EVENT, false);
    },
  },
};
</script>

<style lang="scss" scoped>
$close_size: 32px;
$close_offsetY: 17px;
$close_offsetX: 20px;

.tag {
  &--aviator {
    width: 55px;
    height: 20px;
    margin-left: 20px;

    svg {
      transform: scaleX(2) scaleY(2);
      width: 100%;
      height: 100%;
      color: #e50539;
    }
  }

  &--christmas {
    width: 80px;
    height: 30px;
    margin-left: 12px;

    svg {
      transform: scaleX(1.5) scaleY(1.5);
      width: 100%;
      height: 100%;
      color: #42ff00;
    }
  }

  &--halloween {
    width: 58px;
    height: 20px;
    margin-left: 20px;

    svg {
      transform: scaleX(2) scaleY(2);
      width: 100%;
      height: 100%;
      color: #ff7a00;
    }
  }

  &--chinese {
    width: 120px;
    height: 20px;
    margin-left: 35px;

    svg {
      transform: scaleX(1.8) scaleY(1.8);
      width: 100%;
      height: 100%;
      color: #efb717;
    }
  }

  @media screen and (min-width: 970px) {
    &--aviator {
      svg {
        margin-left: -10px;
        transform: scaleX(1.4) scaleY(1.4);
        color: #e50539;
      }
    }
  }

  &--img-tag-active {
    svg {
      color: white;
    }
  }
}

.menu {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.3s;
  position: fixed !important;
  z-index: 999;

  &:not(&--showed) {
    transform: translateX(100%);

    &.menu--left {
      transform: translateX(-100%);
    }
  }

  &__content {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 512px;
    background-color: fade-out($bg-black-color, 0.1);
    position: relative;
    padding-top: 10px;
    margin-left: auto;
    overflow-y: scroll;

    @media (max-height: 430px) and (orientation: landscape) {
      height: 95%;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 60px;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    & & {
      padding: 0 0 8px 30px;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    color: fade-out($text-color, 0.5);
    font-family: $base-font-family;
    text-transform: uppercase;
    box-sizing: border-box;
    position: relative;
    padding: 0 32px;
    cursor: pointer;

    @media (orientation: portrait) {
      font-size: $font-xm-size;
      height: 48px;
    }

    @media (orientation: landscape) {
      font-size: $font-md-size;
      height: 32px;
    }

    &--btn-sign-out {
      margin-left: auto;
      fill: white;
    }

    &--active {
      color: $text-color;
    }

    &--line {
      border-top: 1px solid white;
      height: 2px;
      width: 86%;
    }
  }

  &__btn--close {
    top: $close_offsetY;
    right: $close_offsetX;
    width: $close_size;
    height: $close_size;
    background-image: url($path + "hide-menu-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
  }

  &--left {
    .menu {
      &__content {
        margin-left: 0;
      }

      &__wrapper {
        padding-left: 120px;
      }

      &__btn--close {
        left: $close_offsetX;
        right: auto;
      }
    }
  }
}
</style>
