var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.win !== null)?_c('div',{staticClass:"jackpot-modal"},[(_vm.win.state === 'pending' && !_vm.isSpinned)?_c('div',{staticClass:"fortune-window",style:({ transform: `scale(${_vm.fortuneWindowScale})` })},[_c('div',{staticClass:"fortune-window__title-0"}),_c('div',{staticClass:"fortune-window__jackpots"},[_c('div',{ref:"ring",staticClass:"fortune-window__ring"},_vm._l((_vm.jackpots),function(jackpot,index){return _c('div',{key:index,class:['fortune-window__jackpot', `fortune-window__jackpot--${jackpot.color}`],style:({
            transform: `rotateX(${jackpot.angle}deg) translateZ(${_vm.transformAxiosZ})`,
          })},[_c('div',{staticClass:"fortune-window__title"},[_vm._v(_vm._s(jackpot.name))])])}),0)]),_c('div',{staticClass:"fortune-window__suggestion"},[_vm._v(" "+_vm._s(_vm._("jackpot-modal.suggestion"))+" ")]),_c('div',{staticClass:"fortune-window__background"}),_c('div',_vm._g({class:[
        'fortune-window__button fortune-window__button--spin',
        { 'fortune-window__button--disabled': _vm.isAnimating },
      ]},{ click: _vm.isAnimating ? () => {} : _vm.playAnimation }),[_c('span',[_vm._v(_vm._s(_vm._("jackpot-modal.spin")))])]),_c('div',_vm._g({class:[
        'fortune-window__lever-wrapper',
        { 'fortune-window__lever-wrapper--active': _vm.isAnimating },
      ]},{ click: _vm.isAnimating ? () => {} : _vm.playAnimation }),[_c('div',{staticClass:"fortune-window__lever"}),_c('div',{staticClass:"fortune-window__lever-ball"})])]):(_vm.win.state === 'pending' || _vm.win.state === 'paid')?_c('div',{staticClass:"congratulations-window",style:({ transform: `scale(${_vm.congratsWindowScale})` })},[_c('div',{staticClass:"congratulations-window__title"}),_c('div',{staticClass:"congratulations-window__title-1"},[_vm._v(" "+_vm._s(_vm._("jackpot-modal.congratulations-title.part-1"))+" ")]),_c('div',{staticClass:"congratulations-window__title-2"},[_vm._v(" "+_vm._s(_vm.jackpotName)+" "+_vm._s(_vm._("jackpot-modal.congratulations-title.part-2"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$f.formatMoney(_vm.$f.toMoney(_vm.win.value)))+" "+_vm._s(_vm.currency)+" ")]),_c('div',_vm._g({staticClass:"fortune-window__button fortune-window__button--take"},{
        click: () => {
          _vm.win.state === 'pending' ? _vm.acceptWin(_vm.win.id) : _vm.markWinAsViewed(_vm.win.id);
          _vm.stopSound();
        },
      }),[_c('span',[_vm._v(_vm._s(_vm.win.state === "pending" ? _vm._("jackpot-modal.take") : _vm._("jackpot-modal.ok")))])])]):(_vm.win.state === 'canceled')?_c('div',{staticClass:"expired-window"},[_c('div',{staticClass:"expired-window__content"},[_c('div',{staticClass:"expired-window__text"},[_vm._v(" "+_vm._s(_vm._("jackpot-modal.time-passed-title"))+" ")])]),_c('div',{staticClass:"expired-window__buttons"},[_c('div',{staticClass:"expired-window__button expired-window__button--blue",on:{"click":function($event){return _vm.markWinAsViewed(_vm.win.id)}}},[_vm._v(" "+_vm._s(_vm._("jackpot-modal.ok"))+" ")])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }