import { throttle } from "lodash-es";

// Helpers
const THROTTLE_DELAY = 250;

export class ViewportAnalyzer {
  constructor(isMobile = false, isLandscapeMode = true) {
    this._isMobile = isMobile;
    this._isLandscapeMode = isLandscapeMode;
    this.mobileBreakpoint = 1024;

    this.beforeUpdate = null;
    this.onUpdate = null;
  }

  /** Getters */

  get isMobile() {
    return this._isMobile;
  }

  get isLandscapeMode() {
    return this._isLandscapeMode;
  }

  /** Methods */

  /**
   * @description Update viewport variables.
   */
  updateState() {
    if (this.beforeUpdate) {
      this.beforeUpdate(this.getState());
    }

    this._isMobile = !!window.matchMedia(`(max-width: ${this.mobileBreakpoint}px)`).matches;
    this._isLandscapeMode = Math.abs(window.orientation) === 90;

    if (this.onUpdate) {
      this.onUpdate(this.getState());
    }
  }

  throttledUpdateState = throttle(this.updateState.bind(this), THROTTLE_DELAY);

  /**
   * @return {{isMobile, isLandscapeMode, heightUnit}}
   * @description Get ViewportAnalyzer state.
   */
  getState() {
    return {
      isMobile: this._isMobile,
      isLandscapeMode: this._isLandscapeMode,
    };
  }

  /**
   * @param {(function, null)} onUpdate
   * @param {boolean} isLeading
   * @description Add event listeners.
   */
  init(onUpdate = null, isLeading) {
    this.onUpdate = onUpdate;
    this.addEventListeners(isLeading);
  }

  /**
   * @param {boolean} isLeading First is update?!
   * @description Add event listeners.
   */
  addEventListeners(isLeading = true) {
    if (isLeading) {
      this.updateState();
    }
    window.addEventListener("resize", this.throttledUpdateState);
    window.addEventListener("orientationchange", this.throttledUpdateState);
  }

  /**
   * @description Remove event listeners.
   */
  removeEventListeners() {
    window.removeEventListener("resize", this.throttledUpdateState);
    window.removeEventListener("orientationchange", this.throttledUpdateState);
  }
}
