var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isHide)?_c('div',{class:[
    'logo logo-default',
    {
      'logo--wide': _vm.isWide,
      'logo--full': !_vm.isCasinoOrSlots && (_vm.isFull || _vm.hasJackpots),
    },
  ],attrs:{"role":"presentation"}},[(_vm.isDemoModeActive && _vm.isFull)?_c('span',{staticClass:"logo__description uppercase"},[_vm._v(_vm._s(_vm._("free.spins")))]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }