import camelize from "camelize";
import { call } from "@/api/client";

/**
 * @typedef {Object} Provider
 * @property {number} id
 * @property {string} name
 * @property {string} stringId
 */
/**
 * @returns {Promise<Array<Provider>>}
 */
export async function fetchProviders() {
  const body = await call("Game.providerList");
  const camelizedBody = camelize(body);
  if ("error" in camelizedBody) {
    throw new Error(camelizedBody.error.message);
  }
  return camelizedBody;
}
