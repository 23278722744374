import { call } from "./client";

/**
 * @returns {Promise<string>}
 */
export async function fetchJackpotsStreamId() {
  try {
    const response = await call("Lobby.getWsStream");

    return response.stream;
  } catch (error) {
    return null;
  }
}

/**
 * @returns {Promise}
 */
export async function fetchParams() {
  return await call("Lobby.getParams");
}

export async function fetchSweepstakesUpdate(amount) {
  return await call("Ss.updateBalance", amount);
}
