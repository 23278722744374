import Vue from "vue";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import { Formatter } from "./utils/formatter";

// Base style
import "@/style/base.scss";

Vue.config.productionTip = false;

Vue.prototype.$f = new Formatter();

Vue.use(VueVirtualScroller);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://efaf9ce8f66a443ca3d6f74d92a79d0e@services-dev.kiosk.games/3",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
  });
}

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

if (process.env.NODE_ENV !== "production") {
  setTimeout(() => {
    app.$mount("#app");
  }, 500);
} else {
  app.$mount("#app");
}
