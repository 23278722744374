var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagName,_vm._g(_vm._b({tag:"component",class:{
    [_vm.variantMap[_vm.variant]]: _vm.variant,
    [_vm.activeClass]: _vm.isActive,
    'btn--is-loading': _vm.isLoading,
    'btn--shadow': _vm.settings.hasShadow,
    'btn--shine': _vm.settings.hasShine,
    'btn--mobile': _vm.settings.hasShine,
    'btn--error': _vm.hasErrors,
    [_vm.disabledClass]: _vm.disabled || _vm.isLoading,
  },attrs:{"aria-label":_vm.ariaLabel || _vm.label,"disabled":_vm.isButton && (_vm.disabled || _vm.isLoading),"role":_vm.currentRole}},'component',_vm.$attrs,false),_vm.$listeners),[_c('div',{class:`${_vm.brandClass}__name`},[_vm._v(_vm._s(_vm.caption))]),_c('div',{class:`${_vm.brandClass}__desc`},[_vm._v(_vm._s(_vm.description))])])
}
var staticRenderFns = []

export { render, staticRenderFns }