<template>
  <div class="spin-button">
    <button
      :class="['spin-button__click', { 'spin-button__click--disabled': isSpinning }]"
      @click="onClick"
    />
  </div>
</template>

<script>
export default {
  name: "SpinButton",
  props: {
    isSpinning: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
};
</script>
<style scoped lang="scss">
.spin-button {
  background-image: url("../assets/images/button-border-base.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 251px;
  height: 251px;
  position: relative;

  &__click {
    border: none;
    outline: none;
    background-image: url("../assets/images/button-spin_normal.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: 100% 90%;
    width: 140px;
    height: 160px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:active {
      transform: translate(-50%, -50%) scale(0.95);
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      opacity: 0.9;
    }

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(1);
    }
  }
}
</style>
