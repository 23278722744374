<template>
  <div class="sticker">
    <v-icon name="swipe-up" :size="26" />

    <span class="sticker__title">Swipe up</span>
  </div>
</template>

<script>
// Components
import { VIcon } from "@/components/Helpers/Icons";

export default {
  name: "SwipeUp",

  components: {
    VIcon,
  },
};
</script>

<style lang="scss" scoped>
$duration: 1.5s;
$svg_size: 26px;

.sticker {
  $_footer-size: $m_footer-btn-height + $m_footer-padding * 2;
  $_offset--landscape: 23px;
  $_offset--portrait: 20px;

  left: 50%;
  bottom: $_footer-size + $_offset--portrait;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  animation: sticker_move $duration both infinite;
  transform: translateX(-50%);
  position: absolute;
  user-select: none;
  opacity: 0.8;

  @media (orientation: landscape) and (max-width: 1023px) {
    bottom: $_offset--landscape;
  }

  @media (orientation: portrait) and (max-height: 700px) {
    bottom: $_footer-size;
  }

  @media (orientation: portrait) and (max-height: 620px) {
    animation: none;
    display: none;
  }

  @media (orientation: landscape) and (max-width: 680px) {
    animation: none;
    display: none;
  }

  &__title {
    font-size: 18px;
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    animation: sticker_title_move $duration both infinite;
  }

  svg {
    width: $svg_size;
    height: $svg_size;
    fill: currentColor;
  }
}

@keyframes sticker_move {
  $_offset: -12px;

  0% {
    -webkit-transform: translateX(-50%) translate3d(0, 0, 0);
    transform: translateX(-50%) translate3d(0, 0, 0);
  }

  50% {
    -webkit-transform: translateX(-50%) translate3d(0, $_offset, 0);
    transform: translateX(-50%) translate3d(0, $_offset, 0);
  }

  100% {
    -webkit-transform: translateX(-50%) translate3d(0, 0, 0);
    transform: translateX(-50%) translate3d(0, 0, 0);
  }
}

@keyframes sticker_title_move {
  $_offset: 5px;

  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(0, $_offset, 0);
    transform: translate3d(0, $_offset, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
