<template>
  <div :class="['home', { 'swiper-container': isMobile && !isDisabledSwiperMobile }]">
    <slot name="before" />

    <div v-if="isMobile && !isDisabledSwiperMobile" class="swiper-pagination" />
    <slot name="header" :isMobile="isMobile" />
    <slot v-if="isMobile" name="present" />
    <div
      :class="[
        'home__wheel-fortune-wrapper home__wheel-fortune-wrapper--is-fortune-page',
        { 'home__wheel-fortune-wrapper--no-jackpots': !hasJackpots },
      ]"
    >
      <slot v-if="isMobile" name="fortune-button" />
    </div>
    <div :class="['home__wrapper', { 'swiper-wrapper': isMobile }]">
      <div
        :class="[
          'home__section home__section--jackpots wrapper',
          {
            'home__wheel-fortune-wrapper--jackpots-hidden': isHiddenJackpotsMobile,
          },
          { 'swiper-slide': isMobile },
        ]"
        data-hash="jackpots"
      >
        <slot name="jackpots" :isMobile="isMobile" />

        <slot v-if="!isMobile" name="bonuses-description" />
        <div
          v-if="!isMobile"
          :class="[
            'home__wheel-fortune-wrapper',
            {
              'home__wheel-fortune-wrapper--no-jackpots': !hasJackpots || !isCasinoOrSlots,
            },
          ]"
        >
          <slot name="fortune-button" :isMobile="isMobile" />
        </div>
        <slot name="swipe_icon" :isMobile="isMobile">
          <swipe-up v-if="isMobile" />
        </slot>
      </div>

      <div
        :class="['home__section home__section--games', { 'swiper-slide': isMobile }]"
        data-hash="games"
      >
        <slot v-if="isMobile" name="games" />
      </div>
      <slot v-if="!isMobile" name="games" />

      <slot name="afterGames" :isMobile="isMobile" />

      <div
        v-if="isBrandsShown && !isHiddenBrandsMobile"
        :class="['home__section home__section--brands', { 'swiper-slide': isMobile }]"
        data-hash="brands"
      >
        <slot name="brands" />
      </div>

      <slot name="footer" :isMobile="isMobile" />
    </div>

    <slot name="after" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

// Vendors
import Swiper from "swiper/js/swiper.min.js";

// Style
import "swiper/css/swiper.min.css";

// Components
import SwipeUp from "@/components/Helpers/Icons/SwipeUp";

export default {
  name: "Container",

  components: {
    SwipeUp,
  },
  props: {
    isHiddenBrandsMobile: {
      type: Boolean,
      default: false,
    },
    isHiddenJackpotsMobile: {
      type: Boolean,
      default: false,
    },
    isDisabledSwiperMobile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      isBrandsShown: "brands/isBrandsShown",
      hasJackpots: "jackpots/hasSlots",
      isCasinoOrSlots: "providers/isCasinoOrSlots",
    }),

    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),
  },

  watch: {
    isMobile() {
      this.reinitializeSwiper();
    },

    isBrandsShown() {
      this.reinitializeSwiper();
    },
  },

  mounted() {
    this.reinitializeSwiper();
  },

  updated() {
    this.reinitializeSwiper();
  },

  methods: {
    reinitializeSwiper() {
      const TRANSPARENT_CLASS = "mobile-header--semitransparent";
      const swiperContainer = document.querySelector(".home.swiper-container");
      const $mobileHeader = (swiperContainer || document).querySelector(".mobile-header");

      if (this.swiper) {
        this.swiper.destroy(true);
      }

      if (swiperContainer && this.isMobile) {
        this.swiper = new Swiper(swiperContainer, {
          direction: "vertical",
          hashNavigation: { watchState: true },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          },
          on: {
            progress(value) {
              if ($mobileHeader) {
                if (!value) {
                  $mobileHeader.classList.remove(TRANSPARENT_CLASS);
                } else {
                  $mobileHeader.classList.add(TRANSPARENT_CLASS);
                }
              }
            },

            transitionEnd() {
              $mobileHeader.classList.remove(TRANSPARENT_CLASS);
            },
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;

  &__wheel-fortune-wrapper {
    position: relative;
    z-index: 1001;
    padding-top: 2px;
    top: -30px;
    display: flex;
    justify-content: center;
    width: 100%;

    &--no-jackpots {
      top: 58px;
    }

    @media (max-width: 1024px) {
      position: fixed;
      top: 48px;
      z-index: 4;

      &--jackpots-hidden {
        display: none;
      }
    }
  }

  @media (min-width: 1025px) {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    @media (min-width: 1025px) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }
  }

  &__section {
    &--jackpots {
      @media (min-width: 1025px) {
        position: relative;
      }
    }

    &--games {
      overflow: hidden;

      @media (min-width: 1025px) {
        margin-top: 4px;
        flex: 1;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

/** Swiper */
.swiper {
  &-container-vertical > &-pagination-bullets {
    $bullet: swiper-pagination-bullet;

    .#{$bullet} {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .#{$bullet} + .#{$bullet} {
      margin: ($m_swiper-p-size * 0.75) 0 0 0;
    }
  }

  &-pagination {
    right: $m_swiper-p-indent !important;
    padding: ($m_swiper-p-size / 3) ($m_swiper-p-size / 2.5) ($m_swiper-p-size / 3) 0;
    -webkit-tap-highlight-color: $null-color;

    &-bullet {
      padding: 0;
      width: $m_swiper-p-size * 2;
      height: $m_swiper-p-size * 2;
      background-color: $null-color;
      cursor: pointer;
      opacity: 1;

      &:after {
        content: "";
        flex: 0 0 $m_swiper-p-size;
        width: $m_swiper-p-size;
        height: $m_swiper-p-size;
        display: block;
        transition: box-shadow 0.4s, background 0.75s;
        background-color: fade-out($bg-white-color, 0.5);
        border-radius: 50%;
      }

      &-active {
        background-color: $null-color;

        &:after {
          background-color: fade-out($bg-white-color, 0.25);
          box-shadow: 0 0 0 $m_swiper-p-active fade-out($bg-white-color, 0.75);
        }
      }
    }
  }
}
</style>
