<template>
  <base-modal v-if="isVisible" title="Terms of use" class="modal" :content="{}">
    <template #body>
      <textarea
        disabled="disabled"
        :rows="rowsCount"
        class="modal__textarea"
        :value="textContent || ''"
      />
    </template>
    <template #footer>
      <v-button class="back-button" variant="cancel" has-texture @click="setDisagreeTerms">
        Disagree
      </v-button>
      <v-button class="back-button" variant="accept" has-texture @click="setAgreeTerms">
        I Agree
      </v-button>
    </template>
  </base-modal>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons";
import BaseModal from "./BaseModal";
import routeMixin from "@/mixins/route";
import { mapActions, mapGetters } from "vuex";
import { getSessionModalTermOFUseState, setSessionModalTermOFUseVisible } from "@/api/sessions";

const BREAK_LENGTH = 25;
const DEFAULT_ROWS_TEXTAREA = 5;

export default {
  name: "TermsOfUseModal",
  components: {
    VButton,
    BaseModal,
  },
  mixins: [routeMixin],

  props: {
    onClose: {
      type: Function,
      default: () => undefined,
    },
  },

  data() {
    return {
      isVisible: getSessionModalTermOFUseState(),
    };
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
    }),

    textContent() {
      return `SWEEPSTAKES RULES
1. Compliance with Laws
1.1 Internet Sweepstakes games may not be legal in some jurisdictions. You understand and accept that the Company is unable to provide you with any legal advice or assurances in respect of your use of the Software and the Company makes no representations whatsoever as to the legality of the Software in your jurisdiction. Please verify the relevant laws in your jurisdiction before using the Software.
2. Failures during Software Operation
2.1 Neither our company (including our employees or agents) nor our partners shall be liable for any loss including loss of winnings that results from uncontrolled system failure and errors.
2.2 The players will forfeit any winnings/losses that result from any such Error.
2.3 In case of unexpected system failure or errors, we will take immediate steps to remedy the problem.
2.4 Our company does not accept either any liability for IT failures caused by your equipment used to run our system or faults related to your internet service provider or problems with your computer.
2.5 In the event of any kind of Software interruption due to any type of problem, our company reserves the right to request screenshots and detailed information.
2.6 The company shall not be liable for the incorrect operation of application in case of using an old version of it. Only the actual version of application should be used.
3. Your Representations and Undertakings
3.1 NO PURCHASE OR PAYMENT IS NECESSARY. You are fully aware that the sweepstakes entries provided are free with no purchase required. The entries you receive are valued at zero value.
`;
    },

    rowsCount() {
      const newLinesCount = this.textContent.split("\n").length;
      let countRows = 0;

      if (newLinesCount > 0) {
        countRows += newLinesCount;
      }

      countRows += this.textContent?.length / BREAK_LENGTH;
      return countRows > 1 ? countRows : DEFAULT_ROWS_TEXTAREA;
    },
  },
  methods: {
    ...mapActions({
      signOut: "user/signOut",
    }),
    setAgreeTerms() {
      setSessionModalTermOFUseVisible(false);
      this.isVisible = false;
    },

    async setDisagreeTerms() {
      await this.signOut();
      setSessionModalTermOFUseVisible(true);
      this.goToLogin();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__textarea {
    overflow-y: visible;
    background: none;
    border: none;
    resize: none;
    min-width: 420px;
    color: white;
    font-size: $font-sm-size;
    font-family: $base-font-family, $fallback-font-family;
    width: 100%;
    text-align: left;

    @media screen and (max-width: 760px) {
      min-width: auto;
      text-align: left;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $btn-primary-color;
    }
  }

  :deep .modal {
    width: 60%;
    overflow-y: hidden;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
    }
  }

  :deep .modal__content {
    max-height: 80%;
    background: none;
  }

  :deep .modal__window {
    max-width: 700px;
    max-height: 760px;
    padding: 40px;
    overflow-y: scroll;

    @media screen and (max-width: 1024px) {
      max-width: 600px;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-shadow: none;
    }

    @media screen and (max-width: 760px) {
      width: 100%;
      max-height: 100%;
    }
  }

  :deep .modal__header {
    font-style: italic;
  }

  :deep .modal__body {
    font-size: 24px;
    font-family: Ubuntu, sans-serif;
    color: #fff;

    p {
      padding: 10px;
      line-height: 1.8px;
    }

    @media screen and (max-width: 1024px) {
      max-height: 475px;
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 565px) {
    .back-button + .back-button {
      margin-left: 18px;
    }
  }

  @media screen and (orientation: landscape) {
    :deep .modal__content {
      max-height: 80%;
      background: none;
    }

    :deep .modal__window {
      overflow: hidden;
    }
  }

  @media screen and (orientation: landscape) and (max-height: 480px) {
    :deep .modal__content {
      max-height: 50%;
      background: none;
    }
  }

  .back-button {
    width: 190px;
    height: 40px;
    font-size: 16px;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    margin: 0 auto;

    @media screen and (max-width: 720px) {
      width: 240px;
      margin-top: -26px;
    }
  }
}
</style>
