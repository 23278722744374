import { errorMessageMutations, errorMessageState } from "../mixins/error-message";
import * as api from "../../api";
import { localesMap } from "@/utils/languages";

export const DEFAULT_LANGUAGE = "en";

const state = {
  ...errorMessageState,
  /**
   *["en", "es", "pt", "ru", "de"]
   */
  locales: null,
  items: null,
  current: null,
  apiLanguages: null,
};

/** Actions */
const actions = {
  async fetch({ commit, state }, { lang }) {
    try {
      let computedLang = lang;
      if (!state.locales.includes(lang)) {
        computedLang = state.apiLanguages.defaultLang;
      }

      const localizations = await api.l10n.fetchLocalizations(computedLang || DEFAULT_LANGUAGE);

      // Assuming localizations is now in the format of en.json { "Bonuses": "Bonuses", "Amount": "Amount"}

      // Mutate the state to set items
      commit("setItems", localizations);

      // Clear error state if there was an error previously
      if (state.error !== null) {
        commit("setError", null);
      }
    } catch (error) {
      // Handle errors appropriately
      if (state.items !== null) {
        commit("setItems", null);
      }
      commit("setError", error.message || "Localizations error.");
    }
  },

  async setLanguage({ commit, dispatch }, { lang }) {
    await dispatch("fetch", { lang });

    commit("setCurrent", lang);
  },
  async fetchLanguages({ commit }) {
    try {
      const languages = await api.language.fetchLanguage();

      commit("setLanguages", languages);

      if (state.error !== null) {
        commit("setLanguages", null);
      }
    } catch (error) {
      if (state.items !== null) {
        commit("setLanguages", null);
      }
    }
  },
};

/** Getters */
const getters = {
  currentLang: (state) => state.current,
  allowLocalesFromApi: (state) => {
    const languagesFromApi = [];
    const apiLanguageList = state?.apiLanguages?.languages;

    for (const lang of apiLanguageList ?? []) {
      languagesFromApi.push(lang.code);
    }

    return languagesFromApi.filter((lang) => Object.keys(localesMap).includes(lang));
  },
  translate: (state) => (messageId) => {
    if (state.current === null || state.items === null) {
      return messageId;
    }

    return state.items[messageId] ?? messageId;
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,
  setLanguages(state, items) {
    state.apiLanguages = items;
    const languagesFromApi = [];
    const apiLanguageList = state?.apiLanguages?.languages;

    for (const lang of apiLanguageList ?? []) {
      languagesFromApi.push(lang.code);
    }
    state.locales = languagesFromApi.filter((lang) => Object.keys(localesMap).includes(lang));
  },

  setItems(state, items) {
    state.items = items;
  },

  setCurrent(state, lang) {
    state.current = lang;
    api.language.setLangToStorage((state.current = lang ?? DEFAULT_LANGUAGE));
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
