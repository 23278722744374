import { call } from "./client";

/**
 * @typedef {Object} Brand
 * @property {number} id
 * @property {string} name
 * @property {string} stringId
 */
/**
 * @returns {Promise<Array<Brand>>}
 */
export async function fetchBrands() {
  const response = await call("Game.sectionList");
  if (response.list === null) {
    return [];
  }
  return response.list.map((brand) => ({
    id: brand.id,
    name: brand.name,
    stringId: brand.stringId,
  }));
}
