<template>
  <base-modal v-if="isShowModal" title="Purchase internet Time" class="modal" :content="{}">
    <template #body>
      <template v-if="isLoading"><Loading /></template>
      <div class="modal__height-wrapper">
        <div class="modal__grid-buttons">
          <v-button
            :disabled="hasDisabled(1)"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(1)"
          >
            1
          </v-button>
          <v-button
            :disabled="hasDisabled(5)"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(5)"
          >
            5
          </v-button>
          <v-button
            :disabled="hasDisabled(10)"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(10)"
          >
            10
          </v-button>
          <v-button
            :disabled="hasDisabled(20)"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(20)"
          >
            20
          </v-button>
          <v-button
            :disabled="hasDisabled(50)"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(50)"
          >
            50
          </v-button>
          <v-button
            :disabled="hasDisabled(100)"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(100)"
          >
            100
          </v-button>
        </div>
        <div>
          <v-button
            :disabled="sweepstakesBalance?.totalWin === 0"
            class="back-button"
            variant="accept"
            has-texture
            @click="fetchSsUpdateUpdate(0)"
          >
            BUY ALL
          </v-button>
        </div>
        <div class="modal__description">10 minutes = 1 {{ currency }}</div>
      </div>
    </template>
    <template #footer>
      <v-button class="back-button" variant="accept" has-texture @click="closeModal">
        close
      </v-button>
    </template>
  </base-modal>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons";
import BaseModal from "./BaseModal";
import { mapGetters, mapMutations, mapState } from "vuex";
import { get } from "lodash-es";
import Loading from "@/components/Helpers/Loading.vue";

export default {
  name: "BuyTimeModal",
  components: {
    Loading,
    VButton,
    BaseModal,
  },

  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },

    onClose: {
      type: Function,
      default: () => undefined,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
      balance: "user/balance",
      sweepstakesBalance: "user/sweepstakesBalance",
      currency: "user/currency",
      hasUser: "user/hasUser",
    }),
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
      isBuyTimeError: (state) => {
        return get(state, "lobby.error", null);
      },
    }),
  },

  methods: {
    ...mapMutations({
      handleCloseModal: "ui/hideBuyTimeModal",
      handleOpenModalSuccessOrError: "ui/showSuccessOrErrorModal",
      setSuccessOrErrorModalError: "ui/setSuccessOrErrorModalError",
    }),

    closeModal() {
      this.handleCloseModal();
      this.onClose?.();
    },

    async fetchSsUpdateUpdate(amount) {
      this.isLoading = true;
      const amountOfValue = amount * 100;
      await this.$store.dispatch("lobby/fetchSsUpdate", {
        amount: amountOfValue,
      });

      if (this.isBuyTimeError !== null) {
        this.setSuccessOrErrorModalError(true);
        this.handleOpenModalSuccessOrError();
      } else {
        this.setSuccessOrErrorModalError(false);
        this.handleOpenModalSuccessOrError();
      }

      this.closeModal();
      this.isLoading = false;
    },

    hasDisabled(value) {
      return this.sweepstakesBalance?.totalWin / 100 < value;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__height-wrapper {
    max-height: calc(100% - 50px);
    overflow-y: scroll;
    width: 100%;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 6px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $btn-primary-color;
    }
  }

  &__grid-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    margin-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;

    @media (max-width: 960px) and (orientation: portrait) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);

      .back-button {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 960px) and (orientation: landscape) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 12px;
      margin-bottom: 12px;
      padding: 0;

      .back-button {
        width: 180px;
      }
    }
  }

  &__description {
    margin-top: 24px;
    font-size: 16px;
    text-align: center;
  }
}

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}

:deep .modal__content {
  background: none;
  overflow-y: hidden;
  padding: 5px 0;
  border: none;
  resize: none;
  min-width: 420px;
  color: white;
  font-size: $font-sm-size;
  font-family: $base-font-family, $fallback-font-family;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 760px) {
    min-width: auto;
    text-align: left;
  }
}

:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}

:deep .modal__window {
  max-width: 700px;
  max-height: 400px;
  padding: 20px;
  //overflow-y: hidden;

  @media screen and (max-width: 960px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    max-height: 80%;
  }

  @media screen and (max-width: 380px) {
    width: 100%;
    max-height: 96%;
  }

  @media screen and (max-height: 480px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__content {
  align-items: flex-start;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 960px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 200px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;

  @media (max-width: 1600px) {
    width: 200px;
  }

  @media (max-width: 1600px) and (max-height: 800px) {
    width: 140px;
  }

  @media (max-width: 1400px) {
    width: 160px;
  }

  @media (max-width: 768px) {
    width: 140px;
  }

  @media (max-width: 960px) and (orientation: landscape) {
    width: 180px;
  }
}
</style>
