import { mapState, mapGetters } from "vuex";
import { poller } from "@/services/PollingService/PollingService";
import { userWatchActivityService } from "@/services/UserWatchActivityService/UserWatchActivityService";

// Helpers
const REFRESH_INTERVAL = 25000;

export default {
  computed: {
    ...mapState({
      userError: (state) => state.user.error,
      bonusError: (state) => state.bonus.error,
    }),

    ...mapGetters({
      isDemoModeActive: "app/isDemoModeActive",
      isShowUserErrorNotice: "user/isShowErrorNotice",
      isShowBonusErrorNotice: "bonus/isShowErrorNotice",
      hasUser: "user/hasUser",
    }),
  },

  methods: {
    /**
     * @description Start update interval. Refreshes `user` & `bonus` data.
     */
    startUpdateDataInterval() {
      if (this.isDemoModeActive) {
        return;
      }

      poller.poll(() => {
        // User. Check & fetch...
        if (this.isShowUserErrorNotice) {
          this.$store.dispatch("user/showErrorNotice");
        } else if (!this.userError.hasLimit) {
          this.$store.dispatch("user/fetch");
        }

        // Bonus. Check & fetch...
        if (this.isShowBonusErrorNotice) {
          this.$store.dispatch("bonus/showErrorNotice");
        } else if (!this.bonusError.hasLimit) {
          this.$store.dispatch("bonus/fetch");
        }

        // Clear interval
        if (this.userError.hasLimit && this.bonusError.hasLimit) {
          this.stopUpdateDataInterval();
        }
      }, REFRESH_INTERVAL);

      userWatchActivityService.init();
    },

    /**
     * @description Stop update interval.
     */
    stopUpdateDataInterval() {
      userWatchActivityService.dispose();
      poller.dispose();
    },
  },
};
