<template>
  <component
    :is="tagName"
    :class="{
      [variantMap[variant]]: variant,
      [activeClass]: isActive,
      'btn--is-loading': isLoading,
      'btn--shadow': settings.hasShadow,
      'btn--shine': settings.hasShine,
      'btn--mobile': settings.hasShine,
      'btn--error': hasErrors,
      'btn--rounded': hasRounded,
      [disabledClass]: disabled || isLoading,
    }"
    :aria-label="ariaLabel || label"
    :disabled="isButton && (disabled || isLoading)"
    :role="currentRole"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span v-if="hasContent && !isLoading" class="btn__content">
      <slot />
    </span>

    <svg-link
      v-if="hasTexture"
      :class="{
        'btn__pattern': true,
        'btn--rounded': hasRounded,
      }"
      href="pattern"
      width="100%"
      height="100%"
    />

    <v-icon v-if="icon" :name="icon" class="btn__icon" v-bind="iconProps" />

    <v-icon v-if="isLoading" name="loader" class="btn__loading" />
  </component>
</template>

<script>
import { validateProp } from "@/utils/validator";

// Components
import { VIcon, SvgLink } from "@/components/Helpers/Icons";

const VARIANTS = [
  "tag",
  "brand",
  "provider",
  "accept",
  "cancel",
  "primary",
  "secondary",
  "prev",
  "next",
  "play",
  "demo",
  "icon",
];

export default {
  name: "VButton",

  components: {
    SvgLink,
    VIcon,
  },

  inheritAttrs: false,

  props: {
    tagName: {
      type: String,
      default: "div",
    },

    variant: {
      type: String,
      default: "",
      validator: (value) => validateProp(value, VARIANTS),
    },

    icon: {
      type: [String, null],
      default: null,
    },

    iconProps: {
      type: Object,
      default: () => ({ size: 32 }),
    },

    activeClass: {
      type: String,
      default: "btn--active",
    },

    disabledClass: {
      type: String,
      default: "btn--disabled",
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    hasTexture: {
      type: Boolean,
      default: false,
    },

    hasRounded: {
      type: Boolean,
      default: false,
    },

    ariaLabel: {
      type: [String, null],
      default: null,
    },

    label: {
      type: [String, null],
      default: null,
    },

    role: {
      type: [String, null],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hasErrors: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /** Set default button props */
      defaultBtnData: {
        hasShine: false,
        hasShadow: false,
        hasPattern: false,
      },

      variantMap: {
        tag: "btn btn--tag",
        brand: "btn btn--brand",
        provider: "btn btn--provider",

        accept: "btn btn--accept",
        primary: "btn btn--primary",
        secondary: "btn btn--secondary",
        cancel: "btn btn--cancel",

        prev: "btn btn--prev",
        next: "btn btn--next",

        play: "btn btn--play",
        demo: "btn btn--demo",

        icon: "btn btn--icon",
      },

      dataMap: {
        tag: {
          hasShine: true,
        },

        primary: {
          hasShadow: true,
          hasShine: true,
        },

        secondary: {
          hasShadow: true,
          hasShine: true,
        },

        accept: {
          hasShadow: true,
          hasShine: true,
        },

        cancel: {
          hasShadow: true,
          hasShine: true,
        },
      },

      interactiveTagMap: {
        button: true,
        link: true,
      },
    };
  },

  computed: {
    isButton() {
      return this.tagName === "button";
    },

    isInteractive() {
      return !!this.interactiveTagMap[this.tagName];
    },

    currentRole() {
      return !this.role && !this.isInteractive ? "button" : this.role;
    },

    hasContent() {
      return !!this.$slots.default;
    },

    settings() {
      return {
        ...this.defaultBtnData,
        ...this.dataMap[this.variant],
      };
    },
  },
};
</script>

<style lang="scss">
$active: btn--active;
$disable: btn--disabled;
$hide: btn--hide;
$small: btn--sm;

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: $text-color;
  font-family: $base-font-family;
  font-size: $font-xxs-size;
  background-repeat: no-repeat;
  transition: background-color 0.5s, opacity 0.5s, border-color 0.5s;
  -webkit-tap-highlight-color: rgba($white-color, 0);
  -webkit-tap-highlight-color: transparent;
  border-style: solid;
  border-image-slice: 1;
  border-color: $null-color;
  border-width: 0;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  padding: 0.1em 0.5em;
  cursor: pointer;
  outline: none;

  &__content {
    z-index: 15;
  }

  &__icon {
    display: inline-block;
  }

  /** Effects ---*/

  &__pattern,
  &--shine:after,
  &--shadow:before,
  &--light-shadow:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
  }

  &__pattern {
    z-index: 0;
  }

  &--shine:after,
  &--shadow:before {
    content: "";
    background-image: url("../../../assets/images/btn_effects.png");
    background-repeat: no-repeat;
    background-size: 100% 200%;
    z-index: 5;
  }

  &--shadow:before {
    background-position-y: 0;
  }

  &--shine {
    &:after {
      background-position-y: 100%;
      z-index: 10;
    }
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
  }

  &--rounded {
    border-radius: 50px;
    &:before,
    &:after {
      border-radius: 50px;
    }
  }

  /** Loading ---*/

  &--is-loading {
    .btn__icon {
      margin-bottom: -55%;
      opacity: 0.1;
      z-index: -1;

      ~ .btn__loading {
        width: 60%;
        height: 60%;
      }
    }
  }

  /** Variants ---*/

  // Primary

  &--accept,
  &--primary {
    border-width: 1px;
    min-width: 80px;
    background-color: $btn-primary-color;
    border-image-source: linear-gradient(
      to bottom,
      fade-out($border-primary-color, 0.5),
      $border-primary2-color,
      fade-out($border-primary-color, 0.5)
    );

    &:not(.#{$disable}) {
      &:hover,
      &:active,
      &.#{$active} {
        background: lighten($btn-primary-color, 10%);
        background: radial-gradient(
          circle,
          lighten($btn-primary-color, 15%) 0,
          $btn-primary-color 100%
        );
      }
    }
  }

  // Secondary

  &--secondary {
    border-width: 1px;
    min-width: 80px;
    background-color: $btn-secondary-color;
    border-image-source: linear-gradient(
      to bottom,
      fade-out($border-secondary-color, 0.5),
      $border-secondary2-color,
      fade-out($border-secondary-color, 0.5)
    );

    &:not(.#{$disable}) {
      &:hover,
      &:active,
      &.#{$active} {
        background: lighten($btn-secondary-color, 10%);
        background: radial-gradient(
          circle,
          lighten($btn-secondary-color, 15%) 0,
          $btn-secondary-color 100%
        );
      }
    }
  }

  // Provider

  &--provider {
    font-size: $font-xs-size;
    font-family: $second-font-family;
    background-color: $bg-darkRed-color;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: inset 0 0 2px 0 fade-out($bg-white-color, 0.5);
    cursor: pointer;
    opacity: 0.6;

    &:hover {
      opacity: 0.9;
    }

    &.#{$active} {
      &,
      &:hover {
        opacity: 1;
      }
    }
  }

  // Brand

  &--brand {
    background-color: $null-color;
    border: none;
    padding: 0;
  }

  // Tag

  &--tag {
    background: $btn-tag-color;
    background: linear-gradient(
      to bottom,
      $null-color,
      fade-out($btn-tag-color, 0.5) 15%,
      fade-out($btn-tag-color, 0.15) 30%,
      $btn-tag-color 50%,
      fade-out($btn-tag-color, 0.15) 70%,
      fade-out($btn-tag-color, 0.5) 85%,
      $null-color
    );

    border-image-source: linear-gradient(
      to bottom,
      $null-color,
      fade-out($white-color, 0.25),
      $null-color
    );
    text-transform: uppercase;
    box-sizing: border-box;
    border-width: 1px;
    padding: 4px 12px;

    &:not(.#{$disable}) {
      &:hover,
      &:active,
      &.#{$active} {
        $color: $btn-activeTag-color;

        background: $color;
        background: linear-gradient(
          to bottom,
          $null-color,
          fade-out($color, 0.5) 15%,
          fade-out($color, 0.15) 30%,
          $color 50%,
          fade-out($color, 0.15) 70%,
          fade-out($color, 0.5) 85%,
          $null-color
        );
      }
    }

    &.#{$disable} {
      &,
      &:hover,
      &:active {
        //opacity: 0.4;
        cursor: default;
        background-color: $btn-disabled-color;
      }
    }
  }

  // Accept & cancel

  &--accept,
  &--cancel {
    font-size: $font-lg-size;
    text-transform: uppercase;
    background-size: contain;
  }

  &--accept {
    border-width: 2px;
  }

  &--cancel {
    border-width: 2px;
    background-color: $btn-cancel-color;
    border-image-source: linear-gradient(
      to bottom,
      fade-out($border-cancel-color, 0.5),
      $border-cancel2-color,
      fade-out($border-cancel-color, 0.5)
    );

    &:not(.#{$disable}) {
      &:hover,
      &:active,
      &.#{$active} {
        background: lighten($btn-cancel-color, 10%);
        background: radial-gradient(
          circle,
          lighten($btn-cancel-color, 15%) 0,
          $btn-cancel-color 100%
        );
      }
    }
  }

  // Disabled

  &--accept,
  &--cancel,
  &--primary,
  &--secondary {
    &.#{$disable} {
      background-color: $btn-disabled-color;
      border-image-source: linear-gradient(
        to bottom,
        $null-color,
        lighten($btn-disabled-color, 20%),
        $null-color
      );
    }
  }

  // Prev & Next

  &--prev,
  &--next {
    background-image: url($path + "slider_nav.png");
    background-position: left top;
    background-size: 100% auto;
    padding: 0;

    &:not(.#{$disable}):not(.#{$small}):hover {
      background-position: left bottom;
    }

    &.#{$disable} {
      filter: grayscale(100%);
    }
  }

  &--next {
    transform: scaleX(-1);
  }

  // Play & Demo

  &--play,
  &--demo {
    font-size: $font-xs-size;
    border-width: 1px;
    background-color: $btn-game-color;
    border-image-source: linear-gradient(
      to bottom,
      fade-out(lighten($btn-game-color, 10%), 0.5),
      lighten($btn-game-color, 30%),
      fade-out(lighten($btn-game-color, 10%), 0.5)
    );
  }

  // Icon

  &--icon {
    padding: 0;

    svg {
      transition: fill 0.3s, transform 0.3s;
    }

    &.#{$hide} {
      svg {
        fill: none;
      }
    }
  }

  /** Mobile styles ---*/

  &--mobile {
    &.btn--secondary {
      $color: darken($btn-secondary-color, 5%);
      $border: darken($border-secondary-color, 10%);

      background-color: $color;
      border-image-source: linear-gradient(
        to bottom,
        fade-out($border, 0.5),
        $border-secondary2-color,
        fade-out($border, 0.5)
      );
    }
  }

  /** Error styles ---*/

  &--error {
    animation-name: shake;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
}

@keyframes fetch-loader {
  0%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(0);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  20%,
  60% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>
