<template>
  <div class="desktop-menu">
    <!--    <div class="desktop-menu__separator" />-->

    <div class="desktop-menu__items" role="list">
      <v-button
        variant="tag"
        class="desktop-menu__item"
        :is-active="currentTagId === null && !isFavoriteFilter && !isRecommendationFilter"
        role="listitem"
        @click="handleClick(null)"
      >
        {{ _("all-games") }}
      </v-button>

      <v-button
        v-if="hasUser"
        variant="tag"
        :is-active="currentTagId === null && isFavoriteFilter"
        class="desktop-menu__item"
        role="listitem"
        @click="handleFavoriteFilter"
      >
        {{ _("favorites") }}
      </v-button>

      <v-button
        v-if="hasUser && countOfRecommendations > 0"
        variant="tag"
        :is-active="isRecommendationFilter"
        class="desktop-menu__item desktop-menu__item--recommendation"
        role="listitem"
        @click="handleRecommendationFilter"
      >
        {{ _("recommendations") }}
      </v-button>

      <template v-for="tag in tags">
        <template v-if="tag.name === TAG_AVIATOR">
          <v-button
            :key="tag.name"
            variant="tag"
            class="desktop-menu__item"
            :is-active="tag.lowerCaseName === currentTagId"
            :disabled="!tag.gamesCount"
            role="listitem"
            @click="tag.gamesCount || isFavoriteFilter ? handleClick(tag.lowerCaseName) : null"
          >
            <div class="tag--aviator">
              <VIcon name="aviator" />
            </div>
          </v-button>
        </template>
        <template v-if="tag.name === TAG_CHRISTMAS">
          <v-button
            :key="tag.name"
            variant="tag"
            class="desktop-menu__item"
            :is-active="tag.lowerCaseName === currentTagId"
            :disabled="!tag.gamesCount"
            role="listitem"
            @click="tag.gamesCount || isFavoriteFilter ? handleClick(tag.lowerCaseName) : null"
          >
            <div class="tag--christmas">
              <VIcon name="christmas" />
            </div>
          </v-button>
        </template>
        <template v-if="tag.name === TAG_HALLOWEEN">
          <v-button
            :key="tag.name"
            variant="tag"
            class="desktop-menu__item"
            :is-active="tag.lowerCaseName === currentTagId"
            :disabled="!tag.gamesCount"
            role="listitem"
            @click="tag.gamesCount || isFavoriteFilter ? handleClick(tag.lowerCaseName) : null"
          >
            <div class="tag--halloween">
              <VIcon name="halloween" />
            </div>
          </v-button>
        </template>
        <template v-if="tag.name === TAG_CHINESE">
          <v-button
            :key="tag.name"
            variant="tag"
            class="desktop-menu__item"
            :is-active="tag.lowerCaseName === currentTagId"
            :disabled="!tag.gamesCount"
            role="listitem"
            @click="tag.gamesCount || isFavoriteFilter ? handleClick(tag.lowerCaseName) : null"
          >
            <div class="tag--chinese">
              <VIcon name="chinese-tag" />
            </div>
          </v-button>
        </template>
        <template
          v-if="
            tag.name !== TAG_AVIATOR &&
            tag.name !== TAG_HALLOWEEN &&
            tag.name !== TAG_CHINESE &&
            tag.name !== TAG_CHRISTMAS
          "
        >
          <v-button
            :key="tag.name"
            variant="tag"
            class="desktop-menu__item"
            :is-active="tag.lowerCaseName === currentTagId"
            :disabled="!tag.gamesCount"
            role="listitem"
            @click="tag.gamesCount || isFavoriteFilter ? handleClick(tag.lowerCaseName) : null"
          >
            {{ _(tag.name) }}
          </v-button>
        </template>
      </template>
      <div :class="['search search-block', { 'search--expanded': !!isSearchFocused }]">
        <input
          type="text"
          class="search__input"
          :placeholder="_('search')"
          :value="searchQuery"
          @input="handleSearchQueryChange"
          @focus="handleSearchFocus"
          @blur="handleSearchBlur"
        />
        <div
          class="search__reset-button"
          role="button"
          aria-label="Search reset"
          @click="handleResetButtonClick"
        />
      </div>
    </div>

    <div v-if="hasDemoMode" class="desktop-menu__demo flashing">
      {{ _("free.spins") }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { debounce } from "lodash-es";

// Mixins
import gameFilter from "@/mixins/game-filter";

// Constants
import { DEBOUNCE_SEARCH_DELAY } from "@/constants/app";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import VIcon from "@/components/Helpers/Icons/VIcon.vue";
import { TAG_AVIATOR, TAG_CHINESE, TAG_CHRISTMAS, TAG_HALLOWEEN } from "@/constants/game";

export default {
  name: "DesktopMenu",

  components: { VIcon, VButton },

  mixins: [gameFilter],

  data() {
    return {
      TAG_CHINESE,
      TAG_AVIATOR,
      TAG_HALLOWEEN,
      TAG_CHRISTMAS,
      isSearchFocused: false,
    };
  },

  computed: {
    ...mapState({
      hasDemoMode: (state) => state.app.hasDemoMode,
    }),

    isFavoriteCountGames() {
      return this.rawItems.filter((game) => game.isFavorite).length;
    },

    ...mapGetters({
      hasUser: "user/hasUser",
      rawItems: "games/rawItems",
      countOfRecommendations: "games/countOfRecommendations",
      recommendationsGames: "games/recommendedGamesIds",
      _: "l10n/translate",
    }),
  },

  watch: {
    isFavoriteCountGames() {
      if (this.isFavoriteCountGames === 0) {
        this.selectFavorite(false);
        this.handleClick(null);
      }
    },
  },

  methods: {
    handleClick(tagId) {
      this.selectTag(tagId);
      this.selectFavorite(false);
      this.selectRecommendation(false);
    },

    handleFavoriteFilter() {
      this.selectRecommendation(false);
      this.selectFavorite(true);
      this.selectTag(null);
    },

    handleRecommendationFilter() {
      this.selectRecommendation(true);
      this.selectFavorite(false);
      this.selectTag(null);
    },

    handleSearchQueryChange: debounce(function (event) {
      this.runSearchQuery(event.target.value);
    }, DEBOUNCE_SEARCH_DELAY),

    handleSearchFocus() {
      this.isSearchFocused = true;
    },

    handleSearchBlur() {
      if (this.isEmptyQuery) {
        this.isSearchFocused = false;
      }
    },

    handleResetButtonClick() {
      this.isSearchFocused = false;
      this.clearSearchQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  &--aviator {
    width: 55px;
    height: 15px;

    svg {
      color: #e50539;
      width: 100%;
      height: 100%;
    }
  }

  &--christmas {
    width: 80px;
    height: 20px;

    svg {
      transform: scale(1.2);
      width: 100%;
      height: 100%;
      color: #42ff00;
    }
  }

  &--chinese {
    width: 120px;
    height: 20px;

    svg {
      transform: scale(0.95);
      width: 100%;
      height: 100%;
      color: #efb717;
    }
  }

  &--halloween {
    width: 58px;
    height: 10px;

    svg {
      transform: scale(1.6);
      color: #ff7a00;
      width: 100%;
      height: 100%;
    }
  }
}

.desktop-menu {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 80px;

  &__separator {
    flex: 1;
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0;
  }

  &__item {
    width: 120px;
    height: 60px;
    padding: 4px 20px;

    & + & {
      margin-left: -1px;
    }

    &::v-deep.btn--disabled {
      background-color: $null-color;
      opacity: 0.35;
    }

    &--recommendation {
      width: 165px;
      text-wrap: balance;
      word-break: break-word;
    }
  }

  &__demo {
    top: 0;
    bottom: 0;
    right: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1366px) {
    padding: 0 160px;
  }
}

.search {
  flex: 1;
  height: 52px;
  position: relative;

  &__input {
    width: 120px;
    height: 60px;
    min-width: 100px;
    color: $text-color;
    border: none;
    font-size: $font-xxs-size;
    font-family: $base-font-family;
    text-transform: uppercase;
    background-color: transparent;
    background-image: url($path + "search-icon.png"),
      linear-gradient(0deg, $null-color 0%, fade-out($bg-white-color, 0.4) 50%, $null-color 100%);
    background-size: 16px 16px, 1px 100%;
    background-position: 8px center, right center;
    background-repeat: no-repeat;
    transition: width 0.25s ease, min-width 0.25s ease, padding-right 0.25s ease;
    position: relative;
    box-sizing: border-box;
    outline: none;
    padding: 0 8px 0 32px;
  }

  &__reset-button {
    top: 10px;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    display: none;
    background-image: url($path + "cross-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    position: absolute;
    cursor: pointer;
  }

  &--expanded {
    .search__input {
      width: 100%;
      min-width: 119px;
      padding-right: 32px;
    }

    .search__reset-button {
      display: block;
    }
  }
}
</style>
