import { call } from "@/api/client";
import { localesMap } from "@/utils/languages";

// Helpers
const LOCAL_STORAGE_KEY = "language";
const localesMapKeys = Object.keys(localesMap);

export const getLangFromStorage = () => {
  const currentLang = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  return localesMapKeys.includes(currentLang) ? currentLang : "en";
};

export function setLangToStorage(lang) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, lang);
}
export async function fetchLanguage() {
  return await call("Lobby.getLanguages", {});
}
