<template>
  <div
    v-if="hasUser"
    :class="[
      'sweepstakes',
      {
        'sweepstakes--column': isColumn,
        'sweepstakes--small': isSmall,
      },
    ]"
  >
    <div class="sweepstakes__item">
      <div class="sweepstakes__title">
        {{ _("entries") }}
      </div>
      <div class="sweepstakes__value">
        {{ $f.formatMoney($f.toMoney(sweepstakesBalance?.entries)) }}
      </div>
    </div>
    <div class="sweepstakes__item">
      <div class="sweepstakes__title">{{ _("total-win") }} ({{ currency }})</div>
      <div class="sweepstakes__value">
        {{ $f.formatMoney($f.toMoney(sweepstakesBalance?.totalWin)) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SweepstakesBalance",
  props: {
    isColumn: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    balanceEntries: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      sweepstakesBalance: "user/sweepstakesBalance",
      hasUser: "user/hasUser",
      currency: "user/currency",
      _: "l10n/translate",
    }),
  },
};
</script>

<style lang="scss" scoped>
.sweepstakes {
  display: flex;
  align-items: center;
  font-family: $base-font-family;
  font-size: $font-sm-size;
  color: $text-color;

  $r: &;

  &--small {
    @media (orientation: landscape) {
      #{$r}__item + #{$r}__item {
        margin-left: 12px;
      }

      #{$r}__value {
        font-size: $font-sm-size;
      }

      #{$r}__title {
        font-size: 10px;
      }
    }
  }

  &--column {
    @media (orientation: landscape) {
      flex-direction: column;
      justify-content: center;

      #{$r}__item + #{$r}__item {
        margin-left: unset;
        margin-top: 4px;
      }
    }
  }

  &__item + &__item {
    margin-left: 28px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 4px;
  }

  &__title {
    color: $text-color;
    font-family: $base-font-family;
    font-size: 10px;
    text-transform: uppercase;
  }

  &__value {
    color: $text-color;
    font-size: $font-md-size;
    font-family: $base-font-family;
    text-transform: uppercase;
  }
}
</style>
