import Vue from "vue";
import Vuex from "vuex";

import ui from "./modules/ui";
import app from "./modules/app";
import l10n from "./modules/l10n";
import user from "./modules/user";
import tags from "./modules/tags";
import bonus from "./modules/bonus";
import games from "./modules/games";
import lobby from "./modules/lobby";
import search from "./modules/search";
import brands from "./modules/brands";
import session from "./modules/session";
import jackpots from "./modules/jackpots";
import providers from "./modules/providers";
import history from "./modules/history";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    ui,
    app,
    l10n,
    user,
    tags,
    bonus,
    games,
    lobby,
    search,
    brands,
    session,
    jackpots,
    providers,
    history,
  },
  mutations: {},
  actions: {},
  state: {},
});
