/* eslint-disable no-console */
const SERVER_ERROR = "Server isn't responding. please try again later.";
const DEFAULT_ERROR = "Unknown error.";
const LIMIT_ERROR = 6; // 30 sec.

/** InitialState */
export const initialErrorState = {
  number: 0,
  message: null,
  hasLimit: false,
  displayNotice: true,
};

/** State */
export const errorState = {
  error: initialErrorState,
};

/** Actions */
export const errorActions = {
  async showErrorNotice({ commit }) {
    console.error(SERVER_ERROR);
    commit("setDisplayNotice", false);
  },
};

/** Getters */
export const errorGetters = {
  isShowErrorNotice(state) {
    return state.error ? state.error.number >= LIMIT_ERROR && state.error.displayNotice : false;
  },
};

/** Mutations */
export const errorMutations = {
  setError(state, message = DEFAULT_ERROR) {
    const number = state.error.number + 1;

    state.error = {
      ...state.error,
      number,
      message,
      hasLimit: number >= LIMIT_ERROR,
    };
  },

  clearError(state) {
    if (state.error && state.error.message) {
      state.error = {
        ...state.error,
        number: 0,
        message: null,
        hasLimit: false,
        isNotified: false,
      };
    }
  },

  setDisplayNotice(state, value = true) {
    if (state.error) {
      state.error.displayNotice = value;
    }
  },
};
