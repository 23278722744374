<template>
  <div>
    <div class="bonus-table">
      <h3 class="bonus-title">{{ _("Bonuses") }}</h3>
      <div class="flex-table">
        <div class="wrapper">
          <div class="flex-row flex-row--header">
            <div class="flex-header">{{ _("Date") }}</div>
            <div class="flex-header">{{ _("Bonus") }}</div>
            <div class="flex-header">{{ _("Amount") }}</div>
            <div class="flex-header">{{ _("Wager") }}</div>
            <div class="flex-header">{{ _("Status") }}</div>
          </div>
          <div v-if="!bonuses && !isBonusAvailable" class="flex-row">
            <div class="flex-cell">
              <div style="position: relative">
                <Loading />
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="bonus in bonuses" :key="bonus.issuedAt" class="flex-row">
              <div class="flex-cell">{{ bonusIssuedAt(bonus.issuedAt) }}</div>
              <div class="flex-cell">{{ bonus.type }}</div>
              <div class="flex-cell">
                {{ $f.formatMoney($f.toMoney(bonus.amount)) }}
              </div>
              <div class="flex-cell">
                {{ $f.formatMoney($f.toMoney(bonus.wager)) }}
              </div>
              <div class="flex-cell">{{ _(`${bonus.status}`) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bonusMixin from "./api/use-bonuses";
import Loading from "@/components/Helpers/Loading.vue";
import { mapGetters } from "vuex";

export default {
  components: { Loading },
  mixins: [bonusMixin],
  computed: {
    bonuses() {
      return this.acceptedBonusList;
    },
    ...mapGetters({
      _: "l10n/translate",
    }),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: max-content;

  @media screen and (max-width: 480px) {
    width: max-content;
  }
}

.flex-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 4px !important;
    background: $btn-primary-color;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px !important;
    background: $btn-primary-color;
  }

  &::-webkit-scrollbar {
    width: 2px !important;
    height: 4px !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $btn-primary-color;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
}

.flex-header {
  background-color: rgba(174, 166, 166, 0.47);
  font-weight: bold;
  padding: 8px;
  flex: 1;
  text-align: center;
}

.flex-cell {
  padding: 8px;
  flex: 1;
  text-align: center;
}

.bonus-table {
  margin-top: 32px;
}

.bonus-title {
  margin: 4px 0;
}
</style>
