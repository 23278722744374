import { fetchBonuses } from "@/api/bonus";

const bonusMixin = {
  data() {
    return {
      acceptedBonusList: null,
      remainingWager: null,
      wager: null,
      isBonusAvailable: true,
    };
  },

  mounted() {
    this.fetch();
  },
  beforeUpdate() {
    if (!this.remainingWager && !this.wager && this.remainingWager !== 0 && this.wager !== 0) {
      this.fetch();
    }
  },

  methods: {
    async fetch() {
      const fetchedBonuses = await fetchBonuses();
      if (fetchedBonuses?.bonus === null) {
        this.isBonusAvailable = false;
      }
      this.acceptedBonusList = fetchedBonuses?.bonus;
      this.remainingWager = fetchedBonuses?.remainingWager;
      this.wager = fetchedBonuses?.wager;
    },
    bonusIssuedAt(timestamp) {
      const dateIssued = new Date(timestamp * 1000); // Convert timestamp to milliseconds

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return dateIssued.toLocaleString("en-UK", options);
    },
  },
};

export default bonusMixin;
