<template>
  <div :class="['modal', { 'modal--full-screen': isFullScreen }]" @click="handleOverlayClick">
    <div class="modal__window">
      <div v-if="!isHideHeader" class="modal__header">
        <slot name="header" :title="title">
          {{ title }}
        </slot>
      </div>

      <div :class="['modal__content', { 'modal__content--row': isRowContent }]">
        <slot
          name="body"
          :content="content"
          :isComplexContent="isComplexContent"
          :contentTagName="contentTagName"
        >
          <template v-if="isComplexContent">
            <component
              :is="contentTagName"
              v-for="key in Object.keys(content)"
              :key="key"
              :class="`modal__${key}`"
            >
              {{ content[key] }}
            </component>
          </template>

          <template v-else>{{ content }}</template>
        </slot>
      </div>

      <div v-if="!isHideFooter" class="modal__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons";

export default {
  name: "BaseModal",

  components: { VButton },

  props: {
    title: {
      type: String,
      default: "",
    },

    /** @param {(string, Object<string>)} */
    content: {
      type: [String, Object],
      required: true,
    },

    contentTagName: {
      type: String,
      default: "div",
    },

    isHideHeader: {
      type: Boolean,
      default: false,
    },

    isHideFooter: {
      type: Boolean,
      default: false,
    },

    isRowContent: {
      type: Boolean,
      default: false,
    },

    closeEmitter: {
      type: String,
      default: "on-overlay-click",
    },

    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isComplexContent() {
      return this.content && typeof this.content === "object";
    },
  },

  methods: {
    handleOverlayClick(event) {
      if (event.target.classList.value === "modal") {
        this.$emit(this.closeEmitter, event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: fade-out($bg-black-color, 0.2);
  z-index: 9999999;

  &--full-screen {
    background-color: black;

    .modal__header {
      padding: 10px 0;
    }

    .modal__footer {
      padding: 10px 6px;
      margin-bottom: 0;
    }

    .modal__button {
      width: 120px;
      height: 40px;
      font-size: 16px;
    }

    .modal__content {
      background-image: repeating-radial-gradient(
        ellipse,
        fade-out($bg-bright-color, 0.7) 0%,
        $null-color 100%
      );
    }

    .modal__window {
      box-shadow: none;
      width: 100vw;
      height: 100vh;
      max-width: inherit;
      max-height: inherit;

      @media screen and (max-width: 768px) {
        width: 98vw;
        height: 100vh;

        .modal__header {
          padding: 2px 0;
        }

        .modal__footer {
          padding: 4px 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &__window {
    width: 90vw;
    height: 90vw;
    max-width: 460px;
    max-height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: fade-out($bg-black-color, 0.3);
    box-shadow: 0 0 3px $shadow-color;

    @media (orientation: landscape) {
      width: 80vh;
      height: 80vh;
      min-width: 300px;
    }

    @media (orientation: portrait) {
      min-height: 300px;
    }
  }

  &__header {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    font-family: $second-font-family;
    text-transform: uppercase;
    color: transparent;
    background: $bg-white-color -webkit-gradient(linear, left top, left bottom, color-stop(60%, $null-color), to(fade-out($bg-black-color, 0.25)));
    background: $bg-white-color
      linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 0 fade-out($bg-black-color, 0.25));
  }

  &__content {
    flex: 1 0;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color;
    font-family: $base-font-family;
    background-image: radial-gradient(
      ellipse,
      fade-out($bg-bright-color, 0.25) 0%,
      $null-color 50%
    );
    padding: 25px 0;

    &:not(&--row) {
      flex-direction: column;
    }
  }

  &__key {
    text-transform: uppercase;
  }

  &__value {
    font-size: 42px;
    color: transparent;
    background: $bg-white-color -webkit-gradient(linear, left top, left bottom, color-stop(60%, $null-color), to(fade-out($bg-black-color, 0.25)));
    background: $bg-white-color
      linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  &__footer {
    $btn-offset: 16px;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 $btn-offset;
    margin-bottom: $btn-offset;
  }

  &__button {
    @media (max-width: 1024px) {
      width: 128px;
      height: 58px;
      font-size: $base-font-size;
    }

    @media (min-width: 1025px) {
      width: 194px;
      height: 88px;
      line-height: 30px;
    }

    @media (orientation: portrait) and (max-width: 345px) {
      width: 120px;
      height: 54px;
    }
  }

  @media (orientation: landscape) and (max-height: 345px) {
    &__header {
      font-size: 40px;
    }

    &__content {
      font-size: 26px;
    }

    &__value {
      font-size: 32px;
    }

    &__button {
      width: 120px;
      height: 54px;
      font-size: $font-md-size;
    }
  }
}
</style>
