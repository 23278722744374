import * as api from "../../api";
import { errorMessageMutations, errorMessageState } from "../mixins/error-message";

/** State */
const state = {
  ...errorMessageState,

  params: null,
};

/** Actions */
const actions = {
  async fetchParams({ commit }) {
    try {
      const params = await api.lobby.fetchParams();
      commit("setParams", params);
      if (state.error !== null) {
        commit("clearError");
      }
    } catch (error) {
      if (state.params !== null) {
        commit("clearParams");
      }
      commit("setError", error.message);
    }
  },

  async fetchSsUpdate({ commit }, amount) {
    try {
      return await api.lobby.fetchSweepstakesUpdate(amount);
    } catch (error) {
      commit("setError", error.message);
    }
  },
};

/** Mutations */
const mutations = {
  ...errorMessageMutations,

  setParams(state, params) {
    state.params = params;
  },

  clearParams(state) {
    state.params = null;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
