var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isJackpotsShown)?_c('div',{class:[
    'jackpots',
    {
      'jackpots--compact': _vm.compact,
      'jackpots--float': _vm.float,
      'jackpots--visible': _vm.float && _vm.isVisible,
    },
  ]},[(_vm.hasUser)?_c('div',{staticClass:"jackpots__container"},_vm._l((_vm.JACKPOTS),function(jackpot){return _c('Jackpot',{key:jackpot.name,ref:_vm.getRefName(jackpot.code),refInFor:true,attrs:{"name":jackpot.name,"currency":_vm.currency,"value":_vm.getJackpotByIndex(jackpot.code),"is-community":_vm.getIsCommunityByIndex(jackpot.code),"jackpot-class":"jackpots__jackpot"},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('div',{staticClass:"jackpots__border",attrs:{"role":"presentation"}}),_c('div',{staticClass:"jackpots__overlay",attrs:{"role":"presentation"}}),_c('div',{staticClass:"jackpots__background",attrs:{"role":"presentation"}})]},proxy:true}],null,true)})}),1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }