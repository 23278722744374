import Papa from "papaparse";

/**
 * @param input
 * @return {Promise<unknown>}
 * @description Parse the localization csv file.
 */
export function parseCSV(input) {
  return new Promise((resolve) => {
    const output = Papa.parse(input, {
      header: true,
      delimiter: ",",
      skipEmptyLines: true,
    });
    resolve(output.data);
  });
}

/**
 * @param {string} streamId
 * @return {string}
 * @description Get Jackpots url by streamId
 * @example
 * wss://sample.com/ws/?stream=XXX
 */
export function getJackpotsApiUrl(streamId) {
  const loc = window.location;
  return (
    (loc.hostname !== "localhost"
      ? (loc.protocol === "https:" ? "wss:" : "ws:") + "//" + loc.host
      : process.env.VUE_APP_WSS_URL) + `/ws/?stream=${streamId}`
  );
}

/**
 * @param {(string, number)} gameId
 * @return {string}
 * @description Get image url by game id.
 */
export function getGameImageUrl(gameId) {
  return `/lobbyGamePicImg/default/${gameId}.jpg`;
}

/**
 * @param sourceUrl
 * @return {boolean}
 * @description Is image cached?!
 */
export function isImageCached(sourceUrl) {
  const image = new Image();
  image.src = sourceUrl;
  const isCached = image.complete;
  image.src = "";
  return isCached;
}

/**
 * @param {number} duration
 * @return {Promise}
 * @description Create Promise with delay
 */
export function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

/**
 * @param {Array} array
 * @return {boolean}
 * @description Isn't empty array?!
 */
export function notEmptyArray(array) {
  return Array.isArray(array) && array.length > 0;
}

/**
 * @param value env variable
 * @return {boolean}
 * @description Convert value to boolean value.
 */
export function getBoolean(value) {
  return value ? !!JSON.parse(value) : !!value;
}

export function getTheme() {
  return process.env.VUE_APP_THEME;
}
