<template>
  <div class="brands" :data-count="brands.length">
    <v-button
      variant="prev"
      label="Previous"
      class="brands__arrow brands__arrow--left"
      @click="handleLeftArrowClick"
    />

    <div :key="brandListKey" class="brands__items swiper-container">
      <div class="swiper-wrapper" role="list">
        <brand-button
          variant="brand"
          class="brands__item swiper-slide"
          :caption="allBrands"
          :description="getBrandDescription()"
          role="listitem"
        />

        <template v-for="brand in brands">
          <brand-button
            v-if="countOf.brands[brand.id] > 0"
            :key="brand.id"
            variant="brand"
            class="brands__item swiper-slide"
            :caption="brand.name"
            :description="getBrandDescription(brand.id)"
            :data-brand-id="brand.id"
            role="listitem"
          />
        </template>
      </div>
    </div>

    <v-button
      variant="next"
      label="Next"
      class="brands__arrow brands__arrow--right"
      @click="handleRightArrowClick"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { debounce, isEqual } from "lodash-es";

// Mixins
import gameFilter from "@/mixins/game-filter";

// Constants
import { DEBOUNCE_SLIDER_DELAY } from "@/constants/app";

// Vendors
import Swiper from "swiper";
// import "swiper/css/swiper.min.css";

// Components
import { BrandButton, VButton } from "@/components/Helpers/Buttons";

// Helpers
const DEFAULT_SLIDE_INDEX = 0;

export default {
  name: "Brands",

  components: {
    BrandButton,
    VButton,
  },

  mixins: [gameFilter],

  data() {
    return {
      brandListKey: 0,
      prevBrands: [], // Used to prevent re-rendering of brands when they are not changed
      swiper: null,
    };
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),

    ...mapGetters({
      countOf: "games/countOf",
      translate: "l10n/translate",
      language: "l10n/currentLang",
    }),

    allBrands() {
      return this.translate("all-brands").toUpperCase();
    },

    /**
     * @returns {{slide: {}, brand: {}}}
     * @description Calculate indexes of slides & brands.
     */
    indexOf() {
      const index = { slide: {}, brand: {} };
      let indexOfSlide = 0;
      for (let i = 0; i < this.brands.length; i++) {
        if (this.countOf.brands[this.brands[i].id] > 0) {
          indexOfSlide++;
          index.slide[this.brands[i].id] = indexOfSlide;
          index.brand[indexOfSlide] = i;
        }
      }
      return index;
    },

    isScrolled() {
      return this.swiper && this.swiper.realIndex > 0;
    },
  },

  watch: {
    async currentProviderId() {
      // ↓ Await updates...
      await this.$nextTick();

      this.slideToDefault();
    },

    isMobile(newValue, oldValue) {
      if (newValue !== oldValue && this.swiper) {
        // TODO при смене с мобильной версии на десктопную пропадают обработчики события на слайдере
        this.swiper.update();
      }
    },

    language() {
      this.updateDuplicateSlides();
    },

    async currentBrandId() {
      await this.$nextTick();

      this.slideToCurrentBrand();
    },

    brands() {
      if (!isEqual(this.prevBrands, this.brands)) {
        this.prevBrands = this.brands;
        this.render();
      }
    },
  },

  mounted() {
    this.reinitializeSwiper();
    this.prevBrands = this.brands;
  },

  beforeDestroy() {
    this.destroySwiper();
  },

  methods: {
    async render() {
      // Incriminate `brandListKey` & run update of brand list
      ++this.brandListKey;
      // ↓ Await updates...
      await this.$nextTick();
      // Updates swiper

      this.reinitializeSwiper();
    },

    createSwiper() {
      this.swiper = new Swiper(document.querySelector(".brands .swiper-container"), {
        runCallbacksOnInit: false,
        slideToClickedSlide: true,
        setWrapperSize: true,
        centeredSlides: true,
        slidesPerGroup: 1,
        slidesPerView: 2,
        mousewheel: true,
        init: false,
        loop: true,
        breakpoints: {
          480: { slidesPerView: 4 }, // >= 480
          1024: { slidesPerView: 5 }, // >= 1024
        },
      });
      // this.swiper.on("doubleClick", this.slideToDefault);
    },

    destroySwiper() {
      if (this.swiper) {
        this.swiper.destroy(true);
      }
    },

    reinitializeSwiper() {
      this.destroySwiper();

      this.createSwiper();
      this.swiper.on("init", this.slideToCurrentBrand);

      this.swiper.on("sliderMove", this.selectBrandByIndex);

      this.swiper.on("click", this.selectBrandByIndex);

      this.swiper.init();
    },

    /**
     * @description Select brand by slide index with debounce.
     */
    selectBrandByIndex: debounce(function () {
      this.setBrand(this.swiper.realIndex);
    }, DEBOUNCE_SLIDER_DELAY),

    /**
     * @param {(number, null)} brandId
     * @returns {number}
     * @description Get index of slide by brand ID.
     */
    getIndexOfSlide(brandId) {
      return brandId ? this.indexOf.slide[brandId] || DEFAULT_SLIDE_INDEX : DEFAULT_SLIDE_INDEX;
    },

    /**
     * @param {number} slideIndex
     * @returns {(number, undefined)}
     * @description Get brand ID by slide index.
     */
    getBrandId(slideIndex) {
      const brand = this.brands[this.indexOf.brand[slideIndex]];
      return brand ? brand.id : undefined;
    },

    /**
     * @param {number} slideIndex
     * @description Set brand ID by slide index.
     */
    setBrand(slideIndex) {
      this.selectBrand(this.getBrandId(slideIndex), true);
      if (this.isFavoriteFilter) {
        this.setFavorites(false);
      }
    },

    /**
     * @description Update the descriptions for the duplicate slides.
     */
    updateDuplicateSlides() {
      if (this.swiper) {
        const $wrapperEl = this.swiper.$wrapperEl;
        const duplicateClass = `.${this.swiper.params.slideClass}.${this.swiper.params.slideDuplicateClass}`;

        $wrapperEl.children(duplicateClass).each((index, element) => {
          element.lastChild.innerHTML = this.getBrandDescription(element.dataset.brandId);
        });
      }
    },

    slideToDefault() {
      if (this.isScrolled) {
        this.swiper.slideToLoop(DEFAULT_SLIDE_INDEX);
      }
    },

    slideToCurrentBrand() {
      const indexOfSlide = this.getIndexOfSlide(this.currentBrandId);
      if (this.swiper && this.swiper.realIndex !== indexOfSlide) {
        this.swiper.slideToLoop(indexOfSlide);
      }
    },

    handleLeftArrowClick() {
      if (this.swiper) {
        this.swiper.slidePrev();
        this.setBrand(this.swiper.realIndex);
      }
    },

    handleRightArrowClick() {
      if (this.swiper) {
        this.swiper.slideNext();
        this.setBrand(this.swiper.realIndex);
      }
    },

    /**
     * @param {(number, undefined)} brandId
     * @return {string}
     * @description Get brand description.
     */
    getBrandDescription(brandId) {
      return `${this.countOf.brands[brandId] || this.countOf.all} ${this.translate(
        "brands.n-games"
      )}`;
    },
  },
};
</script>

<style lang="scss">
.brands {
  $item: brands__item;
  $prev: swiper-slide-prev;
  $next: swiper-slide-next;
  $active: swiper-slide-active;

  $brands-width: 778px;
  $brands-height: 93px;

  .swiper-container {
    mask-image: linear-gradient(
      to right,
      $null-color 0%,
      $bg-black-color 40%,
      $bg-black-color 60%,
      $null-color 100%
    );
  }

  padding: 0 20px;
  z-index: 100;

  @media (max-width: 1024px) {
    $pagination_size: $m_swiper-p-indent + $m_swiper-p-size + $m_swiper-p-active;

    height: 100%;
    min-width: 100%;
    box-sizing: border-box;
    padding: 0 $pagination_size;
  }

  @media (min-width: 1025px) {
    left: 50%;
    bottom: $footer-2row-height + $footer-border-bottom-width;
    width: $brands-width;
    max-width: 50%;
    height: $brands-height;
    position: absolute;
    background-image: radial-gradient(
      ellipse at top,
      fade-out($bg-black-color, 0.9),
      fade-out($bg-black-color, 0.5) 100%
    );
    transform: translateX(-50%);
  }

  @media (min-width: 1025px) and (max-width: 1279px) {
    width: 430px;
  }

  @media (min-width: 1280px) and (max-width: 1320px) {
    width: 550px;
  }

  &--hidden {
    display: none !important;
  }

  &__items {
    width: 100%;
    height: 100%;
  }

  &__item {
    transition: transform 0.3s;

    @media (min-width: 1025px) {
      &:hover {
        background: radial-gradient(
          ellipse at center,
          fade-out($bg-white-color, 0.75) 0,
          fade-out($bg-white-color, 0.825) 25%,
          $null-color 50%,
          $null-color 100%
        );
      }
    }

    &.swiper-slide {
      $offset: 20px;

      &-prev {
        transform: translateX(-$offset);
      }

      &-active {
        transform: translateX(0);
      }

      &-next {
        transform: translateX($offset);
      }
    }
  }

  &__arrow {
    $padding: 15px;
    $offset: 15px;

    $width: 22px;
    $height: 21px;
    $wrapper_height: $footer-1row-height - $footer-border-top-width - $footer-border-bottom-width;

    top: ($wrapper_height - ($height + $padding * 2)) / 2;
    width: $width;
    height: $height;
    padding: $padding;
    position: absolute;
    box-sizing: content-box;
    z-index: 1000;

    &--left {
      left: $offset;
    }

    &--right {
      right: $offset;
    }
  }

  @media (max-width: 1024px) {
    &__arrow,
    &__button {
      display: none;
    }
  }

  &__name {
    color: $text-color;
    text-align: center;
    font-family: $base-font-family;
    transition: font-size 0.3s;

    @media (orientation: portrait) and (max-width: 1024px) {
      font-size: $font-xm-size;

      .#{$item}.#{$active} & {
        font-size: 46px;
      }
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      .#{$item} & {
        font-size: $font-xs-size;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-xm-size;
      }

      .#{$item}.#{$active} & {
        font-size: 40px;
      }
    }

    @media (min-width: 1025px) {
      .#{$item} & {
        font-size: $font-xxs-size;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-md-size;
      }

      .#{$item}.#{$active} & {
        font-size: 22px;
      }
    }
  }

  &__desc {
    color: gray;
    text-align: center;
    font-family: $base-font-family;
    transition: font-size 0.3s;

    @media (orientation: portrait) and (max-width: 1024px) {
      font-size: $font-sm-size;

      .#{$item}.#{$active} & {
        font-size: $font-xm-size;
      }
    }

    @media (orientation: landscape) and (max-width: 1024px) {
      .#{$item} & {
        font-size: 10px;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-xs-size;
      }

      .#{$item}.#{$active} & {
        font-size: 26px;
      }
    }

    @media (min-width: 1025px) {
      .#{$item} & {
        font-size: $font-xxs-size;
      }

      .#{$item}.#{$prev} &,
      .#{$item}.#{$next} & {
        font-size: $font-xxs-size;
      }

      .#{$item}.#{$active} & {
        font-size: $font-xxs-size;
      }
    }
  }
}
</style>
