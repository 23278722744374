<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox">
    <use :href="link" />
  </svg>
</template>

<script>
import { ID_OF_PATTERN_RECT } from "./SvgRoot";

export default {
  name: "SvgLink",

  props: {
    href: {
      type: String,
      required: true,
    },

    viewBox: {
      type: [String, null],
      default: null,
    },
  },

  data() {
    return {
      useMap: {
        pattern: ID_OF_PATTERN_RECT,
      },
    };
  },

  computed: {
    link() {
      return `#${this.useMap[this.href] ? this.useMap[this.href] : this.href}`;
    },
  },
};
</script>
