import { errorActions, errorGetters, errorMutations, errorState } from "../mixins/error";
import * as api from "../../api";

const DEFAULT_IFRAME_URL = "https://www.google.com/webhp?igu=1&gws_rd=ssl";
const title = document.title;
/** State */
const state = {
  item: null,

  ...errorState,
};

/** Actions */
const actions = {
  ...errorActions,

  async fetch({ commit }) {
    try {
      const user = await api.auth.fetchUser();
      commit("setItem", user);
      if (state.error.message !== null) {
        commit("clearError");
      }
    } catch (error) {
      commit("clearItem");
      commit("setError", error.message);
    }
  },

  async signIn({ commit }, parameters) {
    try {
      const user = await api.auth.signIn(parameters);
      commit("setItem", user);
      commit("clearError");
    } catch (error) {
      commit("clearItem");
      commit("setError", error.message);
    } finally {
      commit("games/clearRawItems", null, { root: true });
      commit("providers/clearRawItems", null, { root: true });
      commit("brands/clearRawItems", null, { root: true });
      commit("tags/clearRawItems", null, { root: true });
      commit("lobby/clearParams", null, { root: true });
    }
  },

  async signOut({ commit }) {
    try {
      await api.auth.signOut();
      commit("app/setIsLoading", true, { root: true });
      commit("clearError");
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("clearItem");
      commit("games/clearRawItems", null, { root: true });
      commit("providers/clearRawItems", null, { root: true });
      commit("brands/clearRawItems", null, { root: true });
      commit("tags/clearRawItems", null, { root: true });
      commit("lobby/clearParams", null, { root: true });
    }
  },
};

/** Getters */
const getters = {
  ...errorGetters,

  userMemberCard(state) {
    return state.item?.memberCard ?? null;
  },

  balance(state) {
    return state.item ? state.item.balance : null;
  },

  sweepstakesBalance(state) {
    if (state.item) {
      return {
        entries: state.item?.balanceBet,
        totalWin: state.item?.balanceWin,
      };
    }

    return null;
  },

  hasJackpots(state) {
    return state.item ? state.item?.bankGroup?.jackpots?.enabled : null;
  },

  bonuses(state) {
    return state.item ? state.item?.bankGroup?.bonuses : null;
  },

  bonusWagerCoefficient(state) {
    return state.item ? state.item?.bankGroup?.wagerCoeff : null;
  },

  currency(state) {
    if (!state.item) {
      return null;
    }

    if (state.item.currency !== "NAN") {
      return state.item.currency;
    }

    return "";
  },

  username(state) {
    return state.item ? state.item.displayableName : "";
  },

  hasUser(state) {
    return !!state.item;
  },

  panicState(state) {
    const isPanic = state.item?.lobby?.isPanic;
    const panicUrl = state.item?.lobby?.panicUrl ?? DEFAULT_IFRAME_URL;

    if (isPanic) {
      document.title = "Welcome";
    } else {
      document.title = title;
    }

    return {
      isPanic,
      panicUrl,
    };
  },

  wheelFortuneReadyTimestamp(state) {
    return state.item.bankGroup?.fortuneWheel?.nextWinTime;
  },
  wheelFortuneIsActive(state) {
    return state.item.bankGroup?.fortuneWheel?.isActive;
  },
  wheelFortuneTimeIsNotExpired(state) {
    const time = state.item.bankGroup?.fortuneWheel?.nextWinTime;
    return time > Date.now() / 1000;
  },
};

/** Mutations */
const mutations = {
  ...errorMutations,

  setItem(state, item) {
    state.item = item;
  },

  clearItem(state) {
    state.item = null;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
