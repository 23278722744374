<template>
  <base-modal v-if="isShowModal" :title="_('info')" class="modal" :content="{}">
    <template #body>
      <div class="modal__height-wrapper">
        <div class="modal__static-bonus-info">
          <div
            v-if="hasUser"
            :class="[
              'modal__balance',
              {
                'modal__balance--center': !allowBonusTypes.length && !hasJackpots,
              },
            ]"
          >
            <template v-if="isSweepstakesEnabled">
              <SweepstakesBalance />
            </template>
            <template v-else>
              <div class="row">
                <div class="modal__balance-title">{{ _("balance") }}:</div>
                <div class="modal__balance-value">
                  {{ $f.formatMoney($f.toMoney(balance)) }} {{ currency }}
                </div>
              </div>
            </template>
            <template v-if="isBonusAvailable">
              <template>
                <div class="row">
                  <div class="modal__balance-title">{{ _("Wager") }}:</div>
                  <div class="modal__balance-value">
                    {{ $f.formatMoney($f.toMoney(bonuses.wager)) }}
                    {{ currency }}
                  </div>
                </div>
              </template>
              <template>
                <div class="row">
                  <div class="modal__balance-title">{{ _("info-modal.remaining-wager") }}:</div>
                  <div class="modal__balance-value">
                    {{ $f.formatMoney($f.toMoney(bonuses.remainingWager)) }}
                    {{ currency }}
                  </div>
                </div>
              </template>
              <bonus-table />
            </template>
          </div>
          <div
            v-if="!isSweepstakesEnabled && hasUser && bonus && bonus.remainingWager !== 0"
            class="modal__bonus-balance"
          >
            <div class="modal__bonus-balance-title">{{ _("info-modal.bonus-balance") }}:</div>
            <div class="modal__bonus-balance-value">
              {{ $f.formatMoney($f.toMoney(bonus.amount)) }} {{ currency }}
            </div>
          </div>

          <div
            v-if="!isSweepstakesEnabled && hasUser && bonus && bonus.remainingWager !== 0"
            class="modal__remaining-wager"
          >
            <div class="modal__remaining-wager-title">{{ _("info-modal.remaining-wager") }}:</div>
            <div class="modal__remaining-wager-value">
              {{ $f.formatMoney($f.toMoney(bonus.remainingWager)) }}
              {{ currency }}
            </div>
          </div>
        </div>
        <div v-if="!allowBonusTypes.length && !hasJackpots" class="modal__bonuses-not-found">
          <div class="modal__bonuses-not-found-title">
            {{ _("info-modal.no-bonuses-found") }}
          </div>
        </div>

        <div v-if="hasUser && hasJackpots" class="modal__jackpots">
          <div class="modal__jackpots-title">
            {{ _("info-modal.jackpots-title") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.jackpots-text-1") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.jackpots-text-2") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.jackpots-text-3") }}
          </div>
        </div>

        <div v-if="hasUser && allowBonusTypes.length" class="modal__bonuses">
          <div class="modal__jackpots-title">
            {{ _("info-modal.bonuses-rules-title") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.bonuses-rules-text-1") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.bonuses-rules-text-2") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.bonuses-rules-text-3") }}
          </div>
          <div class="modal__jackpots-text">
            {{ _("info-modal.bonuses-rules-text-4") }}
          </div>
          <br />
          <div class="modal__jackpots-text">
            <b> {{ _("info-modal.note") }}:</b>
            <div>{{ _("info-modal.note-text") }}</div>
          </div>
        </div>

        <div
          v-if="hasUser && allowBonusTypes.length && !isSweepstakesEnabled"
          class="modal__cancellation-bonuses"
        >
          <div class="modal__cancellation-bonuses-title">
            {{ _("info-modal.bonuses-automatic-cancellation-title") }}
          </div>
          <div class="modal__cancellation-bonuses-text">
            {{ _("info-modal.bonuses-automatic-cancellation-text-1") }}
          </div>
          <div class="modal__cancellation-bonuses-text">
            {{ _("info-modal.bonuses-automatic-cancellation-text-2") }}
          </div>
        </div>

        <!--      CASHBACK-->
        <div v-if="allowBonusTypes.includes(BONUS_TYPE.cashback)" class="modal__cashback">
          <div class="modal__cashback-title">Cashback</div>

          <div class="modal__cashback-text">
            {{ _("info-modal.cashback-text-1") }}
            <span class="modal__extra-value"> {{ bonusValuePercent(BONUS_TYPE.cashback) }} % </span>
            {{ _("info-modal.cashback-text-2") }}
          </div>

          <div class="modal__cashback-text">
            {{ _("info-modal.cashback-text-3") }}
            <span class="modal__extra-value">
              {{
                `${bonusThreshold(BONUS_TYPE.cashback)} ${isSweepstakesEnabled ? "" : currency} `
              }}</span
            >
          </div>
          <div v-if="hasWager(BONUS_TYPE.cashback)" class="modal__wager-coeff">
            <div class="modal__wager-coeff-title">{{ _("info-modal.wager-coefficient") }}:</div>
            <div class="modal__wager-coeff-text">
              <span class="modal__extra-value"> {{ bonusWagerCoefficient }}</span>
            </div>
          </div>
          <div
            v-if="bonusCooldown(BONUS_TYPE.cashback) && bonusCooldown(BONUS_TYPE.cashback) !== 0"
            class="modal__cashback-text"
          >
            {{ _("info-modal.cashback-text-4") }}
            <span class="modal__extra-value"> {{ bonusCooldown(BONUS_TYPE.cashback) }}</span>
            <span> {{ _("hours") }}</span>
          </div>
          <div v-if="isHappyHours(BONUS_TYPE.cashback)" class="modal__happy-hours">
            <div class="modal__happy-hours-title-wrapper">
              <div class="modal__happy-hours-title">{{ _("info-modal.happy-hours-title") }}:</div>
              <div class="modal__happy-hours-text">
                {{
                  _("info-modal.happy-hours-time-range")
                    .replace("{start_time}", bonusHappyHoursRange(BONUS_TYPE.cashback).fromHM)
                    .replace("{end_time}", bonusHappyHoursRange(BONUS_TYPE.cashback).toHM)
                }}
              </div>
            </div>
            <div class="modal__happy-hours-text">
              {{ _("info-modal.happy-hours-text-1") }}
              <span class="modal__extra-value"
                >{{ bonusHappyHoursBoostPercent(BONUS_TYPE.cashback) }} %</span
              >
            </div>
          </div>
        </div>

        <!-- BOUNCEBACK-->
        <div v-if="allowBonusTypes.includes(BONUS_TYPE.bounceback)" class="modal__bounceback">
          <div class="modal__bounceback-title">Bounceback</div>
          <div class="modal__bounceback-text">
            {{ _("info-modal.bounceback-text-1") }}
            <span class="modal__extra-value">
              {{ bonusValuePercent(BONUS_TYPE.bounceback) }} %</span
            >
            <span> {{ _("info-modal.bounceback-text-2") }}</span>
          </div>
          <div class="modal__bounceback-text">
            {{ _("info-modal.bounceback-text-3") }}
          </div>
          <div v-if="hasWager(BONUS_TYPE.bounceback)" class="modal__wager-coeff">
            <div class="modal__wager-coeff-title">{{ _("info-modal.wager-coefficient") }}:</div>
            <span class="modal__extra-value"> {{ bonusWagerCoefficient }}</span>
          </div>
          <div
            v-if="
              bonusCooldown(BONUS_TYPE.bounceback) && bonusCooldown(BONUS_TYPE.bounceback) !== 0
            "
            class="modal__bounceback-text"
          >
            {{ _("info-modal.bounceback-text-4") }}
            <span class="modal__extra-value"> {{ bonusCooldown(BONUS_TYPE.bounceback) }}</span>
            <span> {{ _("hours") }}</span>
          </div>
          <div v-if="isHappyHours(BONUS_TYPE.bounceback)" class="modal__happy-hours">
            <div class="modal__happy-hours-title-wrapper">
              <div class="modal__happy-hours-title">{{ _("info-modal.happy-hours-title") }}:</div>
              <div class="modal__happy-hours-text">
                {{
                  _("info-modal.happy-hours-time-range")
                    .replace("{start_time}", bonusHappyHoursRange(BONUS_TYPE.bounceback).fromHM)
                    .replace("{end_time}", bonusHappyHoursRange(BONUS_TYPE.bounceback).toHM)
                }}
              </div>
            </div>
            <div class="modal__happy-hours-text">
              {{ _("info-modal.happy-hours-text-1") }}
              <span class="modal__extra-value"
                >{{ bonusHappyHoursBoostPercent(BONUS_TYPE.bounceback) }} %</span
              >
            </div>
          </div>
        </div>

        <!-- LAST CHANCE-->
        <div v-if="allowBonusTypes.includes(BONUS_TYPE.lastChance)" class="modal__last-chance">
          <div class="modal__last-chance-title">Last Chance</div>
          <div class="modal__last-chance-text">
            {{ _("info-modal.last-chance-text-1") }}
            <span class="modal__extra-value">
              {{ bonusValuePercent(BONUS_TYPE.lastChance) }} %</span
            >
            {{ _("info-modal.last-chance-text-2") }}

            <div class="modal__last-chance-text">
              {{ _("info-modal.last-chance-text-3") }}
              <span class="modal__extra-value">
                {{ bonusThreshold(BONUS_TYPE.lastChance) + " " + currency }}</span
              >
            </div>
          </div>
          <div v-if="hasWager(BONUS_TYPE.lastChance)" class="modal__wager-coeff">
            <div class="modal__wager-coeff-title">{{ _("info-modal.wager-coefficient") }}:</div>
            <span class="modal__extra-value">{{ bonusWagerCoefficient }}</span>
          </div>
          <div
            v-if="
              bonusCooldown(BONUS_TYPE.lastChance) && bonusCooldown(BONUS_TYPE.lastChance) !== 0
            "
            class="modal__last-chance-text"
          >
            {{ _("info-modal.last-chance-text-4") }}
            <span class="modal__extra-value"> {{ bonusCooldown(BONUS_TYPE.lastChance) }}</span>
            <span> {{ _("hours") }}</span>
          </div>
        </div>
        <NoJpWagerSection />
      </div>
    </template>
    <template #footer>
      <v-button class="back-button" variant="accept" has-texture @click="onClose">
        {{ _("session.button-text") }}
      </v-button>
    </template>
  </base-modal>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons";
import BaseModal from "./BaseModal";
import { mapGetters, mapState } from "vuex";
import { get, upperFirst } from "lodash-es";
import SweepstakesBalance from "@/components/SweestakesBalance/SweepstakesBalance.vue";
import BonusTable from "@/components/AcceptedBonusTable/bonus-table.vue";
import bonusMixin from "@/components/AcceptedBonusTable/api/use-bonuses";
import NoJpWagerSection from "@/components/Modal/NoJpWagerSection.vue";

const BONUS_TYPE = {
  cashback: "cashback",
  bounceback: "bounceback",
  lastChance: "lastchance",
};
export default {
  name: "InfoModal",
  components: {
    NoJpWagerSection,
    BonusTable,
    SweepstakesBalance,
    VButton,
    BaseModal,
  },
  mixins: [bonusMixin],

  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },

    onClose: {
      type: Function,
      default: () => undefined,
    },
  },

  data() {
    return {
      BONUS_TYPE,
    };
  },
  computed: {
    bonuses() {
      return {
        wager: this.wager,
        remainingWager: this.remainingWager,
      };
    },
    ...mapGetters({
      _: "l10n/translate",
      balance: "user/balance",
      currency: "user/currency",
      hasUser: "user/hasUser",
      hasJackpots: "user/hasJackpots",
      userBonuses: "user/bonuses",
      bonusWagerCoefficient: "user/bonusWagerCoefficient",
    }),
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
      user: (state) => state.user,
      bonus: (state) => {
        const { item } = state.bonus;
        if (!item || !item.bonus || item.available) {
          return null;
        }

        return {
          remainingWager: item?.remainingWager,
          name: upperFirst(item?.bonus?.stringId),
          amount: item?.bonusAmount,
          type: item?.bonus?.stringId,
        };
      },
    }),

    allowBonusTypes() {
      const bonusesAllow = [];

      if (this.userBonuses?.cashback) {
        bonusesAllow.push(BONUS_TYPE.cashback);
      }

      if (this.userBonuses?.bounceback) {
        bonusesAllow.push(BONUS_TYPE.bounceback);
      }

      if (this.userBonuses?.lastchance) {
        bonusesAllow.push(BONUS_TYPE.lastChance);
      }

      return bonusesAllow;
    },
  },

  methods: {
    hasWager(type) {
      if (this.isSweepstakesEnabled) {
        return false;
      }
      return this.userBonuses[type]?.wager ?? false;
    },

    bonusValuePercent(type) {
      const { value } = this.userBonuses[type];
      return value ?? 0;
    },

    isHappyHours(type) {
      return this.userBonuses[type]?.happyHours?.enabled;
    },

    bonusCooldown(type) {
      return this.userBonuses[type]?.cooldown || 0;
    },

    bonusHappyHoursBoostPercent(type) {
      return this.userBonuses[type]?.happyHours?.boost || 0;
    },

    bonusHappyHoursRange(type) {
      const timeZoneOffsetHours = new Date().getTimezoneOffset() / 60 || 0;
      const from = new Date(this.userBonuses[type]?.happyHours?.from) ?? new Date();

      const to = new Date(this.userBonuses[type]?.happyHours?.to) ?? new Date();

      to.setHours(to.getHours() - timeZoneOffsetHours);
      from.setHours(from.getHours() - timeZoneOffsetHours);

      return {
        fromHM: from.toLocaleTimeString(),
        toHM: to.toLocaleTimeString(),
      };
    },

    bonusThreshold(type) {
      const { threshold } = this.userBonuses[type];
      return isNaN(threshold / 100) ? 0 : threshold / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  margin: 4px 0;
}

.modal {
  &__height-wrapper {
    max-height: calc(100% - 50px);
    overflow-y: scroll;
    width: 100%;
    margin-bottom: auto;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 6px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar:horizontal {
      height: 12px;
      background: $btn-primary-color;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: $btn-primary-color;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $btn-primary-color;
    }
  }

  &__jackpots {
    margin-bottom: 40px;
  }

  &__extra-value {
    font-size: 16px;
    font-weight: bold;
  }

  &__bonuses-not-found {
    text-align: center;
  }

  &__jackpots-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__static-bonus-info {
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      * div {
        font-size: 16px;
      }
    }
  }

  &__jackpots-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__bonuses {
    margin-bottom: 40px;
  }

  &__bonuses-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__bonuses-text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__cancellation-bonuses {
    margin-bottom: 40px;
  }

  &__cancellation-bonuses-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__cancellation-bonuses-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__cashback {
    margin-bottom: 40px;
  }

  &__cashback-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__cashback-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__happy-hours {
    margin-top: 40px;
  }

  &__happy-hours-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 4px;
  }

  &__happy-hours-title-wrapper {
    display: flex;
  }

  &__happy-hours-text {
    font-size: 14px;
    line-height: 1.7;
  }

  &__wager-coeff {
    display: flex;
    align-items: center;
  }

  &__wager-coeff-title {
    font-size: 14px;
    margin-right: 4px;
  }

  &__bounceback {
    margin-bottom: 40px;
  }

  &__bounceback-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__bounceback-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__last-chance {
    margin-bottom: 20px;
  }

  &__last-chance-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__last-chance-text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__current-bonus-type {
    display: flex;
  }

  &__current-bonus-type-title {
    font-size: 18px;
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__current-bonus-type-text {
    font-size: 18px;
    margin-left: 4px;
    color: $text-main-color;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__balance {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;

    &--center {
      justify-content: center;
    }
  }

  &__bonus-balance,
  &__remaining-wager {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &__remaining-wager-value,
  &__bonus-balance-value,
  &__balance-value {
    margin-left: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  &__remaining-wager-title,
  &__bonus-balance-title,
  &__balance-title {
    font-size: 18px;
  }
}

:deep .modal {
  width: 60%;
  overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
    max-height: inherit;
  }
}

:deep .modal__content {
  background: none;
  overflow-y: hidden;
  padding: 5px 0;
  border: none;
  resize: none;
  min-width: 420px;
  color: white;
  font-size: $font-sm-size;
  font-family: $base-font-family, $fallback-font-family;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 760px) {
    min-width: auto;
    padding: 10px 10px;
    text-align: left;
  }
}

:deep .modal__footer {
  padding: 0;
  margin-bottom: 0;

  @media screen and (max-width: 760px) {
    padding: 0;
    margin-bottom: 12px;
  }
}

:deep .modal__window {
  max-width: 700px;
  max-height: 800px;
  padding: 40px;
  //overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: none;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 100%;
  }
}

:deep .modal__header {
  font-style: italic;
}

:deep .modal__content {
  align-items: flex-start;
}

:deep .modal__body {
  font-size: 24px;
  font-family: Ubuntu, sans-serif;
  color: #fff;

  p {
    padding: 10px;
    line-height: 1.8px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.back-button {
  width: 190px;
  height: 40px;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  margin: 0 auto;

  @media screen and (max-width: 720px) {
    width: 240px;
    margin-top: -26px;
  }
}

@keyframes blue-neon {
  0%,
  100% {
    text-shadow: 0 0 1px $shadow-color, 0 0 3px $shadow-color, 0 0 10px $shadow-color,
      0 0 10px $shadow-color, 0 0 0.4px $shadow-lighten-color, 0.5px 0.5px 0.1px $shadow-dim-color;
    color: $text-light-color;
  }
  50% {
    text-shadow: 0 0 0.5px $shadow-dark-color, 0 0 1.5px $shadow-dark-color,
      0 0 5px $shadow-dark-color, 0 0 5px $shadow-dark-color, 0 0 0.2px $shadow-dark-color,
      0.5px 0.5px 0.1px $shadow-darkDim-color;
    color: $text-dim-color;
  }
}
</style>
