var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-button',{staticClass:"button",attrs:{"variant":"icon"},on:{"click":_vm.toggleFavourite}},[_c('v-icon',_vm._b({class:[
      'button__star',
      _vm.iconProps.class,
      { 'button--active-static': _vm.isActiveButton },
      { 'button--inactive': !_vm.isActiveButton },
      { 'button--active': _vm.isActiveClass && _vm.isActiveButton },
    ],attrs:{"name":_vm.variant}},'v-icon',_vm.iconProps,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }