import { call } from "./client";
import { getLangFromStorage } from "@/api/language";

const TERM_OF_USE_MODAL_KEY = "is-tou-modal-visible";
/**
 * @typedef {Object} Session
 * @property {(string, null)} id
 * @property {(string, null)} url
 */

/**
 * @typedef {Object} SessionParams
 * @property {string} game string id
 * @property {boolean} demo
 * @property {string} demo_balance 1 000
 * @property {("desktop", "mobile", "terminal")} device for BTM provider
 */

/**
 * @param {SessionParams} parameters
 * @returns {Promise<Session>}
 */
export async function createSession(parameters) {
  try {
    const response = await call("Game.run", {
      ...parameters,
      lang: getLangFromStorage(),
    });

    return {
      id: response.sessionId,
      url: response.link,
    };
  } catch (error) {
    return {
      id: null,
      url: null,
    };
  }
}

export const getSessionModalTermOFUseState = () => {
  return JSON.parse(window.localStorage.getItem(TERM_OF_USE_MODAL_KEY)) ?? true;
};

export const setSessionModalTermOFUseVisible = (value) => {
  window.localStorage.setItem(TERM_OF_USE_MODAL_KEY, value);
};
