<template>
  <div class="wheel">
    <div class="wheel__wheel-wrapper">
      <div :class="['wheel__svg-outer', { 'wheel__svg-outer--animation-active': isSpinning }]" />
      <div class="wheel__spin-button">
        <SpinButton :is-spinning="isSpinning" :on-click="spin" />
      </div>
      <svg
        class="wheel__svg"
        :viewBox="`0 0 ${wheelOptions.diameter} ${wheelOptions.diameter}`"
        :width="wheelOptions.diameter"
        :height="wheelOptions.diameter"
      >
        <g ref="wheel" style="transform: translateZ(0px)" class="sector__groups">
          <g
            v-for="(item, index) in sectors"
            :key="Math.floor(item.coordsBorder.x1 * index)"
            class="sector"
          >
            <path
              :class="['sector__figure', { 'sector__figure--spin': isSpinning }]"
              :d="item.coordsSector"
              :fill="`url(#gradient-section-${index})`"
              stroke="url(#gradient-border)"
              fill-rule="evenodd"
              clip-rule="evenodd"
              stroke-linejoin="round"
              stroke-linecap="round"
            ></path>
            <rect
              :x="item.coordsLabel.x"
              :y="item.coordsLabel.y"
              fill-rule="evenodd"
              class="sector__figure"
            />
            <g class="sector__label" :style="item.coordsLabel.style">
              <text text-anchor="middle" :x="item.coordsLabel.x" :y="item.coordsLabel.y - 40">
                <!-- prettier-ignore -->
                <tspan
                  v-for="(_, j) of Array.from(String(item.value))"
                  :key="j"
                  :dy="35"
                  :x="item.coordsLabel.x"
                >{{ Array.from(String(item.value))[j] }}
                </tspan>
              </text>
            </g>

            <defs>
              <radialGradient :id="`gradient-section-${index}`" gradientUnits="userSpaceOnUse">
                <stop offset="0%" :stop-color="item.color" />
                <stop offset="50%" :stop-color="item.color" />
                <stop offset="80%" :stop-color="item.color" />
                <stop offset="100%" stop-color="#1E1E1E" />
              </radialGradient>
            </defs>
          </g>
        </g>
        <defs>
          <linearGradient id="gradient-border" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFF3A6" />
            <stop offset="0.08" stop-color="#F9DF7B" />
            <stop offset="0.16" stop-color="#E0BC54" />
            <stop offset="0.25" stop-color="#CDA136" />
            <stop offset="0.33" stop-color="#BF8D21" />
            <stop offset="0.41" stop-color="#B78114" />
            <stop offset="0.49" stop-color="#B57E10" />
            <stop offset="0.56" stop-color="#B78114" />
            <stop offset="0.63" stop-color="#BF8D20" />
            <stop offset="0.71" stop-color="#CCA035" />
            <stop offset="0.78" stop-color="#DFBA52" />
            <stop offset="0.86" stop-color="#F9DF7B" />
            <stop offset="1" stop-color="#FFF3A6" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import { fetchFortuneWheelSettings, fetchFortuneWheelWin } from "../api";
import SpinButton from "./SpinButton.vue";
import { Howl } from "howler";
import coinSound from "@/assets/sounds/coin.mp3";
import wheelSound from "@/assets/sounds/wheel.mp3";
import congratulationsSound from "@/assets/sounds/congratulations.mp3";

const WHEEL_SECTOR_COLOR = ["#35ACE8", "#BE1309", "#91D10E", "#F2C903", "#FF8405", "#EB4AA1"];
const WHEEL_SPIN_DURATION = 10000;
const WHEEL_SIZE = 688;
const SECTORS_REPEAT_COUNT = 4;

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min)) + min;
};

export default {
  name: "WheelFortuneComponent",
  components: {
    SpinButton,
  },
  props: {
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      isSpinning: false,
      currentAngle: 0,
      sectors: [],
      sounds: null,
    };
  },

  computed: {
    wheelOptions() {
      const diameter = WHEEL_SIZE;
      const center = WHEEL_SIZE / 2;

      return {
        diameter,
        radius: center - 2,
        cx: center,
        cy: center,
      };
    },
  },

  destroyed() {
    this.stopSound();
  },

  async beforeMount() {
    const wheelSettings = await fetchFortuneWheelSettings();

    if (!wheelSettings) {
      return;
    }

    this.sectors = this.wheelSectorSettings(wheelSettings?.sectors);
  },

  async mounted() {
    this.initSounds();

    anime.set(this.$refs.wheel, {
      rotate: this.currentAngle,
    });
  },

  methods: {
    sectorSize(sectorsLength) {
      return 360 / sectorsLength;
    },

    initSounds() {
      const createPath = (fileName) => window.TEMPLATE_PATH + fileName;
      this.sounds = {
        coin: new Howl({ src: [createPath(coinSound)] }),
        wheel: new Howl({ src: [createPath(wheelSound)] }),
        congratulations: new Howl({ src: [createPath(congratulationsSound)] }),
      };
    },

    stopSound() {
      Object.values(this.sounds).forEach((s) => s.stop());
    },

    wheelSectorSettings(wheelSettings) {
      const settings = [...wheelSettings].map((sectorItem, index) => {
        return {
          ...sectorItem,
          color: WHEEL_SECTOR_COLOR[index],
          value: sectorItem.value / 100,
        };
      });

      const repeatedSectors = Array(SECTORS_REPEAT_COUNT).fill(settings).flat();

      return repeatedSectors.map((item, index) => {
        return {
          ...item,
          coordsSector: this.describeSector(index, repeatedSectors.length).sector,
          coordsBorder: this.describeSector(index, repeatedSectors.length).border,
          coordsLabel: this.describeLabel(index, repeatedSectors.length),
        };
      });
    },

    describeSector(index, sectorsLength) {
      const { cx, cy, radius } = this.wheelOptions;

      const startAngle =
        index * this.sectorSize(sectorsLength) - this.sectorSize(sectorsLength) / 2;
      const endAngle = startAngle + this.sectorSize(sectorsLength);

      const start = polarToCartesian(cx, cy, radius, endAngle);
      const end = polarToCartesian(cx, cy, radius, startAngle);

      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      return {
        sector: [
          "M",
          start.x,
          start.y,
          "A",
          radius,
          radius,
          0,
          largeArcFlag,
          0,
          end.x,
          end.y,
          "L",
          cx,
          cy,
          "Z",
        ].join(" "),
        border: {
          x1: start.x,
          x2: end.x,
          y1: start.y,
          y2: end.y,
        },
      };
    },

    describeLabel(index, sectorsLength) {
      const { cx, cy, radius } = this.wheelOptions;

      const angle = index * this.sectorSize(sectorsLength);
      const offset = 70;

      const position = polarToCartesian(cx, cy, radius - offset, angle);
      const style = `
        transform-origin: ${position.x}px ${position.y}px;
        transform: rotate(${angle}deg);
        `;

      return { ...position, angle, style };
    },
    async spin() {
      const { currentAngle } = this;

      const win = await fetchFortuneWheelWin();

      if (!win) {
        return;
      }

      const awardIndex = win?.sectorId - 1; // Индекс победного сектора с 0

      // Рандомное смещение угла выигрыша относительно победной секции
      const awardRandomOffset = getRandomInt(
        (this.sectorSize(this.sectors.length) / 2) * -1,
        this.sectorSize(this.sectors.length) / 2
      );

      // Если 6 секций, круг = 360 градусов, 1 секция = 60 градусов, для победы 2 сектора нужен угол от 61 до 119
      const awardAngleCenter = awardIndex * this.sectorSize(this.sectors.length);

      const turns = 10 * 360; // Скорость вращения
      const targetAngleUnvalidated = turns + awardAngleCenter; // Обороты + угол выигрышного сектора

      //Градусы вращения с конечным углом в центре выигрышной секции
      const targetAngle =
        Math.abs(awardAngleCenter) > Math.abs(currentAngle)
          ? targetAngleUnvalidated
          : targetAngleUnvalidated + 360;

      //Фактический угол выигрышной секции без сдвига в центр(рандом)
      const targetAngleOffset = targetAngle + awardRandomOffset;

      this.isSpinning = true;
      this.sounds.wheel.play();

      await anime({
        targets: this.$refs.wheel,
        duration: WHEEL_SPIN_DURATION,
        rotate: -targetAngleOffset,
        easing: "easeOutCirc",
      }).finished;

      await anime({
        targets: this.$refs.wheel,
        delay: WHEEL_SPIN_DURATION / 1000,
        rotate: -targetAngle,
      }).finished;

      this.$store.dispatch("user/fetch");

      this.currentAngle = -targetAngle % 360; // Смещение в центр победной секции

      anime.set(this.$refs.wheel, {
        rotate: this.currentAngle,
      });

      this.isSpinning = false;
      this.onClick(this.sectors[awardIndex].value);
      this.sounds.congratulations.play();
      this.sounds.coin.play();
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-family: $base-font-family;
}

.wheel {
  display: flex;
  align-items: center;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    &__outer-wrapper {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__outer-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__wheel-wrapper {
    position: relative;
    display: flex;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(52, 73, 94, 0.25);
    transform: translate3d(0, 0, 0);
  }

  &__spin-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__svg-outer {
    position: absolute;
    background-image: url("../assets/images/fortune-wheel.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 770px;
    top: -59px;
    left: -40px;
    bottom: 0;

    &--animation-active {
      &:before {
        content: "";
        position: absolute;
        top: 20px;
        left: 40px;
        width: 700px;
        height: 668px;
        border-radius: 50%;
      }

      &:before {
        box-shadow: 0 0 27px #0f0, inset 0 0 27px #0f0;
        animation: move 6s linear infinite;
      }
    }

    @keyframes move {
      0% {
        box-shadow: 0 0 27px #0f0, inset 0 0 27px #0f0;
        filter: hue-rotate(0deg);
      }
      20% {
        box-shadow: 0 0 60px #0f0, inset 0 0 60px #0f0;
      }

      40% {
        box-shadow: 0 0 40px #0f0, inset 0 0 40px #0f0;
      }
      60% {
        box-shadow: 0 0 80px #0f0, inset 0 0 80px #0f0;
      }
      80% {
        box-shadow: 0 0 100px #0f0, inset 0 0 100px #0f0;
      }
      100% {
        box-shadow: 0 0 27px #0f0, inset 0 0 27px #0f0;
        filter: hue-rotate(360deg);
      }
    }
  }
}

.sector {
  stroke-width: 3px;

  &__figure {
    &--spin {
      @media screen and (max-width: 1024px) {
        stroke: rgba(202, 202, 45, 0.93);
      }
    }
  }

  &__groups {
    transform-origin: center center;
    transform-box: fill-box;
  }

  &__label {
    font-family: $second-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -4px;
    text-transform: uppercase;
    transform-origin: center;
    text-anchor: middle;
    fill: white;
    text-shadow: 2px 2px 4px black;

    @media screen and (max-width: 1024px) {
      text-shadow: 4px 4px 8px black;
    }
  }
}
</style>
