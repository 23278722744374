<template>
  <component
    :is="tagName"
    :class="{
      [variantMap[variant]]: variant,
      [activeClass]: isActive,
      'btn--is-loading': isLoading,
      'btn--shadow': settings.hasShadow,
      'btn--shine': settings.hasShine,
      'btn--mobile': settings.hasShine,
      'btn--error': hasErrors,
      [disabledClass]: disabled || isLoading,
    }"
    :aria-label="ariaLabel || label"
    :disabled="isButton && (disabled || isLoading)"
    :role="currentRole"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div :class="`${brandClass}__name`">{{ caption }}</div>
    <div :class="`${brandClass}__desc`">{{ description }}</div>
  </component>
</template>

<script>
import VButton from "./VButton";

export default {
  name: "BrandButton",

  extends: VButton,

  props: {
    caption: {
      type: [String, null],
      default: null,
    },

    brandClass: {
      type: String,
      default: "brands",
    },

    description: {
      type: String,
      default: "",
    },
  },
};
</script>
