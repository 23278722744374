<template>
  <base-modal v-if="isVisible" :title="textState.title" class="modal" :content="{}">
    <template #body>
      <span class="modal__description"> {{ textState.text }}</span>
    </template>
    <template #footer>
      <v-button class="back-button" variant="cancel" has-texture @click="closeModal">
        Close
      </v-button>
    </template>
  </base-modal>
</template>

<script>
import { VButton } from "@/components/Helpers/Buttons";
import BaseModal from "./BaseModal";
import routeMixin from "@/mixins/route";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SuccessOrErrorModal",
  components: {
    VButton,
    BaseModal,
  },
  mixins: [routeMixin],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => undefined,
    },
    isErrorResponse: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    textState() {
      return {
        title: this.successOrErrorModalStateError ? "Error" : "Success",
        text: this.successOrErrorModalStateError ? "Try Again" : "Time successfully bought",
      };
    },
    ...mapGetters({
      _: "l10n/translate",
      successOrErrorModalStateError: "ui/successOrErrorModalStateError",
    }),
  },
  methods: {
    ...mapMutations({
      handleCloseModal: "ui/hideSuccessOrErrorModal",
    }),
    closeModal() {
      this.handleCloseModal();
      this.onClose?.();
    },
    ...mapActions({
      signOut: "user/signOut",
    }),
  },
};
</script>

<style lang="scss" scoped>
.modal {
  :deep .modal {
    width: 60%;
    overflow-y: hidden;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
    }
  }

  :deep .modal__content {
    max-height: 80%;
    background: none;
  }

  :deep .modal__window {
    max-width: 400px;
    max-height: 400px;
    padding: 40px;
    overflow-y: scroll;

    @media screen and (max-width: 1024px) {
      max-width: 600px;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-shadow: none;
    }

    @media screen and (max-width: 760px) {
      width: 100%;
      max-height: 100%;
    }
  }

  :deep .modal__header {
    font-style: italic;
  }

  :deep .modal__body {
    font-size: 24px;
    font-family: Ubuntu, sans-serif;
    color: #fff;

    p {
      padding: 10px;
      line-height: 1.8px;
    }

    @media screen and (max-width: 1024px) {
      max-height: 475px;
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 565px) {
    .back-button + .back-button {
      margin-left: 18px;
    }
  }

  @media screen and (orientation: landscape) {
    :deep .modal__content {
      max-height: 50%;
      background: none;
    }

    :deep .modal__window {
      overflow: hidden;
    }
  }

  .back-button {
    width: 190px;
    height: 40px;
    font-size: 16px;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    margin: 0 auto;

    @media screen and (max-width: 720px) {
      width: 240px;
      margin-top: -26px;
    }
  }

  &__description {
    text-transform: uppercase;
    font-size: 16px;
  }
}
</style>
