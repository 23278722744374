import { call } from "@/api/client";

export async function fetchFortuneWheelSettings() {
  try {
    const response = await call("FortuneWheel.getSettings");
    return response.settings;
  } catch (error) {
    return null;
  }
}

export async function fetchFortuneWheelWin() {
  try {
    return await call("FortuneWheel.getWin");
  } catch (error) {
    return null;
  }
}
