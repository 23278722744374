import { get } from "lodash-es";

export const JackpotsArray = [
  { name: "Ultimate", code: 3 },
  { name: "Grand", code: 2 },
  { name: "Major", code: 1 },
  { name: "Mini", code: 0 },
];

export default {
  data() {
    return {
      JACKPOTS: JackpotsArray,
    };
  },

  methods: {
    getJackpotByIndex(index) {
      return get(this.jackpots, `[${index}].value`, "N/A");
    },

    getIsCommunityByIndex(index) {
      return get(this.jackpots, `[${index}].isCommunity`, true);
    },
  },
};
