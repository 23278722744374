interface FilterParams {
  [key: string]: string | number | boolean | null | undefined;
  brand?: number;
  provider?: number;
  tag?: string;
  isRecommendation?: boolean;
  isFavorite?: boolean;
}

const LS_KEY = "filterParams";

class FilterService {
  private filterParams: FilterParams = {};

  constructor() {
    this.loadFilterParams();
  }

  getFilterParams(): FilterParams {
    return this.filterParams;
  }

  removeFilters() {
    localStorage.removeItem(LS_KEY);
  }

  setParam(key: keyof FilterParams, value: string | number | boolean | undefined) {
    this.filterParams[key] = value;
    this.saveFilterParams();
  }

  private saveFilterParams() {
    const queryString = Object.keys(this.filterParams)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.filterParams[key] as keyof FilterParams
          )}`
      )
      .join("&");

    localStorage.setItem(LS_KEY, queryString);
  }

  private loadFilterParams() {
    const queryString = localStorage.getItem(LS_KEY);

    if (queryString) {
      const params: FilterParams = {};

      const pairs = queryString.split("&");
      pairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        params[decodedKey] = this.parseValue(decodedKey, decodedValue);
      });

      this.filterParams = params;
    }
  }
  private parseValue(decodedKey: keyof FilterParams, value: string) {
    if (decodedKey === "tag") {
      if (value === "null") {
        return null;
      }

      return value;
    }

    if (decodedKey === "brand" || decodedKey === "provider") {
      if (value === "null") {
        return null;
      }

      return Number(value);
    }

    if (decodedKey === "isRecommendation" || decodedKey === "isFavorite") {
      return value === "true";
    }
  }
}

export const filterService = new FilterService();
