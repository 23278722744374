/**
 * @param {(string, number)} prop
 * @param {Array} array
 * @param {boolean} hasEmpty
 * @description Check prop value by `Array`.
 */
export const validateProp = (prop, array, hasEmpty = false) =>
  prop ? array.includes(prop) : !!hasEmpty;

/**
 * @param {(string, number)} prop
 * @param {Set} set
 * @param {boolean} hasEmpty
 * @description Check prop value by `Set`.
 */
export const validatePropBySet = (prop, set, hasEmpty = false) =>
  prop ? set.has(prop) : !!hasEmpty;

/**
 * @param {Object} map
 * @param {string} value First order key
 * @param {(string, null)} defaultValue
 * @description Get value by Map.
 */
export const getValueByMap = (map, value, defaultValue = null) =>
  map[value] !== undefined ? map[value] : map[defaultValue];

/**
 * @param {Object<Object>} map
 * @param {string} value First order key
 * @param {string} key Second order key
 * @param {(string, null)} defaultValue
 * @description Get object value by Map.
 */
export const getObjectValueByMap = (map, value, key, defaultValue = null) =>
  map[value] !== undefined ? map[value][key] : map[defaultValue];
