<template>
  <div :class="['message', { 'message--onTop': isOnTop }]">
    <v-icon v-if="icon" :name="icon" class="message__icon" v-bind="iconSettings" />

    <span v-for="translate in translates" :key="translate" class="message__text">
      {{ _(`game-frame.${translate}`) }}
    </span>
    <slot class="message__text"> </slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import { VIcon } from "../Helpers/Icons";

export default {
  name: "GameMessage",

  components: {
    VIcon,
  },

  props: {
    translates: {
      type: Array,
      default: () => [],
    },

    icon: {
      type: [String, null],
      default: null,
    },

    iconSettings: {
      type: Object,
      default: () => ({}),
    },

    isOnTop: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      _: "l10n/translate",
    }),
  },
};
</script>

<style lang="scss" scoped>
.message {
  color: $text-bright-color;
  font-size: $base-font-size;
  font-weight: bold;
  text-transform: uppercase;
  font-family: $second-font-family;
  padding: 0 2em;
  text-align: center;

  &--onTop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $bg-black-color;
    position: absolute;
    z-index: 15;
  }

  &__icon {
    fill: currentColor;
    display: block;
    margin: 0 auto 1em;
  }

  &__text {
    display: block;
  }
}
</style>
