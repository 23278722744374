const state = {
  isInfoModalOpen: false,
  isBonusModalShown: false,
  isContactsModalShown: false,
  isBuyTimeModalShown: false,
  isBuyTimeResponseModalShown: false,
  isSuccessOrErrorModalShown: false,
  successOrErrorModalStateError: false,
  isNavigationTheRight: true,
  isDisplayNavigation: false,
  isBetHistoryModalShown: false,
};

const actions = {
  toggleMobileNavigation({ commit, state }) {
    commit("setDisplayNavigation", !state.isDisplayNavigation);
  },
  setMobileNavigation({ commit }, value) {
    commit("setDisplayNavigation", value);
  },
};

const getters = {
  successOrErrorModalStateError(state) {
    return state.successOrErrorModalStateError;
  },
};

const mutations = {
  showBonusModal(state) {
    state.isBonusModalShown = true;
  },

  showContactsModal(state) {
    state.isContactsModalShown = true;
  },

  hideContactsModal(state) {
    state.isContactsModalShown = false;
  },

  hideBonusModal(state) {
    state.isBonusModalShown = false;
  },

  showInfoModal(state) {
    state.isInfoModalOpen = true;
  },

  showSportBetHistoryModal(state) {
    state.isBetHistoryModalShown = true;
  },

  hideSportBetHistoryModal(state) {
    state.isBetHistoryModalShown = false;
  },

  hideInfoModal(state) {
    state.isInfoModalOpen = false;
  },

  showBuyTimeModal(state) {
    state.isBuyTimeModalShown = true;
  },

  hideBuyTimeModal(state) {
    state.isBuyTimeModalShown = false;
  },
  showSuccessOrErrorModal(state) {
    state.isSuccessOrErrorModalShown = true;
  },

  hideSuccessOrErrorModal(state) {
    state.isSuccessOrErrorModalShown = false;
  },

  setDisplayNavigation(state, value) {
    state.isDisplayNavigation = value;
  },

  setSuccessOrErrorModalError(state, value) {
    state.successOrErrorModalStateError = value;
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  getters,
};
