import JsBarcode from "jsbarcode";

export function drawBarCode(canvas: HTMLCanvasElement, data: string) {
  JsBarcode(canvas, data, {
    format: "CODE128",
    margin: 0,
    height: canvas.height,
    displayValue: false,
  });
}
