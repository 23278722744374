<template>
  <div class="desktop-footer">
    <div class="desktop-footer__row desktop-footer__row--first">
      <div class="desktop-footer__side">
        <div v-if="hasUser" class="desktop-footer__credit">
          <div v-if="!isSweepstakesEnabled">
            <div class="desktop-footer__credit-title">{{ _("balance") }}</div>
            <div class="desktop-footer__credit-value">
              {{ $f.formatMoney($f.toMoney(balance)) }} {{ currency }}
            </div>
            <!--            <ProgressBar TODO ЖДЕМ АПИ-->
            <!--              :remaining-wager-sum="12"-->
            <!--              :wager-done-sum="20"-->
            <!--              label="Wager"-->
            <!--            />-->
          </div>
          <div v-if="isSweepstakesEnabled" class="desktop-footer__sweepstakes">
            <div class="desktop-footer__sweepstakes-item">
              <div class="desktop-footer__sweepstakes-title">
                {{ _("entries") }}
              </div>
              <div class="desktop-footer__sweepstakes-value">
                {{ $f.formatMoney($f.toMoney(sweepstakesBalance?.entries)) }}
              </div>
            </div>
            <div class="desktop-footer__sweepstakes-item">
              <div class="desktop-footer__sweepstakes-title">
                {{ _("total-win") }} ({{ currency }})
              </div>
              <div class="desktop-footer__sweepstakes-value">
                {{ $f.formatMoney($f.toMoney(sweepstakesBalance?.totalWin)) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAirClubTheme" class="desktop-footer__side--airclub">
        <DesktopMenu />
      </div>
      <div class="desktop-footer__side">
        <div v-if="hasUser && bonus" class="desktop-footer__cashback">
          <div
            :class="[
              'desktop-footer__cashback-title',
              {
                'desktop-footer__cashback-title--red': bonus.type === 'cashback',
              },
            ]"
          >
            {{ bonus.name }}
          </div>
          <div class="desktop-footer__cashback-value">
            {{ $f.formatMoney($f.toMoney(bonus.amount)) }}
            {{ isSweepstakesEnabled ? "" : currency }}
          </div>
        </div>
      </div>
    </div>

    <div class="desktop-footer__row desktop-footer__row--second">
      <div class="desktop-footer__center">
        <Providers />
      </div>

      <div class="desktop-footer__side">
        <div v-if="hasUser" class="desktop-footer__username">
          {{ username }}
        </div>
        <language-select />
        <!--        <switch-language-button class-names="desktop-footer__button desktop-footer__button&#45;&#45;lang" />-->
        <v-button
          v-if="isSignOutButtonShown"
          variant="primary"
          class="desktop-footer__button desktop-footer__button--sign-out"
          :is-loading="isFetchLogOut"
          @click="handleSignOutClick"
        >
          {{ _("exit") }}
        </v-button>

        <BuyTimeButton :is-default-desktop-button="true" />

        <v-button
          v-if="!hasUser"
          variant="primary"
          class="desktop-footer__button desktop-footer__button--sign-in"
          @click="goToLogin"
        >
          {{ _("sign-in.log-in") }}
        </v-button>
      </div>

      <div class="desktop-footer__side">
        <v-button
          v-if="hasUser"
          variant="primary"
          class="desktop-footer__button"
          @click="showSportBetHistoryModal"
        >
          {{ _("bet_history_title_short") }}
        </v-button>
        <v-button
          v-if="hasUser"
          variant="primary"
          class="desktop-footer__button"
          @click="showInfoModal"
        >
          {{ _("info") }}
        </v-button>
        <v-button
          v-if="isEnabledContactsButton && hasUser"
          variant="primary"
          class="desktop-footer__button desktop-footer__button--contacts"
          @click="setModalContactShown"
        >
          {{ _("modal.contacts.title") }}
        </v-button>
        <v-button
          v-if="bonus"
          variant="secondary"
          class="desktop-footer__button desktop-footer__button--take"
          @click="showBonusModal"
        >
          {{ _("take") }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { upperFirst, get } from "lodash-es";

import routeMixin from "@/mixins/route";

import { VButton } from "@/components/Helpers/Buttons";
import Providers from "@/components/Navigation/Providers.vue";
import BuyTimeButton from "@/components/Helpers/Buttons/BuyTimeButton.vue";
import DesktopMenu from "@/components/Menu/DesktopMenu.vue";
import { getTheme } from "@/utils";
import LanguageSelect from "@/components/Helpers/language-select.vue";
// import ProgressBar from "@/components/progress-bar/progress-bar.vue";

const THEME = getTheme();
export default {
  name: "DesktopFooter",

  components: {
    LanguageSelect,
    // ProgressBar,
    DesktopMenu,
    BuyTimeButton,
    VButton,
    Providers,
  },

  mixins: [routeMixin],

  data() {
    return {
      isFetchLogOut: false,
      isAirClubTheme: THEME === "airclub" || THEME === "royalesweepstakes",
    };
  },

  computed: {
    ...mapState({
      language: (state) => state.l10n.current.toUpperCase(),
      bonus: (state) => {
        const { item } = state.bonus;
        if (!item || !item.bonus || !item.available) {
          return null;
        }
        return {
          name: upperFirst(item.bonus.stringId),
          amount: item.bonus.amount,
          type: item.bonus.stringId,
        };
      },

      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },

      isEnabledContactsButton: (state) =>
        get(state, "lobby.params.lobbyButtons.contacts.enabled", false),

      isSignOutButtonShown: (state) => {
        if (!state.user.item) {
          return false;
        }
        return get(state, "lobby.params.lobbyButtons.exit.enabled", false);
      },
    }),

    ...mapGetters({
      filteredItemsCode: "l10n/allowLocalesFromApi",
      _: "l10n/translate",
      balance: "user/balance",
      currency: "user/currency",
      username: "user/username",
      hasUser: "user/hasUser",
      sweepstakesBalance: "user/sweepstakesBalance",
    }),
  },

  methods: {
    ...mapActions({
      signOut: "user/signOut",
    }),

    ...mapMutations({
      showBonusModal: "ui/showBonusModal",
      setModalContactShown: "ui/showContactsModal",
      showInfoModal: "ui/showInfoModal",
      showSportBetHistoryModal: "ui/showSportBetHistoryModal",
    }),

    async handleSignOutClick() {
      this.isFetchLogOut = true;
      try {
        await this.signOut();
        this.goToLoginPage();
      } catch (e) {
        throw new Error(e);
      } finally {
        this.isFetchLogOut = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-footer {
  width: 100%;
  height: $footer-height;
  display: flex;
  flex-direction: column;
  background-color: $bg-black-color;

  @media (max-width: 1024px) {
    display: none;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 $footer-padding;

    &--first {
      height: $footer-1row-height;
      border-top: $footer-border-top-width solid #737375;
      background-image: $footer-background-image;
      border-bottom: $footer-border-bottom-width solid $border-gray-color;
      box-sizing: border-box;
    }

    &--second {
      flex: 1;
      height: $footer-2row-height;
    }
  }

  &__center {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  &__side {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &__credit {
    align-items: flex-start;
  }

  &__cashback {
    align-items: flex-end;

    &-title {
      font-size: 20px;
      color: $text-main-color;
      text-transform: uppercase;
      text-shadow: 0 0 3px $shadow-light-color;
      animation: blue-neon 2s linear infinite;
      -moz-animation: blue-neon 2s linear infinite;
      -webkit-animation: blue-neon 2s linear infinite;
      -o-animation: blue-neon 2s linear infinite;

      &--red {
        color: $text-coral-color;
        text-shadow: 0 0 3px $shadow-coral-color;
        animation: red-neon 2s linear infinite;
        -moz-animation: red-neon 2s linear infinite;
        -webkit-animation: red-neon 2s linear infinite;
        -o-animation: red-neon 2s linear infinite;
      }
    }
  }

  &__sweepstakes {
    display: flex;
    align-items: center;

    &-item + &-item {
      margin-left: 24px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;
    }

    &-title {
      margin: 0 auto;
      color: $text-color;
      font-family: $base-font-family;
      font-size: $font-xxs-size;
      text-transform: uppercase;
    }

    &-value {
      margin: 0 auto;
      color: $text-color;
      font-size: $font-lg-size;
      font-family: $base-font-family;
      text-transform: uppercase;
    }
  }

  &__credit,
  &__cashback {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      color: $text-color;
      font-family: $base-font-family;
      font-size: $font-sm-size;
      text-transform: uppercase;
    }

    &-value {
      color: $text-color;
      font-size: $font-xxl-size;
      font-family: $base-font-family;
      text-transform: uppercase;

      .backgroundcliptext & {
        color: transparent;
        background: $bg-white-color
          linear-gradient(to bottom, $bg-white-color, fade-out($bg-black-color, 0.65) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__username {
    color: $text-darkGray-color;
    font-size: $font-xs-size;
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 90px;

    @media (min-width: 1025px) and (max-width: 1320px) {
      margin-right: 50px;
    }
  }

  &__button {
    height: 30px;
    font-size: $font-sm-size;
    text-transform: uppercase;

    &--lang {
      width: 60px;
    }

    &--contacts {
      margin-left: auto;
    }

    //#de_locale
    //&--sign-in,
    //&--sign-out {
    //  width: 78px;
    //}

    & + & {
      &--take {
        margin-left: 48px;
      }

      margin-left: 2px;
    }
  }
}

@keyframes blue-neon {
  0%,
  100% {
    text-shadow: 0 0 1px $shadow-color, 0 0 3px $shadow-color, 0 0 10px $shadow-color,
      0 0 10px $shadow-color, 0 0 0.4px $shadow-lighten-color, 0.5px 0.5px 0.1px $shadow-dim-color;
    color: $text-light-color;
  }
  50% {
    text-shadow: 0 0 0.5px $shadow-dark-color, 0 0 1.5px $shadow-dark-color,
      0 0 5px $shadow-dark-color, 0 0 5px $shadow-dark-color, 0 0 0.2px $shadow-dark-color,
      0.5px 0.5px 0.1px $shadow-darkDim-color;
    color: $text-dim-color;
  }
}

@keyframes red-neon {
  0%,
  100% {
    text-shadow: 0 0 1px $shadow-red-color, 0 0 3px $shadow-red-color, 0 0 10px $shadow-red-color,
      0 0 10px $shadow-red-color, 0 0 0.4px $shadow-red-color, 0.5px 0.5px 0.1px $brown-color;
    color: $text-gold-color;
  }
  50% {
    text-shadow: 0 0 0.5px $shadow-tomato-color, 0 0 1.5px $shadow-tomato-color,
      0 0 5px $shadow-tomato-color, 0 0 5px $shadow-tomato-color, 0 0 0.2px $shadow-tomato-color,
      0.5px 0.5px 0.1px $choco-color;
    color: $text-brown-color;
  }
}
</style>
