import { getTheme } from "@/utils";

const enum Themes {
  THECLUB = "theclub",
  FREECLUB = "freeclub",
  JACARECLUB = "jacareclub",
  WINDFALL = "windfall",
  PLAYCLUB = "playclub",
  MONTECARLO = "montecarlo",
  BETBOY = "betboy",
  VIPARCADE = "viparcade",
}

const MINI_JACKPOT_GAME_NEW_SECTION_Z = "155px";
const MINI_JACKPOT_GAME_OLD_SECTION_Z = "168px";

export class ThemeService {
  type: Themes;

  public constructor(type: Themes) {
    this.type = type;
  }

  public get getMiniGameTransformSectionZ():
    | typeof MINI_JACKPOT_GAME_NEW_SECTION_Z
    | typeof MINI_JACKPOT_GAME_OLD_SECTION_Z {
    return this.type === Themes.MONTECARLO || this.type === Themes.PLAYCLUB
      ? MINI_JACKPOT_GAME_NEW_SECTION_Z
      : MINI_JACKPOT_GAME_OLD_SECTION_Z;
  }

  public isHeaderBonusInfoVisible() {
    return this.type === Themes.BETBOY;
  }
}

const currentThemeRun: Themes | string = getTheme() ?? Themes.THECLUB;

export const themeService = new ThemeService(currentThemeRun as Themes);
