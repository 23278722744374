<template>
  <div v-if="win !== null" class="jackpot-modal">
    <div
      v-if="win.state === 'pending' && !isSpinned"
      class="fortune-window"
      :style="{ transform: `scale(${fortuneWindowScale})` }"
    >
      <div class="fortune-window__title-0" />

      <div class="fortune-window__jackpots">
        <div ref="ring" class="fortune-window__ring">
          <div
            v-for="(jackpot, index) in jackpots"
            :key="index"
            :class="['fortune-window__jackpot', `fortune-window__jackpot--${jackpot.color}`]"
            :style="{
              transform: `rotateX(${jackpot.angle}deg) translateZ(${transformAxiosZ})`,
            }"
          >
            <div class="fortune-window__title">{{ jackpot.name }}</div>
          </div>
        </div>
      </div>

      <div class="fortune-window__suggestion">
        {{ _("jackpot-modal.suggestion") }}
      </div>

      <div class="fortune-window__background" />

      <div
        :class="[
          'fortune-window__button fortune-window__button--spin',
          { 'fortune-window__button--disabled': isAnimating },
        ]"
        v-on="{ click: isAnimating ? () => {} : playAnimation }"
      >
        <span>{{ _("jackpot-modal.spin") }}</span>
      </div>

      <div
        :class="[
          'fortune-window__lever-wrapper',
          { 'fortune-window__lever-wrapper--active': isAnimating },
        ]"
        v-on="{ click: isAnimating ? () => {} : playAnimation }"
      >
        <div class="fortune-window__lever" />
        <div class="fortune-window__lever-ball" />
      </div>
    </div>

    <div
      v-else-if="win.state === 'pending' || win.state === 'paid'"
      class="congratulations-window"
      :style="{ transform: `scale(${congratsWindowScale})` }"
    >
      <div class="congratulations-window__title" />

      <div class="congratulations-window__title-1">
        {{ _("jackpot-modal.congratulations-title.part-1") }}
      </div>

      <div class="congratulations-window__title-2">
        {{ jackpotName }} {{ _("jackpot-modal.congratulations-title.part-2") }}
        <br />
        {{ $f.formatMoney($f.toMoney(win.value)) }} {{ currency }}
      </div>

      <div
        class="fortune-window__button fortune-window__button--take"
        v-on="{
          click: () => {
            win.state === 'pending' ? acceptWin(win.id) : markWinAsViewed(win.id);
            stopSound();
          },
        }"
      >
        <span>{{ win.state === "pending" ? _("jackpot-modal.take") : _("jackpot-modal.ok") }}</span>
      </div>
    </div>

    <div v-else-if="win.state === 'canceled'" class="expired-window">
      <div class="expired-window__content">
        <div class="expired-window__text">
          {{ _("jackpot-modal.time-passed-title") }}
        </div>
      </div>

      <div class="expired-window__buttons">
        <div
          class="expired-window__button expired-window__button--blue"
          @click="markWinAsViewed(win.id)"
        >
          {{ _("jackpot-modal.ok") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import { range, get } from "lodash-es";
import { Howl } from "howler";
import anime from "animejs";

import coinSound from "../../assets/sounds/coin.mp3";
import alertSound from "../../assets/sounds/alert.mp3";
import wheelSound from "../../assets/sounds/wheel.mp3";
import wheelStoppedSound from "../../assets/sounds/wheel-stopped.mp3";
import congratulationsSound from "../../assets/sounds/congratulations.mp3";
import { themeService } from "@/services/ThemeService/ThemeService";

const JACKPOT_NAMES = ["MINI", "MAJOR", "GRAND", "ULTIMATE"];
const JACKPOT_COLORS = ["blue", "red", "blue", "red"];
const CONGRATS_DURATION = 3000;

export default {
  name: "JackpotModal",

  data: () => ({
    /**
     * @param {number} angle
     * @param {(0, 1, 2, 3)} index
     */
    jackpots: range(0, 360, 45).map((angle, index) => {
      const num = 3 - (index % 4); // get current index = {(4, 3, 2, 1)}

      return {
        angle,
        name: JACKPOT_NAMES[num],
        color: JACKPOT_COLORS[num],
      };
    }),
    congratsWindowScale: 1,
    fortuneWindowScale: 1,
    isAnimating: false,
    isSpinned: false,
    transformAxiosZ: themeService.getMiniGameTransformSectionZ,
  }),

  computed: {
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
    }),
    ...mapGetters({
      userCurrency: "user/currency",
      slots: "jackpots/slots",
      win: "jackpots/win",
      _: "l10n/translate",
    }),

    jackpotName: function () {
      return this.win !== null ? JACKPOT_NAMES[this.win.slot] : "N/A";
    },

    currency() {
      return this.isSweepstakesEnabled ? "" : this.userCurrency;
    },
  },

  watch: {
    win: {
      handler: function (newValue, oldValue) {
        this.onWindowResize();

        const sessionFrame = document.querySelector(".session__frame");
        if (newValue === null) {
          sessionFrame?.focus();
        } else {
          sessionFrame?.blur();
        }

        if (get(newValue, "id", null) === get(oldValue, "id", null)) {
          return;
        }
        if (newValue === null) {
          return;
        }
        this.onWinChange();
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    const createPath = (fileName) => window.TEMPLATE_PATH + fileName;

    document.body.addEventListener("keyup", this.handleKeyUp, true);

    this.sounds = {
      alert: new Howl({ src: [createPath(alertSound)] }),
      coin: new Howl({ src: [createPath(coinSound)] }),
      wheel: new Howl({ src: [createPath(wheelSound)] }),
      wheelStopped: new Howl({ src: [createPath(wheelStoppedSound)] }),
      congratulations: new Howl({ src: [createPath(congratulationsSound)] }),
    };
  },

  mounted() {
    this.onWinChange();

    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },

  methods: {
    playAnimation() {
      this.sounds.alert.stop();

      this.isAnimating = true;
      const jackpotName = this.jackpotName;
      const angles = {
        MINI: -315,
        MAJOR: -270,
        GRAND: -225,
        ULTIMATE: -180,
      };
      const angle = angles[jackpotName] + 360 * 8;
      const animation = anime({
        targets: ".fortune-window__ring",
        rotateX: [0, `${angle}deg`],
        easing: "easeOutExpo",
        duration: 5000,
        autoplay: false,
      });

      animation.finished.then(async () => {
        this.sounds.wheelStopped.play();

        const jackpots = Array.from(document.querySelectorAll(".fortune-window__jackpot"));
        const jackpot = jackpots.reverse().find((e) => e.textContent === jackpotName);

        const jackpotTitle = jackpot.querySelector(".fortune-window__title");

        jackpotTitle.style.animationName = "text-shadow-blink";
        setTimeout(() => {
          this.isSpinned = true;
          this.isAnimating = false;
          this.sounds.congratulations.play();
          jackpotTitle.style.animationName = "";
        }, CONGRATS_DURATION);
      });
      this.sounds.coin.play();
      animation.play();
      this.sounds.wheel.play();
    },

    stopSound() {
      Object.values(this.sounds).forEach((s) => s.stop());
    },

    ...mapActions({
      acceptWin: "jackpots/acceptWin",
    }),

    ...mapMutations({
      markWinAsViewed: "jackpots/markWinAsViewed",
    }),

    async onWinChange() {
      if (this.win === null) {
        return;
      }

      this.isAnimating = false;
      this.isSpinned = this.win.state === "paid";

      if (!this.isSpinned && this.sounds && this.sounds.alert) {
        this.sounds.alert.play();
      }
    },

    handleKeyUp(event) {
      if (event.keyCode === 32 && !this.isAnimating) {
        if (this.win?.state === "pending" && !this.isSpinned) {
          this.playAnimation();
        } else if (this.win?.state === "pending") {
          this.acceptWin(this.win.id);
          this.stopSound();
        } else if (this.win?.state === "paid") {
          this.markWinAsViewed(this.win.id);
          this.stopSound();
        }
      }
      if (this.win !== null) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    onWindowResize() {
      const width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const height =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      const fortuneWindowScale = Math.min(width / 600, height / 700);
      this.fortuneWindowScale = fortuneWindowScale >= 1 ? 1 : fortuneWindowScale;
      const congratsWindowScale = Math.min(width / 650, height / 700);
      this.congratsWindowScale = congratsWindowScale >= 1 ? 1 : congratsWindowScale;
    },
  },
};
</script>

<style lang="scss">
.jackpot-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: fade-out($bg-black-color, 0.1);
  position: fixed;
  z-index: 999998;
}

.fortune-window {
  width: 390px;
  height: 460px;
  min-width: 390px;
  max-width: 390px;
  min-height: 460px;
  max-height: 460px;
  position: relative;

  &__title-0 {
    top: -110px;
    left: 50%;
    width: 285px;
    height: 96px;
    background-image: url($path + "fortune-window__title.png");
    position: absolute;
    margin-left: -142px;
  }

  &__suggestion {
    left: 0;
    right: 0;
    bottom: -50px;
    color: $text-color;
    font-family: $base-font-family;
    font-size: 20px;
    text-align: center;
    text-shadow: 0 0 5px $shadow-bright-color;
    position: absolute;
  }

  &__ring {
    top: 50%;
    left: 50%;
    width: 276px;
    height: 140px;
    transform-style: preserve-3d;
    position: absolute;
    margin-top: -70px;
    margin-left: -138px;
  }

  &__jackpots {
    top: 67px;
    left: 57px;
    width: 276px;
    height: 302px;
    perspective: 1600px;
    position: absolute;
    overflow: hidden;
  }

  &__jackpot {
    width: 276px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    &--red {
      background-image: url($path + "fortune-window__red-jackpot.png");
    }

    &--blue {
      background-image: url($path + "fortune-window__blue-jackpot.png");
    }
  }

  &__title {
    font-size: 52px;
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    color: $text-color;

    .backgroundcliptext &,
    .backgroundcliptext & {
      background: $bg-white-color
        linear-gradient(to bottom, $null-color 60%, fade-out($bg-black-color, 0.25) 100%);
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  &__background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url($path + "fortune-window__background.png");
    position: absolute;

    &::before {
      content: "";
      top: 67px;
      left: 57px;
      width: 276px;
      height: 302px;
      display: block;
      background-image: url($path + "fortune-window__frame.png");
      position: absolute;
    }
  }

  &__button {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url($path + "fortune-window__button.png");
    cursor: pointer;
    z-index: 97;

    span {
      color: $text-color;
      font-size: $font-sm-size;
      font-family: $second-font-family;
      text-wrap: balance;
      word-break: break-word;
      inline-size: 80px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      z-index: 100;
    }

    &::after,
    &::before {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background-image: url($path + "fortune-window__button.png");
      transition: opacity 0.25s;
      position: absolute;
      cursor: pointer;
      opacity: 0;
    }

    &::after {
      background-position: -90px -0px;
      z-index: 98;
    }

    &::before {
      background-position: -180px -0px;
      z-index: 99;
    }

    &:hover::after,
    &:active::before {
      opacity: 1;
    }

    &--disabled {
      &::before,
      &:hover::before,
      &:active::before {
        opacity: 1;
        cursor: default;
        pointer-events: none;
      }
    }

    &--spin {
      left: 50%;
      bottom: 12px;
      position: absolute;
      margin-left: -45px;
    }

    &--take {
      left: 50%;
      bottom: 12px;
      position: absolute;
      margin-left: -45px;
    }
  }

  &__lever-wrapper {
    top: 0;
    right: -76px;
    bottom: 0;
    width: 77px;
    position: absolute;
    cursor: pointer;

    &--active {
      cursor: default;
    }

    .fortune-window__lever {
      top: 28px;
      width: 77px;
      height: 202px;
      background-image: url($path + "fortune-window__lever.png");
      transition: transform 0.25s;
      transform-origin: bottom left;
      position: absolute;

      &-ball {
        content: "";
        top: 15px;
        left: 52px;
        width: 35px;
        height: 35px;
        display: block;
        background-image: url($path + "fortune-window__lever-ball.png");
        transition: transform 0.25s;
        position: absolute;
      }
    }

    &--active {
      .fortune-window__lever {
        transform: scaleY(-1);

        &-ball {
          transform: translateY(390px);
        }
      }
    }
  }
}

.congratulations-window {
  width: 488px;
  height: 520px;
  min-width: 488px;
  max-width: 488px;
  min-height: 520px;
  max-height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url($path + "congratulations-window__background.png");
  position: relative;

  &::after {
    content: "";
    top: 0;
    width: 669px;
    height: 472px;
    display: block;
    pointer-events: none;
    background-image: url($path + "congratulations-window__lines.png");
    position: absolute;
  }

  &__title {
    top: -56px;
    width: 608px;
    height: 41px;
    background-image: url($path + "congratulations-window__title.png");
    position: absolute;
  }

  &__title-1 {
    top: 32px;
    left: 72px;
    color: $text-gray-color;
    font-size: $font-xxl-size;
    font-family: $base-font-family;
    text-transform: uppercase;
    position: absolute;
  }

  &__title-2 {
    color: $text-color;
    font-family: $second-font-family;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: $font-line;
    transform: rotateZ(-20deg);
    margin-bottom: 35px;
    text-shadow: 0 0 25px $shadow-pink-color, 0 0 20px $shadow-pink-color,
      0 0 15px $shadow-pink-color;
  }
}

.expired-window {
  width: 460px;
  height: 460px;
  background-color: fade-out($bg-black-color, 0.3);
  box-shadow: 0 0 3px $shadow-brighten-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    max-width: 90vw;
    max-height: 90vh;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 90vw;
    height: 90vw;
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    width: 90vh;
    height: 90vh;
  }

  @media (min-width: 1025px) {
    width: 460px;
    height: 460px;
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(
      ellipse,
      fade-out($bg-bright-color, 0.25) 0%,
      $null-color 50%
    );
  }

  &__text {
    padding: 0 24px;
    font-size: 36px;
    font-family: $base-font-family;
    color: $text-color;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: $font-xm-size;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin-bottom: 24px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-lg-size;
    font-family: $base-font-family;
    text-align: center;
    color: $text-color;
    text-transform: uppercase;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-image 0.5s;

    &--blue {
      background-image: url($path + "bonus-modal__button--blue.png");

      &:hover {
        background-image: url($path + "bonus-modal__button--blue-hover.png");
      }

      &:active {
        background-image: url($path + "bonus-modal__button--blue-active.png");
      }
    }

    &--red {
      background-image: url($path + "bonus-modal__button--red.png");

      &:hover {
        background-image: url($path + "bonus-modal__button--red-hover.png");
      }

      &:active {
        background-image: url($path + "bonus-modal__button--red-active.png");
      }
    }

    @media (max-width: 1024px) {
      font-size: $base-font-size;
      width: 128px;
      height: 58px;
    }

    @media (min-width: 1025px) {
      width: 194px;
      height: 88px;
    }
  }
}

@keyframes text-shadow-blink {
  from {
    filter: drop-shadow(0 0 0 $shadow-pink-color);
  }

  50% {
    filter: drop-shadow(0 0 25px $shadow-pink-color) drop-shadow(0 0 20px $shadow-pink-color)
      drop-shadow(0 0 15px $shadow-pink-color);
  }

  to {
    filter: drop-shadow(0 0 0 $shadow-pink-color);
  }
}
</style>
