<template>
  <form v-if="!user" class="sign-in" @submit.prevent="handleSubmit">
    <div
      v-if="isPlayClubTheme || isIce8ClubTheme || isVipArcadeTheme"
      class="sign-in__logo sign-logo"
    />
    <div v-if="isVipArcadeTheme || isPlayClubTheme" class="sign-in__logo-bottom sign-logo-footer" />
    <div
      class="sign-in__container"
      :style="[isVirtualKeyboardShown ? { transform: `scale(${scale})` } : {}]"
    >
      <input
        ref="login"
        v-model="login"
        class="sign-in__input sign-in__input--login"
        :placeholder="_('sign-in.login-placeholder')"
        autocomplete="login"
        type="text"
        autofocus
        @focus="onFocus(inputMap.login)"
      />

      <input
        ref="password"
        v-model="password"
        class="sign-in__input sign-in__input--password"
        :placeholder="_('sign-in.password-placeholder')"
        autocomplete="password"
        type="password"
        @focus="onFocus(inputMap.pass)"
      />

      <virtual-keyboard
        v-if="isVirtualKeyboardShown"
        @on-submit="handleSubmit"
        @on-press="handleKeyClick"
      />

      <v-button
        v-if="!isVirtualKeyboardShown"
        class="sign-in__login-button"
        :is-loading="isFetchSubmit"
        tag-name="button"
        variant="primary"
        has-texture
        type="submit"
        @click="handleSubmit"
      >
        {{ _("sign-in.log-in") }}
      </v-button>

      <div v-if="error !== null" class="sign-in__error-message">
        {{ errorMessage }}
      </div>
    </div>
    <router-link v-if="isDemoModeActive" to="home" class="sign-in__back" is-exact-active>
      {{ _("free.spins") }}
    </router-link>
    <div class="sign-in__footer">
      <language-select class-names="sign-in__footer-button" />
      <v-button
        v-if="isDemoModeActive"
        class="sign-in__footer-button"
        variant="primary"
        @click="handleRedirectToLobby"
      >
        {{ _("session.button-text") }}
      </v-button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

// Mixins
import routeMixin from "@/mixins/route";

// Components
import { VButton } from "@/components/Helpers/Buttons";
import VirtualKeyboard, { BACKSPACE_CHAR } from "@/components/Helpers/VirtualKeyboard";
import { getTheme } from "@/utils";
import { setSessionModalTermOFUseVisible } from "@/api/sessions";
import { getLangFromStorage } from "@/api/language";
import LanguageSelect from "@/components/Helpers/language-select.vue";

// Helpers
const ERROR_MESSAGE = "You should enter the login and password fields.";
const ERROR_MESSAGE_DEACTIVATED = "Player is deactivated";

export default {
  components: {
    LanguageSelect,
    VirtualKeyboard,
    VButton,
  },
  mixins: [routeMixin],
  data: () => ({
    login: "",
    password: "",

    inputMap: {
      login: "login",
      pass: "password",
    },

    currentInputName: "login",
    isFetchSubmit: false,
    scale: 1,
  }),
  computed: {
    ...mapState({
      isVirtualKeyboardShown() {
        return getTheme() === "windfall" && !this.isMobile;
      },
      user: (state) => state.user.item,
      l10n: (state) => state.l10n.items,
      language: (state) => state.l10n.current,
      apiLanguages: (state) => state.l10n.apiLanguages,
      isMobile: (state) => state.app.isMobile,
      error: (state) => state.user.error.message,
    }),

    ...mapGetters({
      filteredItemsCode: "l10n/allowLocalesFromApi",
      _: "l10n/translate",
      isDemoModeActive: "app/isDemoModeActive",
    }),

    errorMessage: function () {
      return typeof this.error === "string" && this.error === ERROR_MESSAGE_DEACTIVATED
        ? this._("sign-in.deactivated-error")
        : this.error;
    },

    isPlayClubTheme() {
      return getTheme() === "playclub";
    },

    isVipArcadeTheme() {
      return getTheme() === "viparcade";
    },

    isIce8ClubTheme() {
      return getTheme() === "ice8club";
    },
  },
  created: async function () {
    await this.fetchUser();
    if (this.user !== null) {
      this.checkQueryAndRedirect();
      return;
    }

    const currentLangFromLS = getLangFromStorage();

    if (this.l10n === null || this.apiLanguages === null) {
      await this.$store.dispatch("l10n/fetchLanguages");
      await this.$store.dispatch("l10n/fetch", {
        lang: currentLangFromLS,
      });
    }

    if (this.isVirtualKeyboardShown && this.isMobile) {
      this.redirectRoute({ query: {} });
      return;
    }

    this.$store.commit("app/setIsLoading", false);
  },
  mounted() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    ...mapActions({
      fetchUser: "user/fetch",
      signIn: "user/signIn",
    }),

    handleKeyClick(key) {
      const { currentInputName } = this;

      if (currentInputName !== this.inputMap.login && currentInputName !== this.inputMap.pass) {
        return;
      }

      if (/^[0-9a-zA-Z \-'_]+$/.test(key)) {
        this[currentInputName] += key;
      } else if (key === BACKSPACE_CHAR) {
        this[currentInputName] = this[currentInputName].slice(0, -1);
      }

      this.$refs[currentInputName].focus();
    },

    async handleSubmit() {
      const [login, password] = [this.login.trim(), this.password.trim()];

      if (!login || !password) {
        return this.$store.commit("user/setError", ERROR_MESSAGE);
      }

      this.isFetchSubmit = true;
      await this.signIn({ login: login.toLowerCase(), password });
      this.isFetchSubmit = false;

      if (!this.user) {
        return this.$store.commit("user/setError", this._("sign-in.auth-data-error"));
      }

      setSessionModalTermOFUseVisible(true);
      this.checkQueryAndRedirect();
    },

    async handleRedirectToLobby() {
      this.goToGames(false);
    },

    onWindowResize() {
      const width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const height =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      const scale = Math.min(width / 400, height / 800);
      this.scale = scale >= 1 ? 1 : scale;
    },
    onFocus(name) {
      this.currentInputName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-in {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: $bg-black-color;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10001;
  }

  &__input {
    color: $text-color;
    font-family: $base-font-family;
    background-color: transparent;
    border-image: linear-gradient(to bottom, #444546 0, #ffffff 50%, #444546 100%);
    border-image-slice: 1;
    border-width: 2px;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
      color: $text-color;
    }

    &:-moz-placeholder {
      color: $text-color;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: $text-color;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $text-color;
    }

    &::-ms-input-placeholder {
      color: $text-color;
    }

    &::placeholder {
      color: $text-color;
    }

    &:focus-visible {
      outline: $bg-main-color auto 1px;
    }

    @media (max-width: 1024px) {
      font-size: $base-font-size;
      margin: 0 50px;
      padding: 0 16px;
      width: calc(100% - 100px);
      max-width: 318px;
      height: 54px;

      & + & {
        margin-top: 16px;
      }
    }

    @media (min-width: 1025px) {
      font-size: $font-md-size;
      padding: 0 16px;
      width: 296px;
      height: 56px;

      & + & {
        margin-top: 24px;
      }
    }
  }

  &__login-button {
    color: $text-color;
    font-family: $second-font-family;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    @media (max-width: 1024px) {
      height: 70px;
      min-width: 154px;
      font-size: 38px;
      margin-top: 24px;
    }

    @media (min-width: 1025px) {
      height: 88px;
      min-width: 194px;
      font-size: 48px;
      margin-top: 40px;
    }
  }

  &__error-message {
    $offset: 24px;

    color: lighten($tomato-color, 10%);
    font-family: $base-font-family;
    text-align: center;
    position: relative;
    margin-top: $offset * 2;

    &:before {
      content: "";
      top: -$offset * 0.8;
      left: 50%;
      width: 100%;
      height: 2px;
      display: block;
      background-color: rgba(72, 72, 72, 0.25);
      transform: translateX(-50%);
      position: absolute;

      @media (max-width: 1024px) {
        width: calc(100% - 48px);
        max-width: 318px;
      }

      @media (min-width: 1025px) {
        width: 296px;
      }
    }
  }

  &__back {
    color: $white-color;
    font-size: $font-xxl-size;
    font-family: $base-font-family;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    position: fixed;
    cursor: pointer;
    bottom: 0;
    z-index: 999;

    @media (max-width: 580px) {
      bottom: 120px;
    }
  }

  &__footer {
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;

    @media (max-width: 1024px) {
      height: 54px + 18px * 2;
    }

    @media (max-width: 1024px) and (orientation: portrait) {
      padding: 0 18px;
    }

    @media (max-width: 1024px) and (orientation: landscape) {
      padding: 0 16px;
    }

    @media (min-width: 1025px) {
      height: 36px;
      border-top: 1px solid $border-gray-color;
      padding: 0 26px;
    }

    &-button {
      font-family: $base-font-family;
      text-transform: uppercase;
      padding: 7px 18px;

      & + & {
        margin-left: 2px;
      }

      @media (max-width: 1024px) {
        font-size: $font-lg-size;
      }

      @media (min-width: 1025px) {
        font-size: $font-sm-size;
      }

      @media (max-width: 468px) {
        font-size: $font-xs-size;
      }
    }
  }

  &__logo {
    top: 20%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url($path + "logo.png");
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    transform: translate(-50%, -50%) scale(0.37, 0.3);
    position: absolute;
    z-index: 100000;

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      display: none;
    }

    @media (max-width: 1024px) {
      transform: translate(-50%, -62%) scale(0.32, 0.32);
      padding: 0 250px;
    }
  }

  &__logo-bottom {
    width: 100%;
    height: 100%;
    max-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    top: 70%;
    padding: 0 250px;
    position: absolute;
    z-index: 100000;

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
